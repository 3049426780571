import React from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

class DeleteCountry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal show={this.props.deleteCountry} onHide={this.props.toggleDeleteCountry}>
        <Modal.Header closeButton>
          <Modal.Title>Land Verwijderen</Modal.Title>
        </Modal.Header>
        <Form method='POST' encType='multipart/form-data' onSubmit={(e) => this.props.handleDeleteCountry(e)}>
          <Modal.Body>Bent u zeker dat u "{this.props.selectedCountry.country_name}" wilt verwijderen?</Modal.Body>
          <Modal.Footer>
            <Button variant='primary' type='submit'>
              Verwijderen
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default DeleteCountry;
