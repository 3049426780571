import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import DashboardAmountChart from "./charts/DashboardAmountChart";
import DashboardPriceChart from "./charts/DashboardPriceChart";
import Error from "./Error";
import Loader from "./Loading";

// Keeps Text Fitting inside Div (good for the dynamic size of the 'cards' (most importantly: the 'income' card as its length will be much longer than others))
const ReactFitText = require("react-fittext");

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    console.log("SETTINGS");
    console.log(this.props.state.settings);

    return (
      <div className='main-container'>
        {this.props.state.loading ? (
          <div className='loader'>
            <Loader />
          </div>
        ) : this.props.state.loadError ? (
          <Error message={this.props.state.loadErrorMessage} />
        ) : (
          <div className='dashboard-container'>
            <h1>Dashboard</h1>
            <Row>
              <Col>
                <hr />
              </Col>
            </Row>
            <Row className='dashboard-card-list'>
              <Col lg={2}>
                <div className='dashboard-card'>
                  <ReactFitText minFontSize={16} compressor={0.75}>
                    <h1>{this.props.state.materialCount !== undefined ? this.props.state.materialCount : 0}</h1>
                  </ReactFitText>
                  <ReactFitText minFontSize={16} maxFontSize={28}>
                    <p>materialen</p>
                  </ReactFitText>
                </div>
              </Col>
              <Col lg={2}>
                <div className='dashboard-card'>
                  <ReactFitText minFontSize={16} compressor={0.75}>
                    <h1>{this.props.state.moduleCount !== undefined ? this.props.state.moduleCount : 0}</h1>
                  </ReactFitText>
                  <ReactFitText minFontSize={16} maxFontSize={28}>
                    <p>modules</p>
                  </ReactFitText>
                </div>
              </Col>
              <Col lg={2}>
                <div className='dashboard-card'>
                  <ReactFitText minFontSize={16} compressor={0.75}>
                    <h1>{this.props.state.featureCount !== undefined ? this.props.state.featureCount : 0}</h1>
                  </ReactFitText>
                  <ReactFitText minFontSize={16} maxFontSize={28}>
                    <p>features</p>
                  </ReactFitText>
                </div>
              </Col>
              <Col lg={2}>
                <div className='dashboard-card'>
                  <ReactFitText minFontSize={16} compressor={0.75}>
                    <h1>{this.props.state.customerCount !== undefined ? this.props.state.customerCount : 0}</h1>
                  </ReactFitText>
                  <ReactFitText minFontSize={16} maxFontSize={28}>
                    <p>klanten</p>
                  </ReactFitText>
                </div>
              </Col>
              <Col lg={2}>
                <div className='dashboard-card'>
                  <ReactFitText minFontSize={16} compressor={0.75}>
                    <h1>{this.props.state.orderCount !== undefined ? this.props.state.orderCount : 0}</h1>
                  </ReactFitText>
                  <ReactFitText minFontSize={16} maxFontSize={28}>
                    <p>bestellingen</p>
                  </ReactFitText>
                </div>
              </Col>
              <Col lg={2}>
                <div className='dashboard-card'>
                  <ReactFitText minFontSize={16} compressor={0.75}>
                    <h1>€ {this.props.state.total_income !== undefined ? this.props.state.total_income : "0.00"}</h1>
                  </ReactFitText>
                  <ReactFitText minFontSize={16} maxFontSize={28}>
                    <p>opbrengsten</p>
                  </ReactFitText>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className='dashboard-select-container'>
                <Form.Control className='dashboard-select custom-select' as='select' disabled={this.props.state.orderData.length > 0 ? false : true} onChange={(e) => this.props.handleYearChange(e)}>
                  {this.props.buildYearDropdown()}
                </Form.Control>
              </Col>
              <Col className='dashboard-select-container'>
                <Form.Control className='dashboard-select custom-select' as='select' disabled={this.props.state.orderData.length > 0 ? false : true} onChange={(e) => this.props.handleGraphYChange(e)}>
                  <option value='aantal'>Aantal</option>
                  <option value='opbrengsten'>Opbrengsten</option>
                </Form.Control>
              </Col>
            </Row>
            <Row className='dashboard-fill'>
              <Col className={this.props.state.orderData.length > 0 ? "" : "canvas-blurry"} style={window.innerWidth > 1366 ? { height: "100%" } : { height: "400px" }}>
                <div className={this.props.state.orderData.length > 0 ? "hide" : "graph-no-data-overlay"}>
                  <h1>Geen gegevens</h1>
                </div>

                {this.props.state.yAxesData === "aantal" || this.props.state.yAxesData === undefined ? (
                  <DashboardAmountChart settings={this.props.state.settings} orderData={this.props.state.orderData} selectedYear={this.props.state.selectedYear} tooltipEnabled={this.props.state.orderData.length > 0 ? true : false} />
                ) : (
                  <DashboardPriceChart settings={this.props.state.settings} orderData={this.props.state.orderData} selectedYear={this.props.state.selectedYear} tooltipEnabled={this.props.state.orderData.length > 0 ? true : false} />
                )}
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default Dashboard;
