const formatPrice = (price) => {
  return price
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",");
};

export const extractNumbersFromString = (str) => {
  return str.replace(/[^0-9]+/g, "");
};

export const removeNumbersFromString = (str) => {
  return str.replace(/[0-9]/g, "");
};

export const capitalizeEachWord = (str) => {
  if (str === null)
    return "";
  str = str.toLowerCase();

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getFilenameFromPath = (path = "") => {
  if (path === null || path instanceof File)
    return "";
  return path.split("/").pop()
}

export default formatPrice;
