const { capitalizeEachWord } = require("../misc/functions");

export default class Order {
  // Constructor
  constructor(
    order_id = "",
    order_customer_id = "",
    order_customer_name = "",
    order_total_price = "",
    order_date = null,
    order_JSON = {},
    order_delivery_street = "",
    order_delivery_number = "",
    order_delivery_postal = "",
    order_delivery_city = "",
    order_delivery_country = "",
    order_payment_method = "",
    order_payment_status = ""
  ) {
    this.order_id = order_id;
    this.order_customer_id = order_customer_id;
    this.order_customer_name = order_customer_name;
    this.order_total_price = order_total_price;
    this.order_date = order_date;
    this.order_JSON = order_JSON;
    this.order_delivery_street = order_delivery_street;
    this.order_delivery_number = order_delivery_number;
    this.order_delivery_postal = order_delivery_postal;
    this.order_delivery_city = order_delivery_city;
    this.order_delivery_country = order_delivery_country;
    this.order_payment_method = order_payment_method;
    this.order_payment_status = order_payment_status;
  }

  // Getters & Setters
  get order_id() {
    return this._order_id;
  }

  set order_id(id) {
    this._order_id = id !== undefined ? id : "";
  }

  get order_customer_id() {
    return this._order_customer_id;
  }

  set order_customer_id(customer_id) {
    this._order_customer_id = customer_id !== undefined ? customer_id : "";
  }

  get order_customer_name() {
    return this._order_customer_name;
  }

  set order_customer_name(name) {
    this._order_customer_name = name !== undefined ? name : "";
  }

  get order_total_price() {
    return this._order_total_price;
  }

  set order_total_price(total_price) {
    this._order_total_price = total_price !== undefined ? total_price : "";
  }

  get order_date() {
    if (this._order_date === "") {
      return null;
    } else {
      return new Date(this._order_date);
    }
  }

  set order_date(date) {
    this._order_date = date !== undefined ? date : null;
  }

  get order_JSON() {
    let parsedJSON = JSON.parse(this._order_JSON);
    return parsedJSON;
  }

  set order_JSON(json) {
    this._order_JSON = json !== undefined ? json : {};
  }

  get order_delivery_street() {
    return this._order_delivery_street;
  }

  set order_delivery_street(street) {
    this._order_delivery_street = capitalizeEachWord(street);
  }

  get order_delivery_number() {
    return this._order_delivery_number;
  }

  set order_delivery_number(number) {
    this._order_delivery_number = number !== undefined && number !== null ? number.toUpperCase() : "";
  }

  get order_delivery_postal() {
    return this._order_delivery_postal;
  }

  set order_delivery_postal(postal) {
    this._order_delivery_postal = postal !== undefined ? postal : "";
  }

  get order_delivery_city() {
    return this._order_delivery_city;
  }

  set order_delivery_city(city) {
    this._order_delivery_city = city !== undefined && city !== null ? capitalizeEachWord(city) : "";
  }

  get order_delivery_country() {
    return this._order_delivery_country;
  }

  set order_delivery_country(country) {
    this._order_delivery_country = country !== undefined && country !== null ? capitalizeEachWord(country) : "";
  }

  get order_payment_method() {
    return this._order_payment_method;
  }

  set order_payment_method(payment_method) {
    this._order_payment_method = payment_method !== undefined ? payment_method : "";
  }

  get order_payment_status() {
    return this._order_payment_status;
  }

  set order_payment_status(payment_status) {
    this._order_payment_status = payment_status !== undefined ? payment_status : "";
  }

  setDataFromObject(obj) {
    Object.assign(this, obj);
  }

  getFormattedAddress() {
    return `${this.order_delivery_street} ${this.order_delivery_number}, ${this.order_delivery_postal} ${this.order_delivery_city} ${this.order_delivery_country !== "" ? "(" + this.order_delivery_country + ")" : ""}`;
  }

  toJson(type) {
    let json = {};

    switch (type.toLowerCase()) {
      case "delete":
        json = {
          order_id: this.order_id,
        };
        break;
      default:
        break;
    }

    return json;
  }
}
