import React from "react";
import LoginContainer from "./pages/LoginContainer";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <LoginContainer />;
  }
}

export default App;
