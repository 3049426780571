import React from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import { MaterialGroupNameTooltip, MaterialRealNameTooltip, MaterialTypeTooltip, MaterialReplaceVariableTooltip, MaterialTextureTooltip, MaterialPriceScaleTooltip } from "../../tooltips/MaterialTooltips";

class AddMaterialModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal show={this.props.addMaterial} onHide={this.props.toggleAddMaterial}>
        <Modal.Header closeButton>
          <Modal.Title>Materiaal Toevoegen</Modal.Title>
        </Modal.Header>
        <Form method='POST' encType='multipart/form-data' onSubmit={this.props.submitAddMaterial}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>
                Materiaalgroep Naam <MaterialGroupNameTooltip />
              </Form.Label>
              <Form.Control type='text' placeholder='Materiaalgroep Naam' name='material_base_name' />
              <span className={this.props.formErrors["material_base_name"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["material_base_name"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Reële Materiaalnaam <MaterialRealNameTooltip />
              </Form.Label>
              <Form.Control type='text' placeholder='Materiaal Naam' name='material_real_name' />
              <span className={this.props.formErrors["material_real_name"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["material_real_name"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Materiaal Vervangvariabele <MaterialReplaceVariableTooltip />
              </Form.Label>
              <Form.Control type='text' placeholder='Materiaal Vervangvariabele' name='material_replace_variable' />
              <span className={this.props.formErrors["material_replace_variable"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["material_replace_variable"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Materiaal Type <MaterialTypeTooltip />
              </Form.Label>
              <div>
                <div className='radio-button-group'>
                  <input type='radio' name='material_type' value='inner' id='inner' defaultChecked />
                  <label htmlFor='inner'>Binnen</label>
                </div>
                <div className='radio-button-group'>
                  <input type='radio' name='material_type' value='outer' id='outer' />
                  <label htmlFor='outer'>Buiten</label>
                </div>
              </div>
              <span className={this.props.formErrors["material_type"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["material_type"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Textuur <MaterialTextureTooltip />
              </Form.Label>
              <Form.Control type='file' name='material_texture' id='material_texture' accept='.png, .jpg, .jpeg' />
              <span className={this.props.formErrors["material_texture"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["material_texture"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Prijsfactor <MaterialPriceScaleTooltip />
              </Form.Label>
              <Form.Control type='number' step='0.01' placeholder='Prijsfactor' name='material_price_scale' />
              <span className={this.props.formErrors["material_price_scale"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["material_price_scale"]}</span>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {this.props.formErrors["submitError"] ? <Alert variant='danger'>{this.props.formErrors["submitError"]}</Alert> : null}
            <Button variant='primary' type='submit'>
              Toevoegen
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default AddMaterialModal;
