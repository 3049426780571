import React from "react";
import SideNavContainer from "../components/SideNavContainer";
import Orders from "../components/Orders";
import Order from "../models/Order";
import formatPrice from "../misc/functions";
import { FaTrashAlt } from "react-icons/fa";

import ApiRequest from "../services/ApiRequest";

class OrdersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadError: false,
      loadErrorMessage: [],
      /* Toggle Popup */
      viewOrder: false, // Toggle ViewOrderPopup
      deleteOrder: false, // Toggle DeleteOrderPopup

      /* Form */
      selectedOrder: {}, // Current order data
      errors: {}, // Form Errors


      /* Search + Sort */
      sort: {
        order_id: "desc",
        order_date: "desc",
        order_customer_name: "desc",
        order_total_price: "desc",
        order_payment_method: "desc",
        order_payment_status: "desc",
      },
      searchValue: "", // Search Value

      /* Orders */
      currentDocument: 0, //Used for 'view' of orders (each order can have multiple documents, this is so we know which document we are on)
      backupOrders: [], //Used for filtering
      orders: [],
    };
  }

  // On mount, get all data from database + remove Loading Component when finished
  async componentDidMount() {
    let loadError = false;
    let loadErrorMessage = [];

    await ApiRequest.axiosInstance
      .post("/order/readAllOrdersWithCustomerName.php")
      .then((res) => {
        if (ApiRequest.callSuccess(res)) {
          if (ApiRequest.callHasRecords(res)) {
            let orders = [];
            for (let i = 0, len = res.data.records.length; i < len; i++) {
              let order = new Order();
              order.setDataFromObject(res.data.records[i]);
              orders.push(order);
            }

            orders.sort((a, b) => (a.order_id > b.order_id ? 1 : -1));

            this.setState({
              orders: orders,
            });
          }
        } else if (ApiRequest.callNoResults(res)) {
          this.setState({
            orders: [],
          });
        } else {
          loadError = true;
          if (res.data.message !== undefined) {
            loadErrorMessage.push(res.data.message);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        loadError = true;
        if (err.response.data.messsage !== undefined) {
          loadErrorMessage.push(err.response.data.message);
        }
      })
      .then(() => {
        console.log("Orders 'Read' Request Finished");
        setTimeout(() => {
          this.setState({
            loading: false,
            loadError: loadError,
            loadErrorMessage: loadErrorMessage,
          });
        }, 500);
      });
  }

  componentWillUnmount() {
    // fix for Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  /* Search & Sort functions */

  // Handle Changes in 'Live Search' of Table (Search Box) + Update Table (Hide Orders that Don't Match Criteria)
  searchChange = (value) => {
    this.setState({ searchValue: value });

    let backupOrders = this.state.backupOrders;

    if (backupOrders.length === 0) {
      backupOrders = [...this.state.orders];
      this.setState({ backupOrders: backupOrders });
    }

    let orders = [];

    for (let i = 0, len = backupOrders.length; i < len; i++) {
      if (backupOrders[i].order_id.toString().toLowerCase().includes(value.toLowerCase())) {
        orders.push(backupOrders[i]);
      }
    }

    this.setState({ orders: orders });
  };

  // Clear 'Live Search' (Search Box) + Update Table (Display all Orders)
  clearSearch = () => {
    this.setState({ searchValue: "", orders: this.state.backupOrders });
  };

  // Ascending/Descending Sort Orders (onClick 'th')
  sortOrders = (key) => {
    let orders = this.state.orders;
    let sort = this.state.sort;

    if (this.state.sort[key] === "asc") {
      sort[key] = "desc";
      this.setState({ sort });
      orders.sort((a, b) => (a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1));
    } else {
      sort[key] = "asc";
      this.setState({ sort });
      orders.sort((a, b) => (a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1));
    }

    this.setState({ orders });
  };

  /* Popup (view) with multiple documents */
  // Increments the document with 1 (get next document)
  incrementDocument = () => {
    let documentLength = this.state.selectedOrder.order_JSON.Documents.length;

    if (this.state.currentDocument < documentLength - 1) {
      this.setState({ currentDocument: this.state.currentDocument + 1 });
    }
  };

  // Decrements the document with 1 (get previous document)
  decrementDocument = () => {
    if (this.state.currentDocument > 0) {
      this.setState({ currentDocument: this.state.currentDocument - 1 });
    }
  };

  /* Download Order */

  downloadOrderJSON = order => {
    let fileContents = order.order_JSON;
    let fileName = "KPWJSON_" + order.order_id;

    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(fileContents));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", fileName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  setHandlingStatus = (orderToChange, status) => {
    console.log(orderToChange)
    console.log(status)
    this.state.orders.forEach(async order => {
      if (order.order_id === orderToChange.order_id) {
        order.order_handling_status = status;

        let loadError = false
        let loadErrorMessage = ""

        let formData = new FormData();
        formData.append("order_id", order.order_id);
        formData.append("order_handling_status", order.order_handling_status);

        await ApiRequest.axiosInstance({
          method: "post",
          url: "/order/setOrderHandlingStatus.php",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            if (ApiRequest.callSuccess(res)) {

            } else {
              loadError = true;
              if (res.data.message !== undefined) {
                loadErrorMessage.push(res.data.message);
              }
            }
          })
          .catch((err) => {
            console.error(err);
            loadError = true;
            if (err.response.data.messsage !== undefined) {
              loadErrorMessage.push(err.response.data.message);
            }
          })
          .then(() => {
            console.log("Orders 'Read' Request Finished");
            setTimeout(() => {
              this.setState({
                loading: false,
                loadError: loadError,
                loadErrorMessage: loadErrorMessage,
              });
            }, 500);
          });
      }
    })
  }

  /* Toggle Popups */

  // Show/Hide 'View Order'
  toggleViewOrder = (order) => {
    this.setState({
      viewOrder: !this.state.viewOrder,
      currentDocument: 0,
      selectedOrder: order !== undefined ? order : this.state.selectedOrder,
    });
  };

  // Show/Hide 'Delete Order' Pop-up + Set 'fields' (Used for Displaying 'plint' + having the 'id' )
  toggleDeleteOrder = (order) => {
    this.setState({
      selectedOrder: order !== undefined ? order : this.state.selectedOrder,
      deleteOrder: !this.state.deleteOrder,
    });
  };

  /* Update State on Successful HTTP Request */

  // Delete orders from 'this.state.orders'
  _deleteStateOrder = (id) => {
    let orders = this.state.orders;

    for (let i = 0, len = orders.length; i < len; i++) {
      if (orders[i].order_id === id) {
        orders.splice(i, 1);

        this.setState({ orders });

        break;
      }
    }
  };


  // Handle Submit 'Delete Order' Pop-up
  submitDeleteOrder = async (e) => {
    e.preventDefault();

    let order = this.state.selectedOrder;

    let data = order.toJson("delete");

    await ApiRequest.axiosInstance
      .post("/order/deleteOrder.php", data)
      .then((res) => {
        if (ApiRequest.callSuccess(res)) {
          this._deleteStateOrder(data.order_id);
          this.toggleDeleteOrder();
        } else {
          let errors = this.state.errors;
          errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        let errors = this.state.errors;
        errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
        this.setState({
          errors: errors,
        });
      })
      .then(() => {
        console.log("Order 'Delete' Request Finished");
      });
  };

  /* Build Table */

  // Fill Table with Data from 'this.state.materials'
  populateTable = () => {
    const dateOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };

    let orders = this.state.orders;

    let rows = [];

    for (let i = 0, len = orders.length; i < len; i++) {
      let children = [];

      children.push(<td key={i + "-id"}>{orders[i].order_id}</td>);
      children.push(<td key={i + "-date"}>{orders[i].order_date.toLocaleDateString("nl-NL", dateOptions)}</td>);
      children.push(<td key={i + "-customer"}>{orders[i].order_customer_name}</td>);
      children.push(<td key={i + "-price"}>€ {formatPrice(+orders[i].order_total_price)}</td>);
      children.push(<td key={i + "-payment_method"}>{orders[i].order_payment_method}</td>);
      children.push(<td key={i + "-payment_status"}>{orders[i].order_payment_status}</td>);
      children.push(<td key={i + "-handling_status"}>{orders[i].order_handling_status}</td>);

      children.push(
        <td key={i + "-view"} className='td-icon' onClick={() => this.toggleViewOrder(orders[i])}>
          <span className='view-order-span'>Bekijk</span>
        </td>
      );

      children.push(
        <td key={i + "-download"} className='td-icon' onClick={() => this.downloadOrderJSON(orders[i])}>
          <span className='download-order-span'>Download</span>
        </td>
      );

      children.push(
        <td key={i + "-delete"} className='td-icon' onClick={() => this.toggleDeleteOrder(orders[i])}>
          <FaTrashAlt />
        </td>
      );

      rows.push(
        <tr key={i} id={i}>
          {children}
        </tr>
      );
    }

    return rows;
  };

  render() {
    return (
      <div>
        <SideNavContainer />
        <Orders state={this.state} toggleViewOrder={this.toggleViewOrder} toggleDeleteOrder={this.toggleDeleteOrder} submitDeleteOrder={this.submitDeleteOrder} searchChange={this.searchChange} clearSearch={this.clearSearch} populateTable={this.populateTable} sortOrders={this.sortOrders} incrementDocument={this.incrementDocument} decrementDocument={this.decrementDocument} setHandlingStatus={this.setHandlingStatus} />
      </div>
    );
  }
}

export default OrdersContainer;
