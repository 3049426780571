import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { BsQuestionCircleFill } from "react-icons/bs";

function HelpStepIDTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Het volgnummer van de stap waarvoor het hulpvenster getoond moet worden. (Startend met het kamertype op stap 0)</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function HelpTextTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Hulptekst die getoond moet worden (max. 500 karakters)</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function HelpGifFileTooltip() {
  return (
    <OverlayTrigger
      placement='right'
      overlay={
        <Tooltip>
          Afbeelding die getoond moet worden
          <br />
          (.jpg, .jpeg, .png, .gif)
        </Tooltip>
      }>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

export { HelpStepIDTooltip, HelpGifFileTooltip, HelpTextTooltip };
