import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { BsQuestionCircleFill } from "react-icons/bs";

function SettingsAPIKeyTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>De key van uw Mollie API</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function SettingsLogoTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Logo dat getoond zal worden op KorpusWeb</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function SettingsKPSTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Basis pad van waaruit Korpus de kamer sjablonen kan vinden</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function SettingsModuleKPOTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Basis pad van waaruit Korpus de module sjablonen kan vinden</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function SettingsFeatureKPOTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Basis pad van waaruit Korpus de feature sjablonen kan vinden</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function SettingsPrimaryColorTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Primaire kleur voor KorpusWeb</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function SettingsSecondaryColorTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Secundaire kleur voor KorpusWeb</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function SettingsBackgroundColorTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Achtergrondkleur van KorpusWeb</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function SettingsControlTextColorTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Tekst kleur voor knoppen van KorpusWeb <br/> TIP: Zorg dat de kleur zowel zichtbaar is op de primaire als de secundaire kleur!</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function SettingsContactTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Contact gegevens die getoond zullen worden op KorpusWeb zodat een klant u kan contacteren</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function SettingsCountriesTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Landen waaraan u wilt leveren</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}



export { SettingsAPIKeyTooltip, SettingsLogoTooltip, SettingsPrimaryColorTooltip, SettingsSecondaryColorTooltip, SettingsControlTextColorTooltip, SettingsBackgroundColorTooltip, SettingsContactTooltip, SettingsCountriesTooltip, SettingsKPSTooltip, SettingsModuleKPOTooltip, SettingsFeatureKPOTooltip };
