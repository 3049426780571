import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";


class EditHelpsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal show={this.props.exampleHelp} onHide={this.props.toggleExampleHelp}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Help</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          {this.props.selectedHelp.help_gif_path !== null ? <Image width="100%" src={"../KPWC/"+this.props.selectedHelp.help_gif_path}></Image> : null}
          {this.props.selectedHelp.help_text !== null ? <p>{this.props.selectedHelp.help_text}</p> : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.toggleExampleHelp}>
            Sluiten
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditHelpsModal;
