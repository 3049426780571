export default class Material {
  constructor(material_id = "", material_base_name = "", material_real_name = "", material_replace_variable = "", material_type = "", material_texture_path = "", material_price_scale = "") {
    this.material_id = material_id;
    this.material_base_name = material_base_name;
    this.material_real_name = material_real_name;
    this.material_replace_variable = material_replace_variable;
    this.material_type = material_type;
    this.material_texture_path = material_texture_path;
    this.material_price_scale = material_price_scale;
  }

  get material_id() {
    return this._material_id;
  }

  set material_id(id) {
    this._material_id = id !== undefined ? id : "";
  }

  get material_base_name() {
    return this._material_base_name;
  }

  set material_base_name(name) {
    // .replace white spaces by an actual space (otherwise the searchfield would never show items that contain a space (after searching for an item using a space in the searchQuery))
    this._material_base_name = name !== undefined ? name.replace(/\s+/g, " ") : "";
  }

  get material_real_name() {
    return this._material_real_name;
  }

  set material_real_name(name) {
    // .replace white spaces by an actual space (otherwise the searchfield would never show items that contain a space (after searching for an item using a space in the searchQuery))
    this._material_real_name = name !== undefined ? name.replace(/\s+/g, " ") : "";
  }

  get material_replace_variable() {
    return this._material_replace_variable;
  }

  set material_replace_variable(name) {
    // .replace white spaces by an actual space (otherwise the searchfield would never show items that contain a space (after searching for an item using a space in the searchQuery))
    this._material_replace_variable = name !== undefined ? name.replace(/\s+/g, " ") : "";
  }

  get material_type() {
    return this._material_type;
  }

  set material_type(type) {
    this._material_type = type !== undefined ? type : "";
  }

  get material_texture_path() {
    return this._material_texture_path;
  }

  set material_texture_path(path) {
    this._material_texture_path = path !== undefined ? path : "";
  }

  get material_price_scale() {
    return this._material_price_scale;
  }

  set material_price_scale(price_scale) {
    this._material_price_scale = price_scale !== undefined ? Number(price_scale).toFixed(2) : "";
  }

  setDataFromObject(obj) {
    Object.assign(this, obj);
  }

  toJson(type) {
    let json = {};

    switch (type.toLowerCase()) {
      case "add":
        json = {
          material_base_name: this.material_base_name,
          material_real_name: this.material_real_name,
          material_replace_variable: this.material_replace_variable,
          material_type: this.material_type,
          material_texture_path: this.material_texture_path,
          material_price_scale: this.material_price_scale,
        };
        break;
      case "edit":
        json = {
          material_id: this.material_id,
          material_base_name: this.material_base_name,
          material_real_name: this.material_real_name,
          material_replace_variable: this.material_replace_variable,
          material_type: this.material_type,
          material_texture_path: this.material_texture_path,
          material_price_scale: this.material_price_scale,
        };
        break;
      case "delete":
        json = {
          material_id: this.material_id,
        };
        break;
      default:
        break;
    }

    return json;
  }
}
