import React from "react";

import Materials from "./../components/Materials";
import SideNavContainer from "../components/SideNavContainer";

import Material from "../models/Material";

import { FaTrashAlt, FaPencilAlt } from "react-icons/fa";

import ApiRequest from "../services/ApiRequest";

class MaterialsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadError: false,
      loadErrorMessage: [],
      /* Toggle Popup */
      addMaterial: false, // Toggle AddMaterialPopup
      editMaterial: false, // Toggle EditMaterialPopup
      deleteMaterial: false, // Toggle DeleteMaterialPopup

      /* Form */
      selectedMaterial: new Material(),
      errors: {}, // Form Errors

      /* Search + Sort */
      sort: {
        material_name: "desc",
        material_price_scale: "desc",
      },
      searchValue: "", // Search Value

      /* Materials */
      backupMaterials: [], // Used for filtering
      materials: [],
    };
  }

  // On mount, get all data from database
  async componentDidMount() {
    let loadError = false;
    let loadErrorMessage = [];

    await ApiRequest.axiosInstance
      .post("/material/readAllMaterials.php")
      .then((res) => {
        if (ApiRequest.callSuccess(res)) {
          if (ApiRequest.callHasRecords(res)) {
            let materials = [];

            for (let i = 0, len = res.data.records.length; i < len; i++) {
              let material = new Material();
              material.setDataFromObject(res.data.records[i]);
              materials.push(material);
            }

            materials.sort((a, b) => (a.material_id > b.material_id ? 1 : -1));

            this.setState({
              materials: materials,
            });
          }
        } else if (ApiRequest.callNoResults(res)) {
          this.setState({
            materials: [],
          });
        } else {
          loadError = true;
          if (res.data.message !== undefined) {
            loadErrorMessage.push(res.data.message);
          }
        }
      })
      .catch((err) => {
        loadError = true;
        if (err.response) {
          if (err.response.data.message !== undefined) {
            loadErrorMessage.push(err.response.data.message);
          }
        }
      })
      .then(() => {
        console.log("Materials 'Read' Request Finished");
        console.log("SETTING STATE");
        console.log("LOAD ERROR: " + loadError);

        setTimeout(() => {
          this.setState({
            loading: false,
            loadError: loadError,
            loadErrorMessage: loadErrorMessage,
          });
        }, 500);
      });
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  /* Search & Sort Functions */

  // Handle Changes in 'Live Search' of Table (Search Box) + Update Table (Hide Materials that Don't Match Criteria)
  searchChange = (value) => {
    this.setState({ searchValue: value });

    let backupMaterials = this.state.backupMaterials;

    if (this.state.backupMaterials.length === 0) {
      backupMaterials = [...this.state.materials];
      this.setState({ backupMaterials: [...this.state.materials] });
    }

    let materials = [];

    for (let i = 0, len = backupMaterials.length; i < len; i++) {
      if (backupMaterials[i].material_name.toLowerCase().includes(value.toLowerCase())) {
        materials.push(backupMaterials[i]);
      }
    }

    this.setState({ materials: materials });
  };

  // Clear 'Live Search' (Search Box) + Update Table (Display all Materials)
  clearSearch = () => {
    this.setState({ searchValue: "", materials: this.state.backupMaterials });
  };

  // Ascending/Descending Sort Materials (onClick 'th')
  sortMaterials = (key) => {
    let materials = this.state.materials;
    let sort = this.state.sort;

    if (this.state.sort[key] === "asc") {
      sort[key] = "desc";
      this.setState({ sort });
      materials.sort((a, b) => (a[key].toString().toLowerCase() > b[key].toLowerCase() ? 1 : -1));
    } else {
      sort[key] = "asc";
      this.setState({ sort });
      materials.sort((a, b) => (a[key].toString().toLowerCase() < b[key].toLowerCase() ? 1 : -1));
    }

    this.setState({ materials });
  };

  /* Form Functions */

  // Validate Form (Called on Submit of Add & Edit) + Create Error Messages if Needed
  _validForm = (e, isEdit = false) => {
    let selectedMaterial = this.state.selectedMaterial;
    let errors = {};
    let formIsValid = true;

    let formData = new FormData(e.target);
    let material_base_name = formData.get("material_base_name");
    let material_real_name = formData.get("material_real_name");
    let material_replace_variable = formData.get("material_replace_variable");
    let material_texture = formData.get("material_texture");
    let material_price_scale = formData.get("material_price_scale");
    let material_type = formData.get("material_type");

    if (material_base_name !== "") {
      selectedMaterial.material_base_name = material_base_name;
    } else {
      errors["material_base_name"] = "Materiaalgroep naam is verplicht";
    }

    if (material_real_name !== "") {
      selectedMaterial.material_real_name = material_real_name;
    } else {
      errors["material_real_name"] = "Materiaal reële naam is verplicht";
    }

    if (material_replace_variable !== "") {
      selectedMaterial.material_replace_variable = material_replace_variable;
    } else {
      errors["material_replace_variable"] = "Materiaal vervangvariabele is verplicht";
    }

    if (material_texture.name !== "") {
      selectedMaterial.material_texture_path = material_texture;
    } else if (isEdit) {
      selectedMaterial.material_texture_path = null;
    } else {
      errors["material_texture"] = "Textuur uploaden is verplicht";
    }

    if (material_price_scale !== "") {
      material_price_scale = +material_price_scale;
      if (material_price_scale > 0) {
        selectedMaterial.material_price_scale = material_price_scale;
      } else {
        errors["material_price_scale"] = "Prijsfactor moet groter dan 0 zijn";
      }
    } else {
      errors["material_price_scale"] = "Prijsfactor is verplicht";
    }

    if (material_type.name !== "") {
      selectedMaterial.material_type = material_type;
    } else {
      errors["material_type"] = "Materiaaltype is verplicht";
    }

    if (Object.keys(errors).length > 0) {
      formIsValid = false;
    }

    this.setState({ selectedMaterial: selectedMaterial, errors: errors });

    return formIsValid;
  };

  /* Popups (Add, Edit, Delete) */

  // Show/Hide 'Add Material' Pop-up + Clear all 'Changes' from 'fields' and 'errors'
  toggleAddMaterial = () => {
    this.setState({
      selectedMaterial: new Material(),
      errors: {},
      addMaterial: !this.state.addMaterial,
    });
  };

  // Show/Hide 'Edit Material' Pop-up + Clear 'errors' + set 'fields' (Required for Bypassing Validation when Submitting without Changing Anything)
  toggleEditMaterial = (material) => {
    this.setState({
      selectedMaterial: material !== undefined ? Object.assign(Object.create(Object.getPrototypeOf(material)), material) : this.state.selectedMaterial,
      errors: {},
      editMaterial: !this.state.editMaterial,
    });
  };

  // Show/Hide 'Delete Material' Pop-up + Set 'fields' (Used for Displaying 'material_name' + having the 'id' )
  // NOTE: Errors are not cleared, because we clear them on all others and delete doesn't have any errors linked to it as the only errors that could occur is something with the backend: eg. invalid ID,...
  toggleDeleteMaterial = (material) => {
    this.setState({
      deleteMaterial: !this.state.deleteMaterial,
      selectedMaterial: material !== undefined ? material : this.state.selectedMaterial,
    });
  };

  /* Update State on Successful HTTP Request */

  // Add material to 'this.state.material'
  _addStateMaterial = (material) => {
    let materials = this.state.materials;
    materials.push(material);
    this.setState({ materials });
  };

  // Update 'this.state.materials' with new values from 'Edit'
  _updateStateMaterial = (id) => {
    let materials = this.state.materials;

    for (let i = 0, len = materials.length; i < len; i++) {
      if (materials[i].material_id === id) {
        materials[i] = this.state.selectedMaterial;

        this.setState({ materials });

        break;
      }
    }
  };

  // Delete material from 'this.state.materials'
  _deleteStateMaterial = (id) => {
    let materials = this.state.materials;

    for (let i = 0, len = materials.length; i < len; i++) {
      if (materials[i].material_id === id) {
        materials.splice(i, 1);

        this.setState({ materials });

        break;
      }
    }
  };

  /* Submit Forms (Add, Edit, Delete) */

  // Handle Submit 'Add Material' Pop-up
  submitAddMaterial = (e) => {
    e.preventDefault();

    if (this._validForm(e)) {
      let formData = new FormData(e.target);

      ApiRequest.axiosInstance({
        method: "post",
        url: "/material/createMaterial.php",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          console.log(res);
          if (ApiRequest.callSuccess(res) && ApiRequest.callHasRecords(res)) {
            console.log(this.state.selectedMaterial)
            let material = this.state.selectedMaterial.toJson("add");

            console.log(material)

            let data = res.data.records[0];

            if (data.hasOwnProperty("MATERIAL_ID")) {
              material.material_id = data.MATERIAL_ID;
            }

            if (data.hasOwnProperty("MATERIAL_TEXTURE_PATH")) {
              material.material_texture_path = data.MATERIAL_TEXTURE_PATH;
            }
            console.log(material)
            this._addStateMaterial(material);
            this.toggleAddMaterial();
          } else {
            let errors = this.state.errors;
            errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
            this.setState({
              errors: errors,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          let errors = this.state.errors;
          errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        })
        .then(() => {
          console.log("Material 'Add' Request Finished");
        });
    }
  };

  // Handle Submit 'Edit Material' Pop-up
  submitEditMaterial = (e) => {
    e.preventDefault();

    if (this._validForm(e, true)) {
      let formData = new FormData(e.target);
      formData.set("material_id", this.state.selectedMaterial.material_id);

      ApiRequest.axiosInstance({
        method: "post",
        url: "/material/updateMaterial.php",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          console.log(res);
          if (ApiRequest.callSuccess(res) && ApiRequest.callHasRecords(res)) {
            let material = this.state.selectedMaterial.toJson("edit");

            const data = res.data.records[0];

            if (data.hasOwnProperty("MATERIAL_ID")) {
              material.material_id = data.MATERIAL_ID;
            }

            if (data.hasOwnProperty("MATERIAL_TEXTURE_PATH")) {
              material.material_texture_path = data.MATERIAL_TEXTURE_PATH;
            }

            this._updateStateMaterial(material.material_id);
            this.toggleEditMaterial();
          } else {
            let errors = this.state.errors;
            errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
            this.setState({
              errors: errors,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          let errors = this.state.errors;
          errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        })
        .then(() => {
          console.log("Material 'Edit' Request Finished");
        });
    }
  };

  // Handle Submit 'Delete Material' Pop-up
  submitDeleteMaterial = async (e) => {
    e.preventDefault();

    let material = this.state.selectedMaterial;

    let data = material.toJson("delete");

    await ApiRequest.axiosInstance
      .post("/material/deleteMaterial.php", data)
      .then((res) => {
        if (ApiRequest.callSuccess(res)) {
          this._deleteStateMaterial(data.material_id);
          this.toggleDeleteMaterial();
        } else {
          let errors = this.state.errors;
          errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        let errors = this.state.errors;
        errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
        this.setState({
          errors: errors,
        });
      })
      .then(() => {
        console.log("Material 'Delete' Request Finished");
      });
  };

  /* Build Table */

  // Fill Table with Data from 'this.state.materials'
  populateTable = () => {
    let materials = this.state.materials;

    let rows = [];

    for (let i = 0, len = materials.length; i < len; i++) {
      let items = [];

      items.push(<td key={i + "-material_base_name"}>{materials[i].material_base_name}</td>);
      items.push(<td key={i + "-material_real_name"}>{materials[i].material_real_name}</td>);
      items.push(<td key={i + "-material_replace_variable"}>{materials[i].material_replace_variable}</td>);
      items.push(<td key={i + "-material_type"}>{materials[i].material_type}</td>);
      items.push(<td key={i + "-material_price_scale"}>{materials[i].material_price_scale}</td>);
      items.push(
        <td key={i + "-edit"} className='td-icon' onClick={() => this.toggleEditMaterial(materials[i])}>
          <FaPencilAlt />
        </td>
      );

      items.push(
        <td key={i + "-delete"} className='td-icon' onClick={() => this.toggleDeleteMaterial(materials[i])}>
          <FaTrashAlt />
        </td>
      );

      rows.push(
        <tr key={i} id={i}>
          {items}
        </tr>
      );
    }

    return rows;
  };

  render() {
    return (
      <div>
        <SideNavContainer />
        <Materials
          state={this.state}
          toggleAddMaterial={this.toggleAddMaterial}
          toggleEditMaterial={this.toggleEditMaterial}
          toggleDeleteMaterial={this.toggleDeleteMaterial}
          submitAddMaterial={this.submitAddMaterial}
          submitEditMaterial={this.submitEditMaterial}
          submitDeleteMaterial={this.submitDeleteMaterial}
          searchChange={this.searchChange}
          clearSearch={this.clearSearch}
          populateTable={this.populateTable}
          sortMaterials={this.sortMaterials}
        />
      </div>
    );
  }
}

export default MaterialsContainer;
