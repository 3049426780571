import React from "react";

import Login from "../components/Login";
import { Redirect } from "react-router";
import ApiRequest from "../services/ApiRequest";

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      /* Form */
      fields: {}, // Handle onChange of FormElements
      errors: {}, // Form Errors

      isLoggedIn: window.sessionStorage.getItem("isLoggedIn"),
    };
  }

  /* Form Functions */

  // Validate Email using Regex
  _validEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
  };

  // Handle Input Changes
  handleChange = (e) => {
    let name = e.target.getAttribute("name");
    let value = e.target.value;

    let fields = this.state.fields;

    fields[name] = value;

    this.setState({ fields });
  };

  // Validate Form + Create Error Messages if Needed
  _validForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email is verplicht";
    } else if (!this._validEmail(fields["email"])) {
      formIsValid = false;
      errors["email"] = "Geen geldig emailadres";
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Wachtwoord is verplicht";
    }

    this.setState({ errors: errors });

    return formIsValid;
  };

  /* Submit Forms */

  // Handle Submit Login
  handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData(e.target);

    //let encrypted = CryptoJS.AES.encrypt(formData.get("password"), "Vl3c@dEncrypt1on P4ssphra5e#3939")

    // var data = encrypted.ciphertext.toString()
    // var key = encrypted.key.toString()
    // var iv = encrypted.iv.toString()

    // console.log([data, key, iv])

    //formData.set("password", encrypted);


    if (this._validForm()) {
      // Do fetch(), or axios(), or any http request to server

      await ApiRequest.axiosInstance({
        method: "post",
        url: "/security/dashboardLogin.php",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        console.log(response)
        if (ApiRequest.callSuccess(response)) {
          window.sessionStorage.setItem("isLoggedIn", true);
          this.setState({ isLoggedIn: true });
        } else {
          let errors = this.state.errors;
          errors["submitError"] = response.data.message;
          this.setState({
            errors: errors,
          });
        }
      })

      // window.sessionStorage.setItem("isLoggedIn", true);
      // this.setState({ isLoggedIn: true });
    }
  };

  render() {
    if (this.state.isLoggedIn) {
      return <Redirect to='/dashboard' />;
    }

    return <Login formErrors={this.state.errors} handleSubmit={this.handleSubmit} handleChange={this.handleChange} />;
  }
}

export default LoginContainer;
