export default class Plint_Configuration {
    constructor(plint_id = "", min_width = "", max_width = "", bottom_dim = "", top_dim = "", left_dim = "", right_dim = "", base_price = "", bottom_kpo = "", top_kpo = "", left_kpo = "", right_kpo = "", room_id = "") {
        this.plint_id = plint_id;
        this.min_width = min_width;
        this.max_width = max_width;
        this.bottom_dim = bottom_dim;
        this.top_dim = top_dim;
        this.left_dim = left_dim;
        this.right_dim = right_dim;
        this.base_price = base_price;
        this.bottom_kpo = bottom_kpo;
        this.top_kpo = top_kpo;
        this.left_kpo = left_kpo;
        this.right_kpo = right_kpo;
        this.room_id = room_id;
    }

    get plint_id() {
        return this._plint_id
    }

    set plint_id(id) {
        this._plint_id = id !== undefined ? id : "";
    }

    get min_width() {
        return this._min_width;
    }

    set min_width(width) {
        this._min_width = width !== undefined ? width : "";
    }

    get max_width() {
        return this._max_width;
    }

    set max_width(width) {
        this._max_width = width !== undefined ? width : "";
    }

    get bottom_dim() {
        return this._bottom_dim;
    }

    set bottom_dim(dim) {
        this._bottom_dim = dim !== undefined ? dim : "";
    }

    get top_dim() {
        return this._top_dim;
    }

    set top_dim(dim) {
        this._top_dim = dim !== undefined ? dim : "";
    }

    get left_dim() {
        return this._left_dim;
    }

    set left_dim(dim) {
        this._left_dim = dim !== undefined ? dim : "";
    }

    get right_dim() {
        return this._right_dim;
    }

    set right_dim(dim) {
        this._right_dim = dim !== undefined ? dim : "";
    }

    get base_price() {
        return this._base_price;
      }
    
    set base_price(price) {
        this._base_price = price !== undefined ? Number(price).toFixed(2) : "";
    }

    get bottom_kpo() {
        return this._bottom_kpo;
    }

    set bottom_kpo(kpo) {
        this._bottom_kpo = kpo !== undefined ? kpo : "";
    }

    get top_kpo() {
        return this._top_kpo;
    }

    set top_kpo(kpo) {
        this._top_kpo = kpo !== undefined ? kpo : "";
    }

    get left_kpo() {
        return this._left_kpo;
    }

    set left_kpo(kpo) {
        this._left_kpo = kpo !== undefined ? kpo : "";
    }

    get right_kpo() {
        return this._right_kpo;
    }

    set right_kpo(kpo) {
        this._right_kpo = kpo !== undefined ? kpo : "";
    }

    get room_id() {
        return this._room_id
    }

    set room_id(id) {
        this._room_id = id !== undefined ? id : "";
    }

    setDataFromObject(obj) {
        Object.assign(this, obj);
    }

    toJson(type) {
        let json = {};
    
        switch (type.toLowerCase()) {
          case "add":
            json = {
                min_width: this.min_width,
                max_width: this.max_width,
                bottom_dim: this.bottom_dim,
                top_dim: this.top_dim,
                left_dim: this.left_dim,
                right_dim: this.right_dim,
                base_price: this.base_price,
                bottom_kpo: this.bottom_kpo,
                top_kpo: this.top_kpo,
                left_kpo: this.left_kpo,
                right_kpo: this.right_kpo,
                room_id: this.room_id,
            };
            break;
          case "edit":
            json = {
                plint_id: this.plint_id,
                min_width: this.min_width,
                max_width: this.max_width,
                bottom_dim: this.bottom_dim,
                top_dim: this.top_dim,
                left_dim: this.left_dim,
                right_dim: this.right_dim,
                base_price: this.base_price,
                bottom_kpo: this.bottom_kpo,
                top_kpo: this.top_kpo,
                left_kpo: this.left_kpo,
                right_kpo: this.right_kpo,
                room_id: this.room_id,
            };
            break;
          case "delete":
            json = {
                plint_id: this.plint_id,
            };
            break;
          default:
            break;
        }
    
        return json;
      }
}
