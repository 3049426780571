export default class Setting {
  constructor(settings_id, settings_logo, settings_primary_color, settings_secondary_color, settings_contact_name, settings_contact_email, settings_contact_phone, settings_room_kps_path) {
    this.settings_id = settings_id;
    this.settings_logo = settings_logo;
    this.settings_primary_color = settings_primary_color;
    this.settings_secondary_color = settings_secondary_color;
    this.settings_contact_name = settings_contact_name;
    this.settings_contact_email = settings_contact_email;
    this.settings_contact_phone = settings_contact_phone;
    this.settings_room_kps_path = settings_room_kps_path;
  }

  get settings_id() {
    return this._settings_id;
  }

  set settings_id(id) {
    this._settings_id = id !== undefined ? id : "";
  }

  get settings_logo() {
    return this._settings_logo;
  }

  set settings_logo(logo) {
    this._settings_logo = logo !== undefined ? logo : "";
  }

  get settings_primary_color() {
    return this._settings_primary_color;
  }

  set settings_primary_color(color) {
    this._settings_primary_color = color !== undefined ? color : "";
  }

  get settings_secondary_color() {
    return this._settings_secondary_color;
  }

  set settings_secondary_color(color) {
    this._settings_secondary_color = color !== undefined ? color : "";
  }

  get settings_contact_name() {
    return this._settings_contact_name;
  }

  set settings_contact_name(name) {
    this._settings_contact_name = name !== undefined ? name : "";
  }

  get settings_contact_email() {
    return this._settings_contact_email;
  }

  set settings_contact_email(email) {
    this._settings_contact_email = email !== undefined ? email : "";
  }

  get settings_contact_phone() {
    return this._settings_contact_phone;
  }

  set settings_contact_phone(phone) {
    this._settings_contact_phone = phone !== undefined ? phone : "";
  }

  get settings_room_kps_path() {
    return this._settings_room_kps_path;
  }

  set settings_room_kps_path(path) {
    this._settings_room_kps_path = path !== undefined ? path : "";
  }
  
  get settings_module_kpo_path() {
    return this._settings_module_kpo_path;
  }

  set settings_module_kpo_path(path) {
    this._settings_module_kpo_path = path !== undefined ? path : "";
  }

  get settings_feature_kpo_path() {
    return this._settings_feature_kpo_path;
  }

  set settings_feature_kpo_path(path) {
    this._settings_feature_kpo_path = path !== undefined ? path : "";
  }
  
  setDataFromObject(obj) {
    Object.assign(this, obj);
  }
}
