import React from "react";
import SideNavContainer from "../components/SideNavContainer";
import Rooms from "../components/Rooms";
import Room from "../models/Room";
import Plint_Configuration from "../models/Plint_Configuration"
import ApiRequest from "../services/ApiRequest";
import formatPrice from "../misc/functions";
import { room_types } from "../misc/constants"
import { FaCopy, FaTrashAlt, FaPencilAlt } from "react-icons/fa";

export default class RoomContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadError: false,
            loadErrorMessage: [],

            /* Toggle Popup */
            viewPlint: false, // Toggle ViewPlintPopup 
            addPlint: false, // Toggle AddPlintPopup
            editPlint: false, // Toggle EditPlintPopup
            deletePlint: false, // Toggle DeletePlintPopup
            viewRoom: false, // Toggle ViewRoomPopup
            AddRoom: false, // Toggle AddRoomPopup
            editRoom: false, // Toggle EditRoomPopup
            deleteRoom: false, // Toggle DeleteRoomPopup

            /* Form */
            selectedPlint: new Plint_Configuration(), // Current plint data
            selectedRoom: new Room(),
            fields: {}, // Handle onChange of FormElements (Add)
            errors: {}, // Form Errors
            postError: "",

            /* Search + Sort */
            sort: {
                plint_id: "desc",
                min_width: "desc",
                max_width: "desc",
                bottom_dim: "desc",
                top_dim: "desc",
                left_dim: "desc",
                right_dim: "desc",
                base_price: "desc",
                bottom_kpo: "desc",
                top_kpo: "desc",
                left_kpo: "desc",
                right_kpo: "desc",
                room_id: "desc"
            },
            searchValue: "", // Search Value

            backupPlints: [], //Used for filtering
            plints: [],
            rooms: [],
        };
    }

    async componentDidMount() {

        function _getRooms() {
            return ApiRequest.axiosInstance.post("/room/readRooms.php")
        }

        function _getPlintConfigurations() {
            return ApiRequest.axiosInstance.post("/plint/readPlints.php")
        }

        let loadError = false;
        let loadErrorMessage = [];

        Promise.all([_getRooms(), _getPlintConfigurations()])
            .then(results => {
                let dbRooms = results[0];
                if (ApiRequest.callSuccess(dbRooms)) {
                    if (ApiRequest.callHasRecords(dbRooms)) {
                        let rooms = []

                        for (let i = 0, len = dbRooms.data.records.length; i < len; i++) {
                            let room = new Room();
                            room.setDataFromObject(dbRooms.data.records[i])
                            rooms.push(room)
                        }

                        rooms.sort((a, b) => a.room_id > b.room_id ? 1 : -1)

                        this.setState({
                            rooms: rooms,
                        })
                    }
                } else if (ApiRequest.callNoResults(dbRooms)) {
                    this.setState({
                        rooms: [],
                    });
                } else {
                    loadError = true;
                    if (dbRooms.data.records.message !== undefined) {
                        loadErrorMessage.push(dbRooms.data.message);
                    }
                }

                let dbPlintConfigs = results[1]
                if (ApiRequest.callSuccess(dbPlintConfigs)) {
                    if (ApiRequest.callHasRecords(dbPlintConfigs)) {
                        let plints = []

                        for (let i = 0, len = dbPlintConfigs.data.records.length; i < len; i++) {
                            let plint = new Plint_Configuration();
                            plint.setDataFromObject(dbPlintConfigs.data.records[i])
                            plints.push(plint)
                        }

                        plints.sort((a, b) => a.plint_id > b.plint_id ? 1 : -1)

                        this.setState({
                            plints: plints,
                        })
                    }
                } else if (ApiRequest.callNoResults(dbPlintConfigs)) {
                    this.setState({
                        plints: [],
                    });
                } else {
                    loadError = true;
                    if (dbPlintConfigs.data.records.message !== undefined) {
                        loadErrorMessage.push(dbPlintConfigs.data.message);
                    }
                }
            }
            )
            .catch(err => {
                loadError = true;
                if (err.response) {
                    if (err.response.data.message !== undefined) {
                        loadErrorMessage.push(err.response.data.message);
                    }
                }
            })
            .finally(() => {
                console.log("Rooms 'Read' Request Finished");
                setTimeout(() => {
                    this.setState({
                        loading: false,
                        loadError: loadError,
                        loadErrorMessage: loadErrorMessage,
                    });
                }, 500);
            });
    }

    componentWillUnmount() {
        // fix for Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    /* Search & Sort functions */

    // Handle Changes in 'Live Search' of Table (Search Box) + Update Table (Hide Plint configurations that Don't Match Criteria)
    searchChange = (value) => {
        this.setState({ searchValue: value });

        let backupPlints = this.state.backupPlints;

        if (backupPlints.length === 0) {
            backupPlints = [...this.state.plints];
            this.setState({ backupPlints: backupPlints });
        }

        let plints = [];

        for (let i = 0, len = backupPlints.length; i < len; i++) {
            if (backupPlints[i].plint_id.toString().toLowerCase().includes(value.toLowerCase())) {
                plints.push(backupPlints[i]);
            }
        }

        this.setState({ plints: plints });
    };

    // Clear 'Live Search' (Search Box) + Update Table (Display all Plint configurations)
    clearSearch = () => {
        this.setState({ searchValue: "", plints: this.state.backupPlints });
    };

    // Ascending/Descending Sort Plint configurations (onClick 'th')
    sortPlints = (key) => {
        let plints = this.state.plints;
        let sort = this.state.sort;

        if (this.state.sort[key] === "asc") {
            sort[key] = "desc";
            this.setState({ sort });
            plints.sort((a, b) => (a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1));
        } else {
            sort[key] = "asc";
            this.setState({ sort });
            plints.sort((a, b) => (a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1));
        }

        this.setState({ plints });
    };

    sortRooms = (key) => {
        let rooms = this.state.rooms;
        let sort = this.state.sort;

        if (this.state.sort[key] === "asc") {
            sort[key] = "desc";
            this.setState({ sort });
            rooms.sort((a, b) => (a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1));
        } else {
            sort[key] = "asc";
            this.setState({ sort });
            rooms.sort((a, b) => (a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1));
        }

        this.setState({ rooms });
    }

    // Validate form (Called on Submit of Add & Edit) + Create Error Messages if Needed
    _validRoomForm = (e, isEdit = false) => {
        let selectedRoom = this.state.selectedRoom;
        let errors = {};
        let formIsValid = true;

        let formData = new FormData(e.target);

        let room_description = formData.get("room_description");
        let room_type = formData.get("room_type");
        let room_thumbnail = formData.get("room_thumbnail");

        if (room_description !== "") {
            selectedRoom.room_description = room_description;
        } else {
            errors["room_description"] = "Kamer beschrijving is verplicht";
        }

        // No validation on room_type (radio buttons)
        selectedRoom.room_type = +room_type;

        if (room_thumbnail.name !== "") {
            selectedRoom.room_thumbnail_path = room_thumbnail
        } else if (isEdit) {
            selectedRoom.room_thumbnail_path = null;
        } else {
            errors["room_thumbnail"] = "Kamer thumbnail uploaden is verplicht";
        }

        if (Object.keys(errors).length > 0) {
            formIsValid = false;
        }

        this.setState({ errors: errors, selectedRoom: selectedRoom });

        return formIsValid;
    }

    // Validate form (Called on Submit of Add & Edit) + Create Error Messages if Needed
    _validPlintForm = (e, isEdit = false) => {
        let selectedPlint = this.state.selectedPlint;
        let errors = {};
        let formIsValid = true;

        let formData = new FormData(e.target);

        let min_width = formData.get("min_width");
        let max_width = formData.get("max_width");
        let bottom_dim = formData.get("bottom_dim");
        let top_dim = formData.get("top_dim");
        let left_dim = formData.get("left_dim");
        let right_dim = formData.get("right_dim");
        let base_price = formData.get("base_price");
        let bottom_kpo = formData.get("bottom_kpo");
        let top_kpo = formData.get("top_kpo");
        let left_kpo = formData.get("left_kpo");
        let right_kpo = formData.get("right_kpo");
        let room_id = formData.get("room_id");

        if (min_width !== "") {
            min_width = +min_width;
            if (min_width > 0) {
                selectedPlint.min_width = min_width;
            } else {
                errors["min_width"] = "Minimum breedte moet groter dan 0 zijn";
            }
        } else {
            errors["min_width"] = "Minimum breedte is een verplicht veld";
        }

        if (max_width !== "") {
            max_width = +max_width;
            if (max_width > min_width) {
                selectedPlint.max_width = max_width;
            } else {
                errors["max_width"] = "Maximum breedte moet groter zijn dan de minimum breedte";
            }
        } else {
            errors["max_width"] = "Maximum breedte is een verplicht veld";
        }

        if (bottom_dim !== "") {
            bottom_dim = +bottom_dim;
            if (bottom_dim >= 0) {
                selectedPlint.bottom_dim = bottom_dim;
            } else {
                errors["bottom_dim"] = "Onder maat moet groter dan 0 zijn";
            }
        } else {
            errors["bottom_dim"] = "Onder maat is een verplicht veld";
        }

        if (top_dim !== "") {
            top_dim = +top_dim;
            if (top_dim >= 0) {
                selectedPlint.top_dim = top_dim;
            } else {
                errors["top_dim"] = "Boven maat moet groter dan 0 zijn";
            }
        } else {
            errors["top_dim"] = "Boven maat is een verplicht veld";
        }

        if (left_dim !== "") {
            left_dim = +left_dim;
            if (left_dim >= 0) {
                selectedPlint.left_dim = left_dim;
            } else {
                errors["left_dim"] = "Linkermaat moet groter dan 0 zijn";
            }
        } else {
            errors["left_dim"] = "Linkermaat is een verplicht veld";
        }

        if (right_dim !== "") {
            right_dim = +right_dim;
            if (right_dim >= 0) {
                selectedPlint.right_dim = right_dim;
            } else {
                errors["right_dim"] = "Rechtermaat moet groter dan 0 zijn";
            }
        } else {
            errors["right_dim"] = "Rechtermaat is een verplicht veld";
        }

        if (base_price !== "") {
            base_price = +base_price;
            if (base_price >= 0) {
                selectedPlint.base_price = base_price;
            } else {
                errors["base_price"] = "Standaard Prijs moet groter dan 0 zijn";
            }
        } else {
            errors["base_price"] = "Standaard Prijs is een verplicht veld";
        }

        if (bottom_kpo !== "") {
            selectedPlint.bottom_kpo = bottom_kpo;
        } else {
            errors["bottom_kpo"] = "Onderkant KPO bestand is een verplicht veld";
        }

        if (top_kpo !== "") {
            selectedPlint.top_kpo = top_kpo;
        } else {
            errors["top_kpo"] = "Bovenkant KPO bestand is een verplicht veld";
        }

        if (left_kpo !== "") {
            selectedPlint.left_kpo = left_kpo;
        } else {
            errors["left_kpo"] = "Linkerkant KPO bestand is een verplicht veld";
        }

        if (right_kpo !== "") {
            selectedPlint.right_kpo = right_kpo;
        } else {
            errors["right_kpo"] = "Rechterkant KPO bestand is een verplicht veld";
        }


        if (Object.keys(errors).length > 0) {
            formIsValid = false;
        }

        this.setState({ errors: errors, selectedPlint: selectedPlint });

        return formIsValid;

    }

    /* Toggle Popups */

    // Show/Hide 'View Plint configuration'
    toggleViewPlint = (plint) => {
        this.setState({
            viewPlint: !this.state.viewPlint,
            selectedPlint: plint !== undefined ? plint : this.state.selectedPlint,
        });
    };

    // Show/Hide 'Add Plint' Pop-up + Clear all 'Changes' from 'fields' and 'errors'
    toggleAddPlint = () => {
        this.setState({
            selectedPlint: new Plint_Configuration(),
            errors: {},
            addPlint: !this.state.addPlint,
        });
    };

    // Show/Hide 'Edit Plint' Pop-up + Clear all 'Changes' from 'fields' and 'errors'
    toggleEditPlint = (plint) => {
        this.setState({
            selectedPlint: plint !== undefined ? Object.assign(Object.create(Object.getPrototypeOf(plint)), plint) : this.state.selectedPlint,
            errors: {},
            editPlint: !this.state.editPlint,
        });
    };

    // Show/Hide 'Copy Plint' Pop-up + Clear all 'Changes' from 'fields' and 'errors'
    toggleCopyPlint = (plint) => {
        this.setState({
            selectedPlint: plint !== undefined ? Object.assign(Object.create(Object.getPrototypeOf(plint)), plint) : this.state.selectedPlint,
            errors: {},
            copyPlint: !this.state.copyPlint,
        });
    };

    // Show/Hide 'Delete Plint' Pop-up + Set 'fields' (Used for Displaying 'plint' + having the 'id' )
    toggleDeletePlint = (plint) => {
        this.setState({
            selectedPlint: plint !== undefined ? Object.assign(Object.create(Object.getPrototypeOf(plint)), plint) : this.state.selectedPlint,
            deletePlint: !this.state.deletePlint,
        });
    };

    // Show/Hide 'View Room'
    toggleViewRoom = (room) => {
        this.setState({
            viewRoom: !this.state.viewRoom,
            selectedRoom: room !== undefined ? room : this.state.selectedRoom,
        });
    };

    // Show/Hide 'Add Room' Pop-up + Clear all 'Changes' from 'fields' and 'errors'
    toggleAddRoom = () => {
        this.setState({
            selectedRoom: new Room(),
            errors: {},
            addRoom: !this.state.addRoom,
        });
    };

    // Show/Hide 'Edit Room' Pop-up + Clear 'errors' + set 'fields' (Required for Bypassing Validation when Submitting without Changing Anything)
    toggleEditRoom = (room) => {
        this.setState({
            selectedRoom: room !== undefined ? Object.assign(Object.create(Object.getPrototypeOf(room)), room) : this.state.selectedRoom,
            errors: {},
            editRoom: !this.state.editRoom,
        });
    };

    // Show/Hide 'Delete Room' Pop-up + Set 'fields' (Used for Displaying 'room_description' + having the 'id' )
    toggleDeleteRoom = (room) => {
        this.setState({
            selectedRoom: room !== undefined ? room : this.state.selectedRoom,
            deleteRoom: !this.state.deleteRoom,
        });
    };

    /* Update State on Successful HTTP Request */

    // Add room to 'this.state.rooms'
    _addStateRoom = (room) => {
        let rooms = this.state.rooms;
        rooms.push(room);
        this.setState({ rooms });
    };

    // Update 'this.state.rooms' with new values from 'Edit'
    _updateStateRoom = room => {
        let rooms = this.state.rooms;
        for (let i = 0, len = rooms.length; i < len; i++) {
            if (rooms[i].room_id === room.room_id) {
                rooms[i] = room;
                this.setState({ rooms });

                break;
            }
        }
    }

    // Delete module from 'this.state.modules'
    _deleteStateRoom = (id) => {
        let rooms = this.state.rooms;

        for (let i = 0, len = rooms.length; i < len; i++) {
            if (rooms[i].room_id === id) {
                rooms.splice(i, 1);

                this.setState({ rooms });

                break;
            }
        }
    };

    // Add plint to 'this.state.plints'
    _addStatePlint = plint => {
        let plints = this.state.plints;
        plints.push(plint);
        this.setState({ plints })
    }

    // Edit plint in 'this.state.plints'
    _updateStatePlint = plint => {
        let plints = this.state.plints;
        for (let i = 0, len = plints.length; i < len; i++) {
            if (plints[i].plint_id === plint.plint_id) {
                plints[i] = plint;
                this.setState({ plints });

                break;
            }
        }
    }

    // delete plint from 'this.state.plints'
    _deleteStatePlint = id => {
        let plints = this.state.plints;
        for (let i = 0, len = plints.length; i < len; i++) {
            if (plints[i].plint_id === id) {
                plints.splice(i, 1);

                this.setState({ plints });

                break;
            }
        }
    }

    /* Submit Forms (Add, Edit, Delete) */

    submitAddRoom = e => {
        e.preventDefault();

        if (this._validRoomForm(e)) {
            let formData = new FormData(e.target);

            ApiRequest.axiosInstance({
                method: "post",
                url: "/room/createRoom.php",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(res => {
                    console.log(res)
                    if (ApiRequest.callSuccess(res) && ApiRequest.callHasRecords(res)) {
                        let room = this.state.selectedRoom.toJSON("add");

                        let data = res.data.records[0]

                        if (data.hasOwnProperty("ROOM_ID")) {
                            room.room_id = data.ROOM_ID;
                        }

                        room = Object.assign(new Room(), room);

                        this._addStateRoom(room);
                        this.toggleAddRoom();
                    } else {
                        let errors = this.state.errors;
                        errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
                        this.setState({
                            errors: errors,
                        });
                    }
                })
                .catch(err => {
                    console.error(err);
                    let errors = this.state.errors;
                    errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
                    this.setState({
                        errors: errors,
                    });
                })
                .then(() => {
                    console.log("Room 'Add' Request Finished");
                });
        }
    }

    // Handle Submit 'Edit Room' Pop-up
    submitEditRoom = e => {
        e.preventDefault();

        if (this._validRoomForm(e, true)) {
            let formData = new FormData(e.target);
            formData.set("room_id", this.state.selectedRoom.room_id);

            ApiRequest.axiosInstance({
                method: "post",
                url: "/room/updateRoom.php",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(res => {
                    console.log(res)
                    if (ApiRequest.callSuccess(res) && ApiRequest.callHasRecords(res)) {
                        let room = this.state.selectedRoom.toJson("edit");
                        console.log(room)
                        let data = res.data.records[0]

                        room.room_type = room_types.indexOf(room.room_type)
                        if (data.hasOwnProperty("ROOM_ID")) {
                            room.room_id = data.ROOM_ID;
                        }

                        if (data.hasOwnProperty("ROOM_THUMBNAIL_PATH")) {
                            room.room_thumbnail_path = data.ROOM_THUMBNAIL_PATH;
                        }

                        room = Object.assign(new Room(), room);

                        this._updateStateRoom(room);
                        this.toggleEditRoom();
                    } else {
                        let errors = this.state.errors;
                        errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
                        this.setState({
                            errors: errors,
                        });
                    }
                })
                .catch(err => {
                    console.error(err);
                    let errors = this.state.errors;
                    errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
                    this.setState({
                        errors: errors,
                    });
                })
                .then(() => {
                    console.log("Room 'Edit' Request Finished");
                });
        }
    }
    

    // Handle Submit 'Delete Room' Pop-up
    submitDeleteRoom = async (e) => {
        e.preventDefault();

        let room = this.state.selectedRoom;

        let data = room.toJson("delete");

        await ApiRequest.axiosInstance
            .post("/room/deleteRoom.php", data)
            .then((res) => {
                if (ApiRequest.callSuccess(res)) {
                    this._deleteStateRoom(data.room_id);
                    this.toggleDeleteRoom();
                } else {
                    let errors = this.state.errors;
                    errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
                    this.setState({
                        errors: errors,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                let errors = this.state.errors;
                errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
                this.setState({
                    errors: errors,
                });
            })
            .then(() => {
                console.log("Room 'Delete' Request Finished");
            });
    };

    submitAddPlint = e => {
        e.preventDefault();

        if (this._validPlintForm(e)) {
            let formData = new FormData(e.target);

            ApiRequest.axiosInstance({
                method: "post",
                url: "/plint/createPlint.php",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(res => {
                    console.log(res)
                    if (ApiRequest.callSuccess(res) && ApiRequest.callHasRecords(res)) {

                        let plint = this.state.selectedPlint.toJson("add");

                        let data = res.data.records[0]

                        if (data.hasOwnProperty("PLINT_CONFIGURATION_ID")) {
                            plint.plint_id = data.PLINT_CONFIGURATION_ID;
                        }

                        plint = Object.assign(new Plint_Configuration, plint);

                        this._addStatePlint(plint);
                        this.toggleAddPlint();
                    } else {
                        let errors = this.state.errors;
                        errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
                        this.setState({
                            errors: errors,
                        });
                    }
                })
                .catch(err => {
                    console.error(err);
                    let errors = this.state.errors;
                    errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
                    this.setState({
                        errors: errors,
                    });
                })
                .then(() => {
                    console.log("Plint 'Add' Request Finished");
                });
        }
    }

    submitEditPlint = e => {
        e.preventDefault();

        if (this._validPlintForm(e)) {
            let formData = new FormData(e.target);
            formData.set("plint_id", this.state.selectedPlint.plint_id);
            ApiRequest.axiosInstance({
                method: "post",
                url: "/plint/updatePlint.php",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(res => {
                    console.log(res);
                    if (ApiRequest.callSuccess(res) && ApiRequest.callHasRecords(res)) {
                        let plint = this.state.selectedPlint.toJson("edit");
                        // let data = res.data.records[0];

                        plint = Object.assign(new Plint_Configuration, plint);

                        this._updateStatePlint(plint);
                        this.toggleEditPlint();
                    }
                })
                .catch(err => {
                    console.error(err);
                    let errors = this.state.errors;
                    errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
                    this.setState({
                        errors: errors,
                    });
                })
                .then(() => {
                    console.log("Plint 'Edit' Request Finished");
                });
        }
    }

    submitCopyPlint = e => {
        e.preventDefault();

        if (this._validPlintForm(e)) {
            let formData = new FormData(e.target);

            ApiRequest.axiosInstance({
                method: "post",
                url: "/plint/createPlint.php",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(res => {
                    console.log(res);
                    if (ApiRequest.callSuccess(res) && ApiRequest.callHasRecords(res)) {
                        let plint = this.state.selectedPlint.toJson("add");

                        let data = res.data.records[0]

                        if (data.hasOwnProperty("PLINT_CONFIGURATION_ID")) {
                            plint.plint_id = data.PLINT_CONFIGURATION_ID;
                        }

                        plint = Object.assign(new Plint_Configuration, plint);

                        this._addStatePlint(plint);
                        this.toggleCopyPlint();
                    }
                })
                .catch(err => {
                    console.error(err);
                    let errors = this.state.errors;
                    errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
                    this.setState({
                        errors: errors,
                    });
                })
                .then(() => {
                    console.log("Plint 'Copy' Request Finished");
                });
        }
    }

    // Handle Submit 'Delete Room' Pop-up
    submitDeletePlint = async (e) => {
        e.preventDefault();

        let plint = this.state.selectedPlint;

        let data = plint.toJson("delete");

        await ApiRequest.axiosInstance
            .post("/plint/deletePlint.php", data)
            .then((res) => {
                if (ApiRequest.callSuccess(res)) {
                    this._deleteStatePlint(data.plint_id);
                    this.toggleDeletePlint();
                } else {
                    let errors = this.state.errors;
                    errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
                    this.setState({
                        errors: errors,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                let errors = this.state.errors;
                errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
                this.setState({
                    errors: errors,
                });
            })
            .then(() => {
                console.log("Plint 'Delete' Request Finished");
            });
    };

    /* Build Table */

    // Fill Table with Data from 'this.state.plints'
    populatePlintTable = () => {
        let plints = this.state.plints;

        let rows = [];

        for (let i = 0, len = plints.length; i < len; i++) {
            let children = [];

            children.push(<td key={i + "-id"}>{plints[i].plint_id}</td>);
            children.push(<td key={i + "-min-width"}>{plints[i].min_width}</td>);
            children.push(<td key={i + "-max-width"}>{plints[i].max_width}</td>);
            children.push(<td key={i + "-bottom-dim"}>{plints[i].bottom_dim}</td>);
            children.push(<td key={i + "-top-dim"}>{plints[i].top_dim}</td>);
            children.push(<td key={i + "-left-dim"}>{plints[i].left_dim}</td>);
            children.push(<td key={i + "-right-dim"}>{plints[i].right_dim}</td>);
            children.push(<td key={i + "-base-price"}>€ {formatPrice(+plints[i].base_price)}</td>);
            children.push(<td key={i + "-bottom-kpo"}>{plints[i].bottom_kpo}</td>);
            children.push(<td key={i + "-top-kpo"}>{plints[i].top_kpo}</td>);
            children.push(<td key={i + "-left-kpo"}>{plints[i].left_kpo}</td>);
            children.push(<td key={i + "-right-kpo"}>{plints[i].right_kpo}</td>);
            children.push(<td key={i + "-room-id"}>{plints[i].room_id}</td>);

            children.push(
                <td key={i + "-edit"} className='td-icon' onClick={() => this.toggleEditPlint(plints[i])}>
                    <FaPencilAlt />
                </td>
            );

            children.push(
                <td key={i + "-copy"} className='td-icon' onClick={() => this.toggleCopyPlint(plints[i])}>
                    <FaCopy />
                </td>
            );

            children.push(
                <td key={i + "-delete"} className='td-icon' onClick={() => this.toggleDeletePlint(plints[i])}>
                    <FaTrashAlt />
                </td>
            );

            rows.push(
                <tr key={i} id={i}>
                    {children}
                </tr>
            );
        }

        return rows;
    };

    // Fill Table with Data from 'this.state.rooms'
    populateRoomTable = () => {
        let rooms = this.state.rooms;

        let rows = [];

        for (let i = 0, len = rooms.length; i < len; i++) {
            let children = [];

            children.push(<td key={i + "-id"}>{rooms[i].room_id}</td>);
            children.push(<td key={i + "-room-description"}>{rooms[i].room_description}</td>);
            children.push(<td key={i + "-room-type"}>{rooms[i].room_type}</td>);
            children.push(<td key={i + "-room-thumbnail_path"}>{rooms[i].room_thumbnail_path}</td>);
            children.push(
                <td key={i + "-view"} className='td-icon' onClick={() => this.toggleViewRoom(rooms[i])}>
                    <span className='view-order-span'>Bekijk</span>
                </td>
            );

            children.push(
                <td key={i + "-edit"} className='td-icon' onClick={() => this.toggleEditRoom(rooms[i])}>
                    <FaPencilAlt />
                </td>
            );

            children.push(
                <td key={i + "-delete"} className='td-icon' onClick={() => this.toggleDeleteRoom(rooms[i])}>
                    <FaTrashAlt />
                </td>
            );

            rows.push(
                <tr key={i} id={i}>
                    {children}
                </tr>
            );
        }

        return rows;
    };

    render() {

        return (
            <div>
                <SideNavContainer />
                <Rooms
                    state={this.state}
                    toggleViewRoom={this.toggleViewRoom}
                    toggleAddRoom={this.toggleAddRoom}
                    toggleEditRoom={this.toggleEditRoom}
                    toggleDeleteRoom={this.toggleDeleteRoom}

                    toggleAddPlint={this.toggleAddPlint}
                    toggleEditPlint={this.toggleEditPlint}
                    toggleCopyPlint={this.toggleCopyPlint}
                    toggleDeletePlint={this.toggleDeletePlint}

                    submitAddRoom={this.submitAddRoom}
                    submitEditRoom={this.submitEditRoom}
                    submitDeleteRoom={this.submitDeleteRoom}

                    submitAddPlint={this.submitAddPlint}
                    submitEditPlint={this.submitEditPlint}
                    submitCopyPlint={this.submitCopyPlint}
                    submitDeletePlint={this.submitDeletePlint}

                    searchChange={this.searchChange}
                    clearSearch={this.clearSearch}
                    populateRoomTable={this.populateRoomTable}
                    populatePlintTable={this.populatePlintTable}
                    sortRooms={this.sortRooms}
                    sortPlints={this.sortPlints}
                />
            </div>
        )
    }

}