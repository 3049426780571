import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { BsQuestionCircleFill } from "react-icons/bs";

function FeatureNameTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Naam voor de feature</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function FeatureTypeTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Type van de feature</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function FeatureModelFileTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Model bestand voor de feature (.GLFT, .GLB)</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function FeatureThumbnailFileTooltip() {
  return (
    <OverlayTrigger
      placement='right'
      overlay={
        <Tooltip>
          Thumbnail voor de feature
          <br />
          (.jpg, .jpeg, .png,...)
        </Tooltip>
      }>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function FeaturePriceTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Prijs voor de feature</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

export { FeatureNameTooltip, FeatureTypeTooltip, FeatureModelFileTooltip, FeatureThumbnailFileTooltip, FeaturePriceTooltip };
