import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/table";

import Loading from "./Loading";
import Error from "./Error";

import { FaPlus, FaTimesCircle, FaCaretDown, FaCaretUp } from "react-icons/fa";

import AddFeaturesModal from "./popups/features/AddFeatureModal";
import EditFeaturesModal from "./popups/features/EditFeatureModal";
import DeleteFeaturesModal from "./popups/features/DeleteFeatureModal";

class Features extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='main-container'>
        {this.props.state.loading ? (
          <div className='loader'>
            <Loading />
          </div>
        ) : this.props.state.loadError ? (
          <Error message={this.props.state.loadErrorMessage} />
        ) : (
          <div>
            <h1>Features</h1>
            <hr />
            <AddFeaturesModal addFeature={this.props.state.addFeature} toggleAddFeature={this.props.toggleAddFeature} submitAddFeature={this.props.submitAddFeature} featureTypes={this.props.state.featureTypes} formErrors={this.props.state.errors} />

            <EditFeaturesModal
              editFeature={this.props.state.editFeature}
              toggleEditFeature={this.props.toggleEditFeature}
              submitEditFeature={this.props.submitEditFeature}
              selectedFeature={this.props.state.selectedFeature}
              featureTypes={this.props.state.featureTypes}
              formErrors={this.props.state.errors}
            />

            <DeleteFeaturesModal deleteFeature={this.props.state.deleteFeature} toggleDeleteFeature={this.props.toggleDeleteFeature} submitDeleteFeature={this.props.submitDeleteFeature} selectedFeature={this.props.state.selectedFeature} formErrors={this.props.state.errors} />

            <Row>
              <Col lg={{ span: 2, offset: 0 }} className='search-outer-container'>
                <div className='search-container'>
                  <Form.Control type='text' placeholder='Zoeken op feature...' value={this.props.state.searchValue} onChange={(e) => this.props.searchChange(e.target.value)} />
                  <span className={this.props.state.searchValue !== "" ? "search-clear" : "hide"} onClick={this.props.clearSearch}>
                    <FaTimesCircle />
                  </span>
                </div>
              </Col>

              <Col lg={{ span: 1, offset: 9 }} className='add-btn-container'>
                <Button variant='primary' onClick={this.props.toggleAddFeature}>
                  <FaPlus /> Nieuwe Feature
                </Button>
              </Col>
            </Row>
            <br />
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    <span
                      onClick={() => {
                        this.props.sortFeatures("feature_name");
                      }}>
                      Feature
                      {this.props.state.sort.feature_name === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span
                      onClick={() => {
                        this.props.sortFeatures("feature_type_name");
                      }}>
                      Type
                      {this.props.state.sort.feature_type_name === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span
                      onClick={() => {
                        this.props.sortFeatures("feature_price");
                      }}>
                      Prijs
                      {this.props.state.sort.feature_price === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th className='th-icon'>Aanpassen</th>
                  <th className='th-icon'>Verwijderen</th>
                </tr>
              </thead>
              <tbody>{this.props.populateTable()}</tbody>
            </Table>
          </div>
        )}
      </div>
    );
  }
}

export default Features;
