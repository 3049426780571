import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { BsQuestionCircleFill } from "react-icons/bs";

export function RoomDescriptionTooltip() {
    return (
        <OverlayTrigger placement='right' overlay={<Tooltip>Kamer Beschrijving, kenmerken en andere informatie (max 50 karakters)</Tooltip>}>
            <BsQuestionCircleFill />
        </OverlayTrigger>
    );
}

export function RoomTypeTooltip() {
    return (
        <OverlayTrigger placement='right' overlay={<Tooltip>De manier waarop de kast in de kamer geplaatst wordt</Tooltip>}>
            <BsQuestionCircleFill />
        </OverlayTrigger>
    );
}

export function RoomThumbnailTooltip() {
    return (
        <OverlayTrigger placement='right' overlay={<Tooltip>Thumbnail van het kamer sjabloon</Tooltip>}>
            <BsQuestionCircleFill />
        </OverlayTrigger>
    );
}