import React from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import { PlintMinWidthTooltip, PlintMaxWidthTooltip, PlintBottomDimTooltip, PlintTopDimTooltip, PlintLeftDimTooltip, PlintRightDimTooltip, PlintBasePriceTooltip, PlintKorpusFileTooltip, PlintRoomTooltip} from "./../../tooltips/PlintTooltips";
class CopyPlintModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    populateDropDown = () => {
        let dropdownItems = [];

        let rooms = this.props.rooms;
        for (let i = 0, len = rooms.length; i < len; i++) {
            dropdownItems.push(
                <option key={i} value={rooms[i].room_id}>
                    {rooms[i].room_description}
                </option>
            );
        }

        return dropdownItems;
    };

    render() {
        return (
            <Modal show={this.props.copyPlint} onHide={this.props.toggleCopyPlint}>
                <Modal.Header closeButton>
                    <Modal.Title>Plint Configuratie Aanpassen</Modal.Title>
                </Modal.Header>
                <Form method='POST' encType='multipart/form-data' onSubmit={this.props.submitCopyPlint}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>
                                Minimum Breedte <PlintMinWidthTooltip />
                            </Form.Label>
                            <Form.Control type='text' placeholder='Minimum Breedte' name='min_width' defaultValue={this.props.selectedPlint.min_width} />
                            <span className={this.props.formErrors["min_width"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["min_width"]}</span>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Maximum Breedte <PlintMaxWidthTooltip />
                            </Form.Label>
                            <Form.Control type='text' placeholder='Maximum Breedte' name='max_width' defaultValue={this.props.selectedPlint.max_width} />
                            <span className={this.props.formErrors["max_width"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["max_width"]}</span>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Onder Maat <PlintBottomDimTooltip />
                            </Form.Label>
                            <Form.Control type='text' placeholder='Onder Maat' name='bottom_dim' defaultValue={this.props.selectedPlint.bottom_dim} />
                            <span className={this.props.formErrors["bottom_dim"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["bottom_dim"]}</span>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Boven Maat <PlintTopDimTooltip />
                            </Form.Label>
                            <Form.Control type='text' placeholder='Boven Maat' name='top_dim' defaultValue={this.props.selectedPlint.top_dim} />
                            <span className={this.props.formErrors["top_dim"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["top_dim"]}</span>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Linkermaat <PlintLeftDimTooltip />
                            </Form.Label>
                            <Form.Control type='text' placeholder='Linkermaat' name='left_dim' defaultValue={this.props.selectedPlint.left_dim} />
                            <span className={this.props.formErrors["left_dim"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["left_dim"]}</span>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Rechtermaat <PlintRightDimTooltip />
                            </Form.Label>
                            <Form.Control type='text' placeholder='Rechtermaat' name='right_dim' defaultValue={this.props.selectedPlint.right_dim} />
                            <span className={this.props.formErrors["right_dim"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["right_dim"]}</span>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Standaard Prijs <PlintBasePriceTooltip />
                            </Form.Label>
                            <Form.Control type='number' step='0.01' placeholder='Standaard Prijs' name='base_price' defaultValue={this.props.selectedPlint.base_price} />
                            <span className={this.props.formErrors["base_price"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["base_price"]}</span>
                        </Form.Group>


                        <Form.Group>
                            <Form.Label>
                                Onderkant KPO Bestand <PlintKorpusFileTooltip />
                            </Form.Label>
                            <Form.Control type='text' placeholder='Onderkant KPO Bestand' name='bottom_kpo' defaultValue={this.props.selectedPlint.bottom_kpo} />
                            <span className={this.props.formErrors["bottom_kpo"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["bottom_kpo"]}</span>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Bovenkant KPO Bestand <PlintKorpusFileTooltip />
                            </Form.Label>
                            <Form.Control type='text' placeholder='Bovenkant KPO Bestand' name='top_kpo' defaultValue={this.props.selectedPlint.top_kpo} />
                            <span className={this.props.formErrors["top_kpo"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["top_kpo"]}</span>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Linkerkant KPO Bestand <PlintKorpusFileTooltip />
                            </Form.Label>
                            <Form.Control type='text' placeholder='Linkerkant KPO Bestand' name='left_kpo' defaultValue={this.props.selectedPlint.left_kpo} />
                            <span className={this.props.formErrors["left_kpo"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["left_kpo"]}</span>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Rechterkant KPO Bestand <PlintKorpusFileTooltip />
                            </Form.Label>
                            <Form.Control type='text' placeholder='Rechterkant KPO Bestand' name='right_kpo' defaultValue={this.props.selectedPlint.right_kpo} />
                            <span className={this.props.formErrors["right_kpo"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["right_kpo"]}</span>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Kamer ID <PlintRoomTooltip />
                            </Form.Label>
                            <Form.Control as='select' name='room_id' defaultValue={this.props.selectedPlint.room_id} className='custom-select'>
                                {this.populateDropDown()}
                            </Form.Control>
                            <span className={this.props.formErrors["room_id"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["room_id"]}</span>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        {this.props.formErrors["submitError"] ? <Alert variant='danger'>{this.props.formErrors["submitError"]}</Alert> : null}
                        <Button variant='primary' type='submit'>
                            Toevoegen
            </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

export default CopyPlintModal;
