import React from "react";

import { Link } from "react-router-dom";

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.history.pushState("", "", "/404");
  }

  render() {
    return (
      <div className='not-found-container center-screen noselect'>
        <h1>404</h1>
        <p>Pagina niet gevonden</p>
        <Link to='/'>KorpusWeb Dashboard</Link>
      </div>
    );
  }
}

export default NotFound;
