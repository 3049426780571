import React from "react";
import formatPrice from "../misc/functions";
import Dashboard from "../components/Dashboard";
import SideNavContainer from "../components/SideNavContainer";
import Setting from "../models/Setting";

import ApiRequest from "../services/ApiRequest";

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true, //used for displaying loading item as long as not every item is fetched
      loadError: false,
      loadErrorMessage: [],
      materialCount: 0,
      moduleCount: 0,
      featureCount: 0,
      customerCount: 0,
      orderCount: 0,
      total_income: 0,
      selectedYear: null,
      yAxesData: "aantal", //used for choosing which data is displayed on yAxes of Graph
      orderData: [],
      settings: [],
    };
  }

  // On mount, get data from database (count of materials, modules, features, customers & orders + all orders)
  async componentDidMount() {
    function _getMaterialCount() {
      return ApiRequest.axiosInstance("/material/readMaterialCount.php");
    }

    function _getModuleCount() {
      return ApiRequest.axiosInstance("/module/readModuleCount.php");
    }

    function _getFeatureCount() {
      return ApiRequest.axiosInstance("/feature/readFeatureCount.php");
    }

    function _getCustomerCount() {
      return ApiRequest.axiosInstance("/customer/readCustomerCount.php");
    }

    function _getOrderCount() {
      return ApiRequest.axiosInstance("/order/readOrderCount.php");
    }

    function _getOrders() {
      return ApiRequest.axiosInstance("/order/readAllOrders.php");
    }

    function _getSettings() {
      return ApiRequest.axiosInstance.post("/settings/readSetting.php");
    }

    let total_income = "0,00";
    let orderData = [];

    let loadError = false;
    let loadErrorMessage = [];

    await Promise.all([_getMaterialCount(), _getModuleCount(), _getFeatureCount(), _getCustomerCount(), _getOrderCount(), _getOrders(), _getSettings()])
      .then((results) => {
        let materialCount = results[0];
        if (ApiRequest.callSuccess(materialCount) && ApiRequest.callHasRecords(materialCount)) {
          this.setState({
            materialCount: materialCount.data.records[0].material_count,
          });
        } else if (ApiRequest.callNoResults(materialCount)) {
          this.setState({
            materialCount: 0,
          });
        } else {
          if (materialCount.data.message !== undefined) {
            loadErrorMessage.push(materialCount.data.message);
          }
          loadError = true;
        }

        let moduleCount = results[1];
        if (ApiRequest.callSuccess(moduleCount) && ApiRequest.callHasRecords(moduleCount)) {
          this.setState({
            moduleCount: moduleCount.data.records[0].module_count,
          });
        } else if (ApiRequest.callNoResults(moduleCount)) {
          this.setState({
            moduleCount: 0,
          });
        } else {
          if (moduleCount.data.message !== undefined) {
            loadErrorMessage.push(moduleCount.data.message);
          }
          loadError = true;
        }

        let featureCount = results[2];
        if (ApiRequest.callSuccess(featureCount) && ApiRequest.callHasRecords(featureCount)) {
          this.setState({
            featureCount: featureCount.data.records[0].feature_count,
          });
        } else if (ApiRequest.callNoResults(featureCount)) {
          this.setState({
            featureCount: 0,
          });
        } else {
          if (featureCount.data.message !== undefined) {
            loadErrorMessage.push(featureCount.data.message);
          }
          loadError = true;
        }

        let customerCount = results[3];
        if (ApiRequest.callSuccess(customerCount) && ApiRequest.callHasRecords(customerCount)) {
          this.setState({
            customerCount: customerCount.data.records[0].customer_count,
          });
        } else if (ApiRequest.callNoResults(customerCount)) {
          this.setState({
            customerCount: 0,
          });
        } else {
          if (customerCount.data.message !== undefined) {
            loadErrorMessage.push(customerCount.data.message);
          }
          loadError = true;
        }

        let orderCount = results[4];
        if (ApiRequest.callSuccess(orderCount) && ApiRequest.callHasRecords(orderCount)) {
          this.setState({
            orderCount: orderCount.data.records[0].order_count,
          });
        } else if (ApiRequest.callNoResults(orderCount)) {
          this.setState({
            orderCount: 0,
          });
        } else {
          if (orderCount.data.message !== undefined) {
            loadErrorMessage.push(orderCount.data.message);
          }
          loadError = true;
        }

        let orders = results[5];
        if (ApiRequest.callSuccess(orders)) {
          if (ApiRequest.callHasRecords(orders)) {
            orderData = this._parseOrderData(orders.data.records);
            total_income = this._getTotalIncomeFromOrderData(orderData);

            this.setState({
              total_income: total_income,
              orderData: orderData,
            });
          }
        } else if (ApiRequest.callNoResults(orders)) {
          this.setState({
            orders: [],
          });
        } else {
          if (orders.data.message !== undefined) {
            loadErrorMessage.push(orders.data.message);
          }
          loadError = true;
        }

        let settings = results[6];
        if(ApiRequest.callSuccess(settings)) {
          if(ApiRequest.callHasRecords(settings)) {
            let setting = new Setting();

            console.log("SETTINGS FROM API");
            console.log(settings);
            
            setting.setDataFromObject(settings.data.records[0]); // pak 1e value, we gaan er toch maar 1 weergeven + er zou maar 1 mogen inzitten
            this.setState({
              settings: setting,
            });
          }
        }else if (ApiRequest.callNoResults(settings)) {
          this.setState({
            settings: [],
          });
        } else {
          if (settings.data.message !== undefined) {
            loadErrorMessage.push(settings.data.message);
          }
          loadError = true;
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.message !== undefined) {
            loadErrorMessage.push(err.response.data.message);
          }
        }
        loadError = true;
      })
      .finally(() => {
        console.log("Dashboard 'Read' Request Finished");

        setTimeout(() => {
          //Add extra time, for 2 reasons: 1. if really fast it otherwise just blinks loading screen. 2. Slower pc's might take longer to update the DOM
          this.setState({
            loading: false,
            loadError: loadError,
            loadErrorMessage: loadErrorMessage,
          });
        }, 500);
      });
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  // Parse data into orderData
  _parseOrderData = (data) => {
    let orderData = [];

    if (data !== undefined) {
      for (let i = 0, len = data.length; i < len; i++) {
        const date = new Date(data[i].order_date);

        orderData.push({
          date: {
            month: date.getMonth(),
            year: date.getFullYear(),
          },
          total_price: +data[i].order_total_price,
        });
      }
    }

    return orderData;
  };

  // Calculate total income using the formData
  _getTotalIncomeFromOrderData = (orderData) => {
    if (orderData.length > 0) {
      let total = 0;

      for (let i = 0, len = orderData.length; i < len; i++) {
        total += +orderData[i].total_price;
      }

      total = formatPrice(total);

      return total;
    }

    return "0,00";
  };

  // Year Changed in Dropdown (above graph)
  handleYearChange = (e) => {
    let value = e.target.value;

    this.setState({
      selectedYear: value,
    });
  };

  // Y axis Changed in dropdown
  handleGraphYChange = (e) => {
    let value = e.target.value;

    this.setState({
      yAxesData: value,
    });
  };

  // Dropdown 'Year' programmatic build
  buildYearDropdown() {
    let orders = this.state.orderData;

    // Get all Unique Values
    let flags = [];
    let unique = [];
    for (let i = 0, len = orders.length; i < len; i++) {
      if (flags[orders[i].date.year]) continue;
      flags[orders[i].date.year] = true;
      unique.push(orders[i].date.year);
    }

    unique.sort().reverse(); //Sort in desceding order (newest year first in  the list)

    //Build Select
    let options = [];
    for (let i = 0, len = unique.length; i < len; i++) {
      options.push(
        <option key={i} value={unique[i]}>
          {unique[i]}
        </option>
      );
    }

    // This will not impact the use of the site, as the only time this will be called is when the selectbox is disabled
    if (options.length === 0) {
      options.push(
        <option key={0} value={new Date().getFullYear()}>
          {new Date().getFullYear()}
        </option>
      );
    }

    return options;
  }

  render() {
    return (
      <div>
        <SideNavContainer />
        <Dashboard state={this.state} handleYearChange={this.handleYearChange} handleGraphYChange={this.handleGraphYChange} buildYearDropdown={this.buildYearDropdown} />
      </div>
    );
  }
}

export default DashboardContainer;
