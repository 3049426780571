import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { BsQuestionCircleFill } from "react-icons/bs";

export function PlintMinWidthTooltip() {
    return (
        <OverlayTrigger placement='right' overlay={<Tooltip>Minimum Kamerbreedte</Tooltip>}>
            <BsQuestionCircleFill />
        </OverlayTrigger>
    );
}

export function PlintMaxWidthTooltip() {
    return (
        <OverlayTrigger placement='right' overlay={<Tooltip>Maximum Kamerbreedte</Tooltip>}>
            <BsQuestionCircleFill />
        </OverlayTrigger>
    );
}

export function PlintBottomDimTooltip() {
    return (
        <OverlayTrigger placement='right' overlay={<Tooltip>Standaard waarde voor de hoogte van de onderste plint</Tooltip>}>
            <BsQuestionCircleFill />
        </OverlayTrigger>
    );
}

export function PlintTopDimTooltip() {
    return (
        <OverlayTrigger placement='right' overlay={<Tooltip>Standaard waarde voor de hoogte van de bovenste plint</Tooltip>}>
            <BsQuestionCircleFill />
        </OverlayTrigger>
    );
}

export function PlintLeftDimTooltip() {
    return (
        <OverlayTrigger placement='right' overlay={<Tooltip>Standaard waarde voor de breedte van de afwerking aan de linkerkant</Tooltip>}>
            <BsQuestionCircleFill />
        </OverlayTrigger>
    );
}

export function PlintRightDimTooltip() {
    return (
        <OverlayTrigger placement='right' overlay={<Tooltip>Standaard waarde voor de breedte van de afwerking aan de rechterkant</Tooltip>}>
            <BsQuestionCircleFill />
        </OverlayTrigger>
    );
}

export function PlintBasePriceTooltip() {
    return (
        <OverlayTrigger placement='right' overlay={<Tooltip>Standaard prijs voor deze plint configuratie</Tooltip>}>
            <BsQuestionCircleFill />
        </OverlayTrigger>
    );
}

export function PlintKorpusFileTooltip() {
    return (
        <OverlayTrigger placement='right' overlay={<Tooltip>Locatie van het korpus bestand. bv: 'C:\Users\Gebruiker\Desktop\bestand.kpo</Tooltip>}>
            <BsQuestionCircleFill />
        </OverlayTrigger>
    );
}

export function PlintRoomTooltip() {
    return (
        <OverlayTrigger placement='right' overlay={<Tooltip>Het kamer type waarvoor deze Plint Configuratie gebruikt mag worden</Tooltip>}>
            <BsQuestionCircleFill />
        </OverlayTrigger>
    );
}
