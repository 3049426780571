import React from "react";

import Select from 'react-select'

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import DeleteCountryModal from "../components/popups/settings/DeleteCountryModal";

import { FaPlus, FaCaretDown, FaCaretUp } from "react-icons/fa";

import { SettingsAPIKeyTooltip, SettingsLogoTooltip, SettingsPrimaryColorTooltip, SettingsControlTextColorTooltip, SettingsBackgroundColorTooltip, SettingsSecondaryColorTooltip, SettingsContactTooltip, SettingsCountriesTooltip, SettingsKPSTooltip, SettingsModuleKPOTooltip, SettingsFeatureKPOTooltip } from "./tooltips/SettingsTooltips";
import Loading from "./Loading";
import Error from "./Error";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fontOptions = [
      { label: 'Arial', value: 'arial' },
      { label: 'Verdana', value: 'verdana' },
      { label: 'Helvetica', value: 'helvetica' },
      { label: 'Tahoma', value: 'tahoma' },
      { label: 'Trebuchet MS', value: 'trebuchet ms' },
      { label: 'Times New Roman', value: 'timer new roman' },
      { label: 'Georgia', value: 'georgia' },
      { label: 'Garamond', value: 'garamond' },
      { label: 'Courier New', value: 'courier new' },
      { label: 'Brush Script MT', value: 'brush script mt' },
    ].sort();
    this.customStyleSelect = {
      control: base => ({
        ...base,
        border: '1px solid #ced4da',
        borderRadius: '0px',
        // This line disable the blue border
        boxShadow: 'none'
      }),
    }
  }

  render() {
    console.log(this.props.state)
    return (
      <div className='main-container'>
        {this.props.state.loading ? (
          <div className='loader'>
            <Loading />
          </div>
        ) : this.props.state.loadError ? (
          <Error message={this.props.state.loadErrorMessage} />
        ) : (
          <div>
            <DeleteCountryModal toggleDeleteCountry={this.props.toggleDeleteCountry} deleteCountry={this.props.state.deleteCountry} handleDeleteCountry={this.props.handleDeleteCountry} selectedCountry={this.props.state.selectedCountry} />

            <h1>Instellingen</h1>
            <hr />
            <div className='settings-container'>
              <div className='success'>{this.props.state.success}</div>
              <Form onSubmit={(e) => this.props.handleSubmitSettings(e)}>
                {/* Logo */}
                <Row>
                  <Col>
                    Mollie API Key <SettingsAPIKeyTooltip />
                  </Col>
                  <Col>
                    <Form.Control type='text' name='settings_mollie_api_key' id='settings_mollie_api_key' placeholder="" defaultValue={this.props.state.settings.settings_mollie_api_key} />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    Logo uploaden <SettingsLogoTooltip />
                  </Col>
                  <Col>
                    <Form.Control type='file' name='settings_logo' id='settings_logo' onChange={(e) => this.props.handleLogoUpload(e)} />
                    <Form.Label htmlFor='settings_logo'>{this.props.state.settings_logo}</Form.Label>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    Kamer .KPS pad <SettingsKPSTooltip />
                  </Col>
                  <Col>
                    <Form.Control type='text' name='settings_room_kps_path' id='settings_room_kps_path' placeholder="C:\Vlecad\KorpusWeb" defaultValue={this.props.state.settings.settings_room_kps_path} />
                    {/* <div className={this.props.state.errors["settings_room_kps_path"] !== undefined ? "form-error" : "hide"}>{this.props.state.errors["settings_room_kps_path"]}</div> */}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    Module .KPO pad <SettingsModuleKPOTooltip />
                  </Col>
                  <Col>
                    <Form.Control type='text' name='settings_module_kpo_path' id='settings_module_kpo_path' placeholder="C:\Vlecad\KorpusWeb" defaultValue={this.props.state.settings.settings_module_kpo_path} />
                    {/* <div className={this.props.state.errors["settings_room_kps_path"] !== undefined ? "form-error" : "hide"}>{this.props.state.errors["settings_room_kps_path"]}</div> */}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    Feature .KPO pad <SettingsFeatureKPOTooltip />
                  </Col>
                  <Col>
                    <Form.Control type='text' name='settings_feature_kpo_path' id='settings_feature_kpo_path' placeholder="C:\Vlecad\KorpusWeb" defaultValue={this.props.state.settings.settings_feature_kpo_path} />
                    {/* <div className={this.props.state.errors["settings_room_kps_path"] !== undefined ? "form-error" : "hide"}>{this.props.state.errors["settings_room_kps_path"]}</div> */}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    Font (knoppen)
                  </Col>
                  <Col>
                    <Select options={this.fontOptions} styles={this.customStyleSelect} name="$font-family-controls" defaultValue={this.fontOptions.filter(option => option.value === this.props.state.settings.style['$font-family-controls'])} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Font (algemeen)
                  </Col>
                  <Col>
                    <Select options={this.fontOptions} styles={this.customStyleSelect} name="$font-family" defaultValue={this.fontOptions.filter(option => option.value === this.props.state.settings.style['$font-family'])} />
                  </Col>
                </Row>

                {/* Theme */}
                <Row>
                  <Col>
                    Thema kleur (primary) <SettingsPrimaryColorTooltip />
                  </Col>
                  <Col>
                    <Form.Control type='color' name='$color-primary' defaultValue={this.props.state.settings.style['$color-primary']} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Thema kleur (secondary) <SettingsSecondaryColorTooltip />
                  </Col>
                  <Col>
                    <Form.Control type='color' name='$color-secondary' defaultValue={this.props.state.settings.style['$color-secondary']} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Achtergrondkleur (pagina) <SettingsBackgroundColorTooltip />
                  </Col>
                  <Col>
                    <Form.Control type='color' name='$color-background' defaultValue={this.props.state.settings.style['$color-background']} />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    Tekst kleur (knoppen) <SettingsControlTextColorTooltip />
                  </Col>
                  <Col>
                    <Form.Control type='color' name='$font-color-controls' defaultValue={this.props.state.settings.style['$font-color-controls']} />
                  </Col>
                </Row>


                {/* Contact */}
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        Contact <SettingsContactTooltip />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control type='text' name='settings_contact_name' placeholder='Naam' defaultValue={this.props.state.settings.settings_contact_name} />
                        <div className={this.props.state.errors["settings_contact_name"] !== undefined ? "form-error" : "hide"}>{this.props.state.errors["settings_contact_name"]}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control type='email' name='settings_contact_email' placeholder='Email' defaultValue={this.props.state.settings.settings_contact_email} />
                        <div className={this.props.state.errors["settings_contact_email"] !== undefined ? "form-error" : "hide"}>{this.props.state.errors["settings_contact_email"]}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control type='text' name='settings_contact_phone' placeholder='Telefoon' defaultValue={this.props.state.settings.settings_contact_phone} />
                        <div className={this.props.state.errors["settings_contact_phone"] !== undefined ? "form-error" : "hide"}>{this.props.state.errors["settings_contact_phone"]}</div>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Row>
                      <Col>
                        Leveringslanden <SettingsCountriesTooltip />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control type='text' placeholder='Land' id='country_name' />
                        <div className='form-error'>{this.props.state.errors["country"]}</div>
                      </Col>
                      <Col lg={4} style={{ paddingRight: "0px" }}>
                        <Button type='submit' onClick={(e) => this.props.handleAddCountry(e)}>
                          <FaPlus />
                          Toevoegen
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Table bordered>
                          <thead>
                            <tr>
                              <th>
                                <span onClick={() => this.props.sortCountries("country_name")}>
                                  Land
                                  {this.props.state.sort.country_name === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                </span>
                              </th>
                              <th className='th-icon'>Verwijderen</th>
                            </tr>
                          </thead>
                          <tbody>{this.props.populateCountries()}</tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className='form-error'>{this.props.state.errors["submitError"]}</div>
                    <Button type='submit' disabled={this.props.state.isSubmitting}>{this.props.state.isSubmitting ? 'Opslaan...' : 'Opslaan'}</Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Settings;
