export default class Country {
  constructor(country_id = "", country_name = "", country_allowed = false) {
    this.country_id = country_id;
    this.country_name = country_name;
    this.country_allowed = country_allowed;
  }

  get country_id() {
    return this._country_id;
  }

  set country_id(id) {
    this._country_id = id !== undefined ? id : "";
  }

  get country_name() {
    return this._country_name;
  }

  set country_name(name) {
    this._country_name = name !== undefined ? name/*.replace(/\s+/g, "")*/ : "";
  }

  get country_allowed() {
    return this._country_allowed;
  }

  set country_allowed(allowed) {
    this._country_allowed = allowed !== undefined ? allowed : 0;
    this._country_allowed = this._country_allowed === 1 ? true : false;
  }

  setDataFromObject(obj) {
    Object.assign(this, obj);
  }
}
