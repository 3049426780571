const { capitalizeEachWord, extractNumbersFromString, removeNumbersFromString } = require("../misc/functions");

export default class Customer {
  // Constructor
  constructor(
    customer_id = "",
    customer_name = "",
    customer_password = "",
    customer_email = "",
    customer_address_streetname = "",
    customer_address_number = "",
    customer_address_number_extra = "",
    customer_address_postal = "",
    customer_address_city = "",
    customer_address_country = "",
    customer_btw = ""
  ) {
    this.customer_id = customer_id;
    this.customer_name = customer_name;
    this.customer_password = customer_password;
    this.customer_email = customer_email;
    this.customer_address_streetname = customer_address_streetname;

    this.customer_address_number = customer_address_number;
    this.customer_address_number_extra = customer_address_number_extra;
    this.customer_address_postal = customer_address_postal;
    this.customer_address_city = customer_address_city;
    this.customer_address_country = customer_address_country;
    this.customer_btw = customer_btw;
  }

  // Getters & Setters
  get customer_id() {
    return this._customer_id;
  }

  set customer_id(id) {
    this._customer_id = id !== undefined ? id.toString().trim() : "";
  }

  get customer_name() {
    return this._customer_name;
  }

  set customer_name(name) {
    // .replace white spaces by an actual space (otherwise the searchfield would never show items that contain a space (after searching for an item using a space in the searchQuery))
    this._customer_name = name !== undefined && name !== null ? name.toString().trim().replace(/\s+/g, " ") : "";
  }

  get customer_password() {
    return this._customer_password;
  }

  set customer_password(password) {
    this._customer_password = password !== undefined && password !== null ? password.toString().trim() : "";
  }

  get customer_email() {
    return this._customer_email;
  }

  set customer_email(email) {
    this._customer_email = email !== undefined && email !== null ? email.toString().trim().toLowerCase() : "";
  }

  get customer_address_streetname() {
    return this._customer_address_streetname;
  }

  set customer_address_streetname(streetname) {
    this._customer_address_streetname = streetname !== undefined && streetname !== null ? capitalizeEachWord(streetname.toString().trim()).replace(/\s+/g, " ") : "";
  }

  get customer_address_number() {
    return this._customer_address_number;
  }

  set customer_address_number(number) {
    this._customer_address_number = number !== undefined && number !== null ? extractNumbersFromString(number.toString().trim()) : "";
  }

  get customer_address_number_extra() {
    return this._customer_address_number_extra;
  }

  set customer_address_number_extra(number_extra) {
    this._customer_address_number_extra = number_extra !== undefined && number_extra !== null ? removeNumbersFromString(number_extra.toString().trim()).toUpperCase() : "";
  }

  get customer_address_postal() {
    return this._customer_address_postal;
  }

  set customer_address_postal(postal) {
    this._customer_address_postal = postal !== undefined && postal !== null ? postal.toString().trim() : "";
  }

  get customer_address_city() {
    return this._customer_address_city;
  }

  set customer_address_city(city) {
    this._customer_address_city = city !== undefined && city !== null ? capitalizeEachWord(city.toString().trim()).replace(/\s+/g, " ") : "";
  }

  get customer_address_country() {
    return this._customer_address_country;
  }

  set customer_address_country(country) {
    this._customer_address_country = country !== undefined && country !== null ? capitalizeEachWord(country.toString().trim()).replace(/\s+/g, " ") : "";

    // Might have to become the ID of the seelcted country
  }

  get customer_btw() {
    return this._customer_btw;
  }

  set customer_btw(btw) {
    this._customer_btw = btw !== undefined && btw !== null ? btw.toString().trim().replace(/\s+/g, " ") : "";
  }

  getFormattedAddress() {
    return `${this.customer_address_streetname} ${this.customer_address_number}${this.customer_address_number_extra}, ${this.customer_address_postal} ${this.customer_address_city} ${this.customer_address_country !== "" ? "(" + this.customer_address_country + ")" : ""}`;
  }

  //   Functions
  setDataFromObject(obj) {
    Object.assign(this, obj);
  }

  toJson(type) {
    let json = {};

    switch (type.toLowerCase()) {
      case "add":
        json = {
          customer_name: this.customer_name,
          customer_email: this.customer_email,
          customer_address_streetname: this.customer_address_streetname,
          customer_address_number: this.customer_address_number + this.customer_address_number_extra,
          customer_address_postal: this.customer_address_postal,
          customer_address_city: this.customer_address_city,
          customer_address_country: this.customer_address_country,
          customer_btw: this.customer_btw,
        };
        break;
      case "edit":
        json = {
          customer_id: this.customer_id,
          customer_name: this.customer_name,
          customer_email: this.customer_email,
          customer_address_streetname: this.customer_address_streetname,
          customer_address_number: this.customer_address_number + this.customer_address_number_extra,
          customer_address_postal: this.customer_address_postal,
          customer_address_city: this.customer_address_city,
          customer_address_country: this.customer_address_country,
          customer_btw: this.customer_btw,
        };
        break;
      case "delete":
        json = {
          customer_id: this._customer_id,
        };
        break;
      default:
        break;
    }

    return json;
  }
}
