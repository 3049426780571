import React from "react";

import Settings from "../components/Settings";
import SideNavContainer from "../components/SideNavContainer";

import ApiRequest from "../services/ApiRequest";

import Setting from "../models/Setting";
import Country from "../models/Country";
import { FaTrashAlt } from "react-icons/fa";

class SettingsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadError: false,
      loadErrorMessage: [],

      sort: {
        country_name: "asc",
      },

      deleteCountry: false,
      selectedCountry: new Country(),

      errors: {},

      settings_logo: "Uploaden",
      settings: new Setting(),
      success: "", //success message for saving settings
      countries: [new Country("1", "Nederland")],

      isSubmitting:  false,
    };
  }

  async componentDidMount() {
    function _getSettings() {
      return ApiRequest.axiosInstance.post("/settings/readSetting.php");
    }
    function _getCountries() {
      return ApiRequest.axiosInstance.post("/country/readCountries.php");
    }
    let loadError = false;
    let loadErrorMessage = [];
    await Promise.all([_getSettings(), _getCountries()])
      .then((results) => {
        let settings = results[0];
        console.log(settings);
        // console.log(settings.data.records)
        if (ApiRequest.callSuccess(settings)) {
          if (ApiRequest.callHasRecords(settings)) {
            let setting = new Setting();
            
            setting.setDataFromObject(settings.data.records[0]); // pak 1e value, we gaan er toch maar 1 weergeven + er zou maar 1 mogen inzitten
            this.setState({
              settings: setting,
            });
          }
        } else if (ApiRequest.callNoResults(settings)) {
          this.setState({
            settings: [],
          });
        } else {
          if (settings.data.message !== undefined) {
            loadErrorMessage.push(settings.data.message);
          }
          loadError = true;
        }
        let countries = results[1];
        if (ApiRequest.callSuccess(countries)) {
          if (ApiRequest.callHasRecords(countries)) {
            let countryData = [];
            for (let i = 0, len = countries.data.records.length; i < len; i++) {
              let country = new Country();
              country.setDataFromObject(countries.data.records[i]);
              countryData.push(country);
            }
            countryData.sort((a, b) => {
              return a.country_name.toLowerCase() > b.country_name.toLowerCase() ? 1 : -1;
            });
            this.setState({
              countries: countryData,
            });
          }
        } else if (ApiRequest.callNoResults(countries)) {
          this.setState({
            countries: [],
          });
        } else {
          if (countries.data.message !== undefined) {
            loadErrorMessage.push(countries.data.message);
          }
          loadError = true;
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.message !== undefined) {
            loadErrorMessage.push(err.response.data.message);
          }
        }
        loadError = true;
      })
      .then(() => {
        console.log("Settings 'Read' Request Finished");
        setTimeout(() => {
          this.setState({
            loading: false,
            loadError: loadError,
            loadErrorMessage: loadErrorMessage,
          });
        }, 500);
      });

    // // VERWIJDER DE SETSTATE HIERONDER VAN ZODRA DAT DE CODE HIERBOVEN WORDT GEBRUIKT
    // this.setState({
    //   loading: false,
    // });
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  _validForm = (formData) => {
    let errors = {};

    let contactName = formData.get("settings_contact_name");
    let contactEmail = formData.get("settings_contact_email");
    let contactPhone = formData.get("settings_contact_phone");
    let kpsPath = formData.get("settings_room_kps_path");

    if (contactName === "") {
      errors["settings_contact_name"] = "Contact naam is verplicht";
    }

    if (contactEmail === "") {
      errors["settings_contact_email"] = "Contact email is verplicht";
    }

    if (contactPhone === "") {
      errors["settings_contact_phone"] = "Contact telefoon is verplicht";
    }

    // if (kpsPath === "") {
    //   errors["settings_room_kps_path"] = "Contact telefoon is verplicht";
    // }

    this.setState({
      errors: errors,
    });

    if (Object.keys(errors).lenght > 0) {
      return false;
    }

    return true;
  };

  // Used to update the text on the upload button with uploaded file name
  handleLogoUpload = (e) => {
    const file = e.target.value.split("\\");
    const fileName = file[file.length - 1];

    this.setState({
      settings_logo: fileName,
    });
  };

  toggleDeleteCountry = (country) => {
    this.setState({
      errors: {},
      deleteCountry: !this.state.deleteCountry,
      selectedCountry: country !== undefined ? country : this.state.selectedCountry,
    });
  };

  sortCountries = (key) => {
    let countries = this.state.countries;
    let sort = this.state.sort;

    if (sort[key] === "asc") {
      sort[key] = "desc";
      this.setState({ sort });
      countries.sort((a, b) => (a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1));
    } else {
      sort[key] = "asc";
      this.setState({ sort });
      countries.sort((a, b) => (a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1));
    }

    this.setState({ countries });
  };

  _addStateUpdate = (country) => {
    let countries = this.state.countries;

    countries.push(country);

    this.setState({
      countries: countries,
    });
  };

  _deleteStateUpdate = (index) => {
    let countries = this.state.countries;

    for (let i = 0; i < countries.length; i++) {
      if (countries[i].country_id === index) {
        countries.splice(i, 1);
        break;
      }
    }

    this.setState({
      countries: countries,
    });
  };

  _countryNotExist = (countryName) => {
    for (let i = 0, len = this.state.countries.length; i < len; i++) {
      if (this.state.countries[i].country_name.toLowerCase().trim() === countryName.toLowerCase().trim()) {
        return false;
      }
    }

    return true;
  };

  handleAddCountry = async (e) => {
    e.preventDefault();
    const name = document.getElementById("country_name").value;

    let errors = {};

    if (name !== "") {
      if (this._countryNotExist(name)) {
        // let data = {
        //   country_name: name,
        // };
        // await ApiRequest.axiosInstance
        //   .post("/country/createCountry.php", data)
        //   .then((res) => {
        //     if (ApiRequest.callSuccess(res)) {
        //       if (ApiRequest.callHasRecords(res)) {
        //         let country = new Country("", name); //use inputted name as fallback
        //         if (res.data.records[0].hasOwnProperty("COUNTRY_ID")) {
        //           country.country_id = res.data.records[0].COUNTRY_ID;
        //         }
        //         if (res.data.records[0].hasOwnProperty("COUNTRY_NAME")) {
        //           country.country_name = res.data.records[0].COUNTRY_NAME;
        //         }
        //         this._addStateUpdate(country);
        //       }
        //     } else {
        //       let errors = this.state.errors;
        //       errors["country"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
        //       this.setState({
        //         errors: errors,
        //       });
        //     }
        //   })
        //   .catch((err) => {
        //     let errors = this.state.errors;
        //     errors["country"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
        //     this.setState({
        //       errors: errors,
        //     });
        //   })
        //   .then(() => {
        //     console.log("Country 'Add' Request Finished")
        //   });
      } else {
        errors["country"] = "Land is al toegevoegd";
      }
    } else {
      errors["country"] = "Land mag niet leeg zijn";
    }

    // Reset field back to old value (this doesn't automatically happen, because this field is not a submitted as a form)
    document.getElementById("country_name").value = "";

    this.setState({
      errors: errors,
    });
  };

  handleDeleteCountry = async (e) => {
    e.preventDefault();
    // let data = {
    //   country_id: this.state.selectedCountry.country_id,
    // };

    // await ApiRequest.axiosInstance
    //   .post("/country/deleteCountry.php", data)
    //   .then((res) => {
    //     if (ApiRequest.callSuccess(res)) {
    //       this._deleteStateUpdate(this.state.selectedCountry.country_id);
    //       this.toggleDeleteCountry();
    //     } else {
    //       let errors = this.state.errors;
    //       errors["country"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
    //       this.setState({
    //         errors: errors,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     let errors = this.state.errors;
    //     errors["country"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
    //     this.setState({
    //       errors: errors,
    //     });
    //   })
    //   .then(() => {
    //     console.log("Country 'Delete' Request Finished");
    //   });
  };

  handleSubmitSettings = async (e) => {
    e.preventDefault();

    this.setState({
      isSubmitting: true,
    });

    let formData = new FormData(e.target);

    let logo = formData.get("settings_logo");
    if(logo.name !== ""){
      await ApiRequest.axiosInstance({
        method: "post",
        url: "/settings/uploadLogo.php",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(res=>{
          if(ApiRequest.callSuccess){
            
          }
          else{
            let errors = this.state.errors;
            errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
            this.setState({
              errors: errors,
            });
          }
        })
    }

    if (this._validForm(formData)) {
      await ApiRequest.axiosInstance({
        method: "post",
        url: "/settings/saveSetting.php",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          console.log(res);
          if (ApiRequest.callSuccess) {

            let setting = this.state.settings;
            this.setState({
              settings: setting,
              success: "Instellingen opgeslagen",
            });

            // window.location.reload(true); DEPRECATED
            setTimeout(() => {
              window.location.href = window.location.href; //Reload + Force clearing cache
            }, 1000);
          } else {
            let errors = this.state.errors;
            errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
            this.setState({
              errors: errors,
            });
          }
        })
        .catch((err) => {
          let errors = this.state.errors;
          errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        })
        .then(() => {
          console.log("Settings 'Update' Request Finished");
        });
    }

    this.setState({
      isSubmitting: false,
    });

  };

  populateCountries = () => {
    let rows = [];

    let countries = this.state.countries;

    for (let i = 0; i < countries.length; i++) {
      if (countries[i].country_allowed === 1) {
        let items = [];

        items.push(<td key={i + "-country_name"}>{countries[i].country_name}</td>);
        items.push(
          <td key={i + "-delete"} className='td-icon' onClick={() => this.toggleDeleteCountry(countries[i])}>
            <FaTrashAlt />
          </td>
        );

        rows.push(<tr key={i}>{items}</tr>);
      }
    }

    return rows;
  };

  render() {
    return (
      <div>
        <SideNavContainer />
        <Settings
          state={this.state}
          handleLogoUpload={this.handleLogoUpload}
          sortCountries={this.sortCountries}
          populateCountries={this.populateCountries}
          handleAddCountry={this.handleAddCountry}
          toggleDeleteCountry={this.toggleDeleteCountry}
          handleDeleteCountry={this.handleDeleteCountry}
          handleSubmitSettings={this.handleSubmitSettings}
        />
      </div>
    );
  }
}

export default SettingsContainer;
