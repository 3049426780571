import React from "react";

import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Loading from "./Loading";

import { FaCaretDown, FaCaretUp, FaTimesCircle } from "react-icons/fa";

import ViewOrderModal from "./popups/orders/ViewOrderModal";
import DeleteOrderModal from "./popups/orders/DeleteOrderModal";
import Error from "./Error";

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='main-container'>
        {this.props.state.loading ? (
          <div className='loader'>
            <Loading />
          </div>
        ) : this.props.state.loadError ? (
          <Error />
        ) : (
          <div>
            <h1>Bestellingen</h1>
            <hr />

            <ViewOrderModal
              viewOrder={this.props.state.viewOrder}
              toggleViewOrder={this.props.toggleViewOrder}
              selectedOrder={this.props.state.selectedOrder}
              currentDocument={this.props.state.currentDocument}
              incrementDocument={this.props.incrementDocument}
              decrementDocument={this.props.decrementDocument}
              setHandlingStatus={this.props.setHandlingStatus}
            />

            <DeleteOrderModal deleteOrder={this.props.state.deleteOrder} toggleDeleteOrder={this.props.toggleDeleteOrder} submitDeleteOrder={this.props.submitDeleteOrder} selectedOrder={this.props.state.selectedOrder} formErrors={this.props.state.errors} />

            <Row>
              <Col lg={{ span: 2, offset: 0 }} className='search-outer-container'>
                <div className='search-container'>
                  <Form.Control type='text' placeholder='Zoeken op bestelnummer...' value={this.props.state.searchValue} onChange={(e) => this.props.searchChange(e.target.value)} />
                  <span className={this.props.state.searchValue !== "" ? "search-clear" : "hide"} onClick={this.props.clearSearch}>
                    <FaTimesCircle />
                  </span>
                </div>
              </Col>
            </Row>
            <br />
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    <span onClick={() => this.props.sortOrders("order_id")}>
                      Bestelnummer
                      {this.props.state.sort.order_id === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortOrders("order_date")}>
                      Datum
                      {this.props.state.sort.order_date === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortOrders("order_customer_name")}>
                      Klantnaam
                      {this.props.state.sort.order_customer_name === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortOrders("order_total_price")}>
                      Totaal
                      {this.props.state.sort.order_total_price === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortOrders("order_payment_method")}>
                      Betaalmethode
                      {this.props.state.sort.order_payment_method === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortOrders("order_payment_status")}>
                      Betaal Status
                      {this.props.state.sort.order_payment_status === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortOrders("order_handling_status")}>
                      Verwerking Status
                      {this.props.state.sort.order_handling_status === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th className='th-icon'>Bekijken</th>
                  <th className='th-icon'>JSON Downloaden</th>
                  <th className='th-icon'>Verwijderen</th>
                </tr>
              </thead>
              <tbody>{this.props.populateTable()}</tbody>
            </Table>
          </div>
        )}
      </div>
    );
  }
}

export default Orders;
