import React from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

class DeleteHelpsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal show={this.props.deleteHelp} onHide={this.props.toggleDeleteHelp}>
        <Modal.Header closeButton>
          <Modal.Title>Hulpvenster Verwijderen</Modal.Title>
        </Modal.Header>
        <Form method='POST' encType='multipart/form-data' onSubmit={this.props.submitDeleteHelp}>
          <Modal.Body>Bent u zeker dat u het hulpvenster voor stap "{this.props.selectedHelp.help_step_id}" wilt verwijderen?</Modal.Body>
          <Modal.Footer>
            {this.props.formErrors["submitError"] ? <Alert variant='danger'>{this.props.formErrors["submitError"]}</Alert> : null}
            <Button variant='primary' type='submit'>
              Verwijderen
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default DeleteHelpsModal;
