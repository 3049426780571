import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/table";

import Loading from "./Loading";
import Error from "./Error";

import { FaBookOpen, FaPlus, FaTimesCircle, FaCaretDown, FaCaretUp } from "react-icons/fa";

import AddHelpsModal from "./popups/help/AddHelpsModal";
import EditHelpsModal from "./popups/help/EditHelpsModal";
import ExampleHelpsModal from "./popups/help/ExampleHelpsModal";
import DeleteHelpsModal from "./popups/help/DeleteHelpsModal";

class Helps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className='main-container'>
                {this.props.state.loading ? (
                    <div className='loader'>
                        <Loading />
                    </div>
                ) : this.props.state.loadError ? (
                    <Error message={this.props.state.loadErrorMessage} />
                ) : (
                    <div>
                        <h1>Hulp vensters</h1>
                        <hr />
                        <AddHelpsModal addHelp={this.props.state.addHelp} toggleAddHelp={this.props.toggleAddHelp} submitAddHelp={this.props.submitAddHelp} formErrors={this.props.state.errors} />

                        <EditHelpsModal
                            editHelp={this.props.state.editHelp}
                            toggleEditHelp={this.props.toggleEditHelp}
                            submitEditHelp={this.props.submitEditHelp}
                            selectedHelp={this.props.state.selectedHelp}
                            formErrors={this.props.state.errors}
                        />

                        <ExampleHelpsModal
                            exampleHelp={this.props.state.exampleHelp}
                            toggleExampleHelp={this.props.toggleExampleHelp}
                            selectedHelp={this.props.state.selectedHelp}
                        />

                        <DeleteHelpsModal deleteHelp={this.props.state.deleteHelp} toggleDeleteHelp={this.props.toggleDeleteHelp} submitDeleteHelp={this.props.submitDeleteHelp} selectedHelp={this.props.state.selectedHelp} formErrors={this.props.state.errors} />

                        <Row>
                            <Col lg={{ span: 2, offset: 0 }} className='search-outer-container'>
                                <div className='search-container'>
                                    <Form.Control type='text' placeholder='Zoeken op hulp venster...' value={this.props.state.searchValue} onChange={(e) => this.props.searchChange(e.target.value)} />
                                    <span className={this.props.state.searchValue !== "" ? "search-clear" : "hide"} onClick={this.props.clearSearch}>
                                        <FaTimesCircle />
                                    </span>
                                </div>
                            </Col>

                            <Col lg={{ span: 1, offset: 9 }} className='add-btn-container'>
                                <Button variant='primary' onClick={this.props.toggleAddHelp}>
                                    <FaPlus /> Nieuw Hulpvenster
                </Button>
                            </Col>
                        </Row>
                        <br />
                        <Table responsive striped bordered hover>
                            <thead>
                                <tr>
                                    <th>
                                        <span
                                            onClick={() => {
                                                this.props.sortHelps("help_step_id");
                                            }}>
                                            Stap ID
                      {this.props.state.sort.help_step_id === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                        </span>
                                    </th>
                                    <th>
                                        <span
                                            onClick={() => {
                                                this.props.sortHelps("help_gif_path");
                                            }}>
                                            Afbeelding pad
                      {this.props.state.sort.help_gif_path === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                        </span>
                                    </th>
                                    <th>
                                        <span
                                            onClick={() => {
                                                this.props.sortHelps("help_text");
                                            }}>
                                            Hulptekst
                      {this.props.state.sort.help_text === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                        </span>
                                    </th>
                                    <th className='th-icon'>Voorbeeld</th>
                                    <th className='th-icon'>Aanpassen</th>
                                    <th className='th-icon'>Verwijderen</th>
                                </tr>
                            </thead>
                            <tbody>{this.props.populateTable()}</tbody>
                        </Table>
                    </div>
                )}
            </div>
        );
    }
}

export default Helps;
