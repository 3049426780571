import React from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { room_types } from "../../../misc/constants"

import { RoomDescriptionTooltip, RoomThumbnailTooltip, RoomTypeTooltip } from "./../../tooltips/RoomTooltips";

export default class EditRoomModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Modal show={this.props.editRoom} onHide={this.props.toggleEditRoom}>
                <Modal.Header closeButton>
                    <Modal.Title>Kamer Sjabloon Aanpassen</Modal.Title>
                </Modal.Header>
                <Form method='POST' encType='multipart/form-data' onSubmit={this.props.submitEditRoom}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>
                                Kamer Beschrijving <RoomDescriptionTooltip />
                            </Form.Label>
                            <Form.Control type='text' placeholder='Kamer Beschrijving' name='room_description' defaultValue={this.props.selectedRoom.room_description} />
                            <span className={this.props.formErrors["room_description"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["room_description"]}</span>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Kamer Type <RoomTypeTooltip />
                            </Form.Label>
                            <div>
                                {room_types.map(type => {
                                    if (type === this.props.selectedRoom.room_type)
                                        return (
                                            <div className='radio-button-group' key={type+'-radio-group'}>
                                                <input type='radio' name='room_type' value={type} id={'room_type_' + type} defaultChecked />
                                                <label htmlFor={'room_type_' + type}>{type}</label>
                                            </div>
                                        )
                                    else
                                        return (
                                            <div className='radio-button-group' key={type+'-radio-group'}>
                                                <input type='radio' name='room_type' value={type} id={'room_type_' + type} />
                                                <label htmlFor={'room_type_' + type}>{type}</label>
                                            </div>
                                        )
                                })}
                            </div>
                            <span className={this.props.formErrors["module_size"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["module_size"]}</span>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>
                                Thumbnail Uploaden <RoomThumbnailTooltip />
                            </Form.Label>
                            <Form.Control type='file' name='room_thumbnail' accept='.png, .jpg, .jpeg' />
                            <span className={this.props.formErrors["room_thumbnail"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["room_thumbnail"]}</span>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.props.formErrors["submitError"] ? <Alert variant='danger'>{this.props.formErrors["submitError"]}</Alert> : null}
                        <Button variant='primary' type='submit'>
                            Toevoegen
                    </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}