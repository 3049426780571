import React from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import { ModuleNameTooltip, ModuleDescriptionTooltip, ModuleSizeTooltip, ModuleKorpusNameTooltip, ModuleKorpusFileTooltip, ModuleModelFileTooltip, ModuleThumbnailFileTooltip, ModuleBaseMaterialTooltip, ModuleBasePriceTooltip, ModulePriceScaleTooltip, ModuleModelHeightTooltip } from "./../../tooltips/ModuleTooltips";

class AddModuleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  populateDropDown = () => {
    let dropdownItems = [];

    let baseMaterials = this.props.baseMaterials;
    for (let i = 0, len = baseMaterials.length; i < len; i++) {
      dropdownItems.push(
        <option key={i} value={baseMaterials[i].material_id}>
          {baseMaterials[i].material_name}
        </option>
      );
    }

    return dropdownItems;
  };

  render() {
    return (
      <Modal show={this.props.addModule} onHide={this.props.toggleAddModule}>
        <Modal.Header closeButton>
          <Modal.Title>Module Toevoegen</Modal.Title>
        </Modal.Header>
        <Form method='POST' encType='multipart/form-data' onSubmit={this.props.submitAddModule}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>
                Module Naam <ModuleNameTooltip />
              </Form.Label>
              <Form.Control type='text' placeholder='Module Naam' name='module_name' />
              <span className={this.props.formErrors["module_name"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["module_name"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>Module Beschrijving <ModuleDescriptionTooltip /></Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Module Beschrijving" name="module_description" />
              <span className={this.props.formErrors["module_description"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["module_description"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Module Grootte <ModuleSizeTooltip />
              </Form.Label>
              <div>
                <div className='radio-button-group'>
                  <input type='radio' name='module_size' value='1' id='module_size_1' defaultChecked />
                  <label htmlFor='module_size_1'>1</label>
                </div>
                <div className='radio-button-group'>
                  <input type='radio' name='module_size' value='2' id='module_size_2' />
                  <label htmlFor='module_size_2'>2</label>
                </div>
              </div>
              <span className={this.props.formErrors["module_size"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["module_size"]}</span>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Korpus Naam <ModuleKorpusNameTooltip />
              </Form.Label>
              <Form.Control type='text' placeholder='Korpus Naam' name='module_kps_name' />
              <span className={this.props.formErrors["module_kps_name"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["module_kps_name"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Korpus Bestand <ModuleKorpusFileTooltip />
              </Form.Label>
              <Form.Control type='text' placeholder='Korpus Bestand' name='module_kps_path' />
              <span className={this.props.formErrors["module_kps_path"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["module_kps_path"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Model Uploaden <ModuleModelFileTooltip />
              </Form.Label>
              <Form.Control type='file' name='module_model' accept='.glb, .gltf, .GLB, .GLTF' />
              <span className={this.props.formErrors["module_model"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["module_model"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Model Hoogte (in cm) <ModuleModelHeightTooltip />
              </Form.Label>
              <Form.Control type='number' step='1' placeholder='Module Hoogte' name='module_model_height' />
              <span className={this.props.formErrors["module_model_height"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["module_model_height"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Thumbnail Uploaden <ModuleThumbnailFileTooltip />
              </Form.Label>
              <Form.Control type='file' name='module_model_thumbnail' accept='.png, .jpg, .jpeg' />
              <span className={this.props.formErrors["module_model_thumbnail"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["module_model_thumbnail"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Basis Materiaal <ModuleBaseMaterialTooltip />
              </Form.Label>
              <Form.Control as='select' name='module_base_material_id' defaultValue={this.props.baseMaterials.length > 0 ? this.props.baseMaterials[0].material_id : null} className='custom-select'>
                {this.populateDropDown()}
              </Form.Control>
              <span className={this.props.formErrors["module_base_material_id"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["module_base_material_id"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Standaard Prijs <ModuleBasePriceTooltip />
              </Form.Label>
              <Form.Control type='number' step='0.01' placeholder='Standaard Prijs' name='module_base_price' />
              <span className={this.props.formErrors["module_base_price"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["module_base_price"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Prijsfactors <ModulePriceScaleTooltip />
              </Form.Label>
              <Form.Control type='number' step='0.01' placeholder='Hoogte' name='module_height_price_scale' />
              <span className={this.props.formErrors["module_height_price_scale"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["module_height_price_scale"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Control type='number' step='0.01' placeholder='Breedte' name='module_width_price_scale' />
              <span className={this.props.formErrors["module_width_price_scale"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["module_width_price_scale"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Control type='number' step='0.01' placeholder='Diepte' name='module_depth_price_scale' />
              <span className={this.props.formErrors["module_depth_price_scale"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["module_depth_price_scale"]}</span>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {this.props.formErrors["submitError"] ? <Alert variant='danger'>{this.props.formErrors["submitError"]}</Alert> : null}
            <Button variant='primary' type='submit'>
              Toevoegen
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default AddModuleModal;
