import React from "react";

import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaPlus, FaCaretDown, FaCaretUp, FaTimesCircle } from "react-icons/fa";

import AddPlintModal from "./popups/plints/AddPlintModal";
import CopyPlintModal from "./popups/plints/CopyPlintModal";
import EditPlintModal from "./popups/plints/EditPlintModal";
import DeletePlintModal from "./popups/plints/DeletePlintModal";
import AddRoomModal from "./popups/rooms/AddRoomModal";
import EditRoomModal from "./popups/rooms/EditRoomModal";
import DeleteRoomModal from "./popups/rooms/DeleteRoomModal";
import Loading from "./Loading";
import Error from "./Error";

export default class Rooms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        return (
            <div className='main-container'>
                {this.props.state.loading ? (
                    <div className='loader'>
                        <Loading />
                    </div>
                ) : this.props.state.loadError ? (
                    <Error message={this.props.state.loadErrorMessage} />
                ) : (
                            <div>
                                <h1>Kamer</h1>
                                <hr />
                                <Tabs defaultActiveKey="room" id="room-plint-tab">
                                    <Tab eventKey="room" title="Kamers">
                                        <AddRoomModal addRoom={this.props.state.addRoom} toggleAddRoom={this.props.toggleAddRoom} submitAddRoom={this.props.submitAddRoom} formErrors={this.props.state.errors} />
                                        <EditRoomModal editRoom={this.props.state.editRoom} toggleEditRoom={this.props.toggleEditRoom} submitEditRoom={this.props.submitEditRoom} selectedRoom={this.props.state.selectedRoom} formErrors={this.props.state.errors} />
                                        <DeleteRoomModal deleteRoom={this.props.state.deleteRoom} toggleDeleteRoom={this.props.toggleDeleteRoom} submitDeleteRoom={this.props.submitDeleteRoom} selectedRoom={this.props.state.selectedRoom} formErrors={this.props.state.errors} />

                                        <br />
                                        <Row>
                                            <Col lg={{ span: 2, offset: 0 }} className='search-outer-container'>
                                                {/* <div className='search-container'>
                                                    <Form.Control type='text' placeholder='Zoeken op configuratie...' value={this.props.state.searchValue} onChange={(e) => this.props.searchChange(e.target.value)} />
                                                    <span className={this.props.state.searchValue !== "" ? "search-clear" : "hide"} onClick={this.props.clearSearch}>
                                                        <FaTimesCircle />
                                                    </span>
                                                </div> */}
                                            </Col>

                                            <Col lg={{ span: 1, offset: 9 }} className='add-btn-container'>
                                                <Button variant='primary' onClick={this.props.toggleAddRoom}>
                                                    <FaPlus /> Nieuwe Kamer
                                                </Button>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Table responsive striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span onClick={() => this.props.sortRooms("room_id")}>
                                                            Kamer ID
                                                    {this.props.state.sort.room_id === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span onClick={() => this.props.sortRooms("room_description")}>
                                                            Kamer Beschrijving
                                                    {this.props.state.sort.room_description === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span onClick={() => this.props.sortRooms("room_type")}>
                                                            Kamer Type
                                                    {this.props.state.sort.room_type === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span onClick={() => this.props.sortRooms("room_thumbnail_path")}>
                                                            Kamer Thumbnail
                                                    {this.props.state.sort.room_thumbnail_path === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th className='th-icon'>Bekijken</th>
                                                    <th className='th-icon'>Aanpassen</th>
                                                    <th className='th-icon'>Verwijderen</th>
                                                </tr>
                                            </thead>
                                            <tbody>{this.props.populateRoomTable()}</tbody>
                                        </Table>
                                    </Tab>

                                    <Tab eventKey="plint" title="Plint Configuraties">
                                        {/* <h1>Plint Configuraties</h1>
                                        <hr /> */}
                                        {/* Pop-up form (Add Plint) */}
                                        <AddPlintModal addPlint={this.props.state.addPlint} toggleAddPlint={this.props.toggleAddPlint} submitAddPlint={this.props.submitAddPlint} rooms={this.props.state.rooms} formErrors={this.props.state.errors} />
                                        <EditPlintModal editPlint={this.props.state.editPlint} toggleEditPlint={this.props.toggleEditPlint} submitEditPlint={this.props.submitEditPlint} selectedPlint={this.props.state.selectedPlint} rooms={this.props.state.rooms} formErrors={this.props.state.errors} />
                                        <CopyPlintModal copyPlint={this.props.state.copyPlint} toggleCopyPlint={this.props.toggleCopyPlint} submitCopyPlint={this.props.submitCopyPlint} selectedPlint={this.props.state.selectedPlint} rooms={this.props.state.rooms} formErrors={this.props.state.errors} />
                                        <DeletePlintModal deletePlint={this.props.state.deletePlint} toggleDeletePlint={this.props.toggleDeletePlint} submitDeletePlint={this.props.submitDeletePlint} selectedPlint={this.props.state.selectedPlint} rooms={this.props.state.rooms} formErrors={this.props.state.errors} />
                                        
                                        <br />
                                        <Row>
                                            <Col lg={{ span: 2, offset: 0 }} className='search-outer-container'>
                                                <div className='search-container'>
                                                    <Form.Control type='text' placeholder='Zoeken op configuratie...' value={this.props.state.searchValue} onChange={(e) => this.props.searchChange(e.target.value)} />
                                                    <span className={this.props.state.searchValue !== "" ? "search-clear" : "hide"} onClick={this.props.clearSearch}>
                                                        <FaTimesCircle />
                                                    </span>
                                                </div>
                                            </Col>

                                            <Col lg={{ span: 1, offset: 9 }} className='add-btn-container'>
                                                <Button variant='primary' onClick={this.props.toggleAddPlint}>
                                                    <FaPlus /> Nieuw
                                                </Button>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Table responsive striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <span onClick={() => this.props.sortPlints("plint_id")}>
                                                            Configuratie ID
                                                    {this.props.state.sort.plint_id === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span onClick={() => this.props.sortPlints("min_width")}>
                                                            Minimumbreedte
                                                    {this.props.state.sort.min_width === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span onClick={() => this.props.sortPlints("max_width")}>
                                                            Maximumbreedte
                                                    {this.props.state.sort.max_width === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span onClick={() => this.props.sortPlints("bottom_dim")}>
                                                            Ondermaat
                                                    {this.props.state.sort.bottom_dim === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span onClick={() => this.props.sortPlints("top_dim")}>
                                                            Bovenmaat
                                                    {this.props.state.sort.top_dim === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span onClick={() => this.props.sortPlints("left_dim")}>
                                                            Linkermaat
                                                    {this.props.state.sort.left_dim === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span onClick={() => this.props.sortPlints("right_dim")}>
                                                            Rechtermaat
                                                    {this.props.state.sort.right_dim === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span onClick={() => this.props.sortPlints("base_price")}>
                                                            Basisprijs
                                                    {this.props.state.sort.base_price === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span onClick={() => this.props.sortPlints("bottom_kpo")}>
                                                            KPO Onder
                                                    {this.props.state.sort.bottom_kpo === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span onClick={() => this.props.sortPlints("top_kpo")}>
                                                            KPO Boven
                                                    {this.props.state.sort.top_kpo === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span onClick={() => this.props.sortPlints("left_kpo")}>
                                                            KPO Links
                                                    {this.props.state.sort.left_kpo === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span onClick={() => this.props.sortPlints("right_kpo")}>
                                                            KPO Rechts
                                                    {this.props.state.sort.right_kpo === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th>
                                                        <span onClick={() => this.props.sortPlints("room_id")}>
                                                            Kamer ID
                                                    {this.props.state.sort.room_id === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                                                        </span>
                                                    </th>
                                                    <th className='th-icon'>Aanpassen</th>
                                                    <th className='th-icon'>Kopiëren</th>
                                                    <th className='th-icon'>Verwijderen</th>
                                                </tr>
                                            </thead>
                                            <tbody>{this.props.populatePlintTable()}</tbody>
                                        </Table>
                                    </Tab>
                                </Tabs>
                            </div>
                        )}
            </div>
        );
    }
}  