// Imports
import React from "react";
import formatPrice from "../../misc/functions";
import { Bar } from "react-chartjs-2";

export default function DashboardPriceChart(props) {
  const months = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];

  const _getLastYear = () => {
    let year = -1;

    let orderData = props.orderData;

    for (let i = 0, len = orderData.length; i < len; i++) {
      if (+orderData[i].date.year > year) {
        year = +orderData[i].date.year;
      }
    }

    return year;
  };

  const _getChartDataFromProps = () => {
    let amountData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // 12x 0 (voor iedere maand)

    let orderData = props.orderData;
    let selectedYear = props.selectedYear;

    if (selectedYear === null) {
      selectedYear = _getLastYear();
    }

    if (orderData !== undefined) {
      for (let i = 0, len = orderData.length; i < len; i++) {
        if (+orderData[i].date.year === +selectedYear) {
          amountData[orderData[i].date.month] += 1;
        }
      }
    }

    return amountData;
  };

  const _getPriceDataFromProps = () => {
    let chartData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    let orderData = props.orderData;
    let selectedYear = props.selectedYear;

    if (selectedYear === null) {
      selectedYear = _getLastYear();
    }

    if (orderData !== undefined) {
      for (let i = 0, len = orderData.length; i < len; i++) {
        if (+orderData[i].date.year === +selectedYear) {
          chartData[orderData[i].date.month] += +orderData[i].total_price;
        }
      }
    }

    return chartData;
  };

  let chartData = _getPriceDataFromProps();
  let amountData = _getChartDataFromProps();

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  const settings = props.settings;
 
  const primaryColorRGB = hexToRgb(settings.style['$color-primary'] != null ? settings.style['$color-primary'] : '#059bff');

  // Chart data (+ colors)
  const data = {
    labels: months,
    datasets: [
      {
        backgroundColor: `rgba(${primaryColorRGB.r},${primaryColorRGB.g},${primaryColorRGB.b}, 0.3)`, 
        borderColor: `rgba(${primaryColorRGB.r},${primaryColorRGB.g},${primaryColorRGB.b}, 1)`, 
        borderWidth: 1,
        hoverBackgroundColor: `rgba(${primaryColorRGB.r},${primaryColorRGB.g},${primaryColorRGB.b}, 0.5)`, 
        hoverBorderColor: `rgba(${primaryColorRGB.r},${primaryColorRGB.g},${primaryColorRGB.b}, 1)`, 
        data: chartData,
        amountData: amountData,
      },
    ],
  };

  // Chart options
  const options = {
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            startAtZero: true,
            callback: function (value, index, values) {
              return "€ " + formatPrice(value);
            },
          },
        },
      ],
    },

    tooltips: {
      mode: "label",
      intersect: false,
      enabled: props.tooltipEnabled,
      callbacks: {
        title: function (tooltipItem, data) {
          let month = tooltipItem[0].xLabel;

          let year = props.selectedYear;

          if (year === null) {
            year = _getLastYear();
          }

          return `Opbrengsten ${month} ${year}`;
        },

        afterLabel: function (tooltipItem, data) {
          let aantal = data.datasets[0].amountData[tooltipItem.index];

          return ` Aantal: ${aantal}`;
        },

        label: function (tooltipItem, data) {
          let price = +tooltipItem.value;

          return ` Totaal: € ${formatPrice(price)}`;
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
}
