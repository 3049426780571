import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { BsQuestionCircleFill } from "react-icons/bs";

function CustomerNameTooltip() {
  return (
    <OverlayTrigger
      placement='right'
      overlay={
        <Tooltip>
          Volledige naam van de klant <br />
          Bv: Jan Janssen
        </Tooltip>
      }>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function CustomerEmailTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Email van de klant</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function CustomerAddressTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Adres van de klant</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function CustomerDeliveryTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Leveringsadres voor de klant</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function CustomerBtwTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>BTW-nummer van de klant</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

export { CustomerNameTooltip, CustomerEmailTooltip, CustomerAddressTooltip, CustomerDeliveryTooltip, CustomerBtwTooltip };
