import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { BsQuestionCircleFill } from "react-icons/bs";

function MaterialGroupNameTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Groepnaam voor het materiaal, getoond in de configurator.</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function MaterialRealNameTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Naam voor het materiaal, gebruikt bij het vervangen in Korpus.</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function MaterialReplaceVariableTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Variable in het sjabloon om te vervangen.</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function MaterialTypeTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Is deze versie van het materiaal voor de binnen- of buitenkant van de kast.</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function MaterialTextureTooltip() {
  return (
    <OverlayTrigger
      placement='right'
      overlay={
        <Tooltip>
          Textuur voor het materiaal
          <br />
          (.jpg, .jpeg, .png,...)
        </Tooltip>
      }>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function MaterialPriceScaleTooltip() {
  return (
    <OverlayTrigger
      placement='right'
      overlay={
        <Tooltip>
          Schaling voor prijs
          <br /> 1 = geen verschil in prijs
          <br /> &gt; 1 = hogere prijs
        </Tooltip>
      }>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

export { MaterialGroupNameTooltip, MaterialRealNameTooltip, MaterialTypeTooltip, MaterialReplaceVariableTooltip, MaterialTextureTooltip, MaterialPriceScaleTooltip };
