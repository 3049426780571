import React from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import { FaEye, FaEyeSlash } from "react-icons/fa";

import logo from "../images/vlecad-logo.png";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidePassword: true,
    };
  }

  render() {
    return (
      <div className='login-container'>
        <div className='login-logo-container noselect'>
          <img src={logo} alt='Logo Vlecad' className='nodrag' />
        </div>
        <div className='login-title'>
          <h1>KorpusWeb Test</h1>
        </div>
        <Form onSubmit={this.props.handleSubmit}>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <br />
            <Form.Control type='text' name='email' placeholder='Email' onChange={(e) => this.props.handleChange(e)} />
            <span className={this.props.formErrors["email"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["email"]}</span>
          </Form.Group>
          <Form.Group>
            <Form.Label>Wachtwoord</Form.Label>
            <br />
            <div className='password-group'>
              <Form.Control type={this.state.hidePassword ? "password" : "text"} placeholder='Wachtwoord' name='password' className='password-field' onChange={(e) => this.props.handleChange(e)} />

              <span className='password-eye'>{this.state.hidePassword ? <FaEye onClick={() => this.setState({ hidePassword: false })} /> : <FaEyeSlash onClick={() => this.setState({ hidePassword: true })} />}</span>
            </div>
            <span className={this.props.formErrors["password"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["password"]}</span>
          </Form.Group>

          {this.props.formErrors["submitError"] ? <Alert variant='danger'>{this.props.formErrors["submitError"]}</Alert> : null}

          <Button variant='primary' type='submit'>
            Login
          </Button>
        </Form>
      </div>
    );
  }
}

export default Login;
