import React from "react";

import Button from "react-bootstrap/Button";

class Error extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleReload = () => {
    window.location.reload();
  };

  _buildErrorMessage = () => {
    let errorMessages = [];

    let messages = this.props.message;
    for (let i = 0, len = messages.length; i < len; i++) {
      if (messages[i] !== "") {
        errorMessages.push(<li key={i}>{messages[i]}</li>);
      }
    }

    return errorMessages;
  };

  render() {
    return (
      <div className='loader'>
        <h1>Er is iets fout gegaan!</h1>
        <br />
        <Button onClick={this.handleReload}>Probeer opnieuw</Button>
        <hr />
        <ul>{this._buildErrorMessage()}</ul>
      </div>
    );
  }
}

export default Error;
