const axios = require("axios");

export default class ApiRequest {
  // Development;
  static _url = "http://192.168.1.91:8800";
  static _default_path = "KorpusOnline/Master/api";
  // Default settings for API request
   //static _url = "https://masterapi.korpusweb.be/";
   //static _default_path = "Master/KorpusOnline/api";

  static _create_api_url() {
    return `${process.env.REACT_APP_API_ENDPOINT}/${this._default_path}`;
  }

  // Axios Instance can be used for calls to always use the settings defined in it
  static get axiosInstance() {
    return axios.create({
      baseURL: this._create_api_url(),
      timeout: 30000,
    });
  }

  // Checks if axios api call was successful
  static callSuccess(response) {
    if (response.status === 200 && response.data.success) {
      return true;
    }

    return false;
  }

  // Call returned 'success' false, because the SELECT couldn't get any results (0 rows)
  static callNoResults(response) {
    if (response.status === 200 && !response.data.success) {
      return true;
    }

    return false;
  }
  // Check if axios api call has records
  static callHasRecords(response) {
    if (response.data.hasOwnProperty("records")) {
      // console.log(response);
      console.log(response.data.records.length);
      if (response.data.records.length > 0) {
        return true;
      }
    }

    return false;
  }
}
