import React from "react";
import SideNavContainer from "../components/SideNavContainer";
import Modules from "../components/Modules";
import formatPrice from "../misc/functions";

import { FaCopy, FaTrashAlt, FaPencilAlt } from "react-icons/fa";

import ApiRequest from "./../services/ApiRequest";
import Module from "../models/Module";

class ModulesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadError: false,
      loadErrorMessage: [],
      /* Toggle Popup */
      addModule: false, // Toggle AddModulePopup
      editModule: false, // Toggle EditModulePopup
      copyModule: false, // Toggle CopyModulePopup
      deleteModule: false, //Toggle DeleteModulePopup

      /* Form */
      selectedModule: new Module(),
      fields: {}, // Handle onChange of FormElements (Add)
      errors: {}, // Form Errors
      postError: "",

      /* Search + Sort */
      sort: {
        module_name: "desc",
        module_size: "desc",
        module_model_height: "desc",
        module_kps_name: "desc",
        module_base_price: "desc",
        module_width_price_scale: "desc",
        module_height_price_scale: "desc",
        module_depth_price_scale: "desc",
      },
      searchValue: "", // Search Value

      /* Modules */
      backupModules: [], //Used for filtering
      modules: [],
      baseMaterials: [],
    };
  }

  async componentDidMount() {
    function _getModules() {
      return ApiRequest.axiosInstance.post("/module/readAllModules.php");
    }

    function _getBaseMaterials() {
      return ApiRequest.axiosInstance.post("/material/readAllMaterials.php");
    }

    let loadError = false;
    let loadErrorMessage = [];

    await Promise.all([_getModules(), _getBaseMaterials()])
      .then((results) => {
        let dbModules = results[0];
        if (ApiRequest.callSuccess(dbModules)) {
          if (ApiRequest.callHasRecords(dbModules)) {
            let modules = [];

            for (let i = 0, len = dbModules.data.records.length; i < len; i++) {
              let module = new Module();
              module.setDataFromObject(dbModules.data.records[i]);
              modules.push(module);
            }

            modules.sort((a, b) => (a.module_id > b.module_id ? 1 : -1));

            this.setState({
              modules: modules,
            });
          }
        } else if (ApiRequest.callNoResults(dbModules)) {
          this.setState({
            modules: [],
          });
        } else {
          loadError = true;
          if (dbModules.data.records.message !== undefined) {
            loadErrorMessage.push(dbModules.data.message);
          }
        }

        let baseMaterials = results[1];
        if (ApiRequest.callSuccess(baseMaterials)) {
          if (ApiRequest.callHasRecords(baseMaterials)) {
            let _baseMaterials = [];

            for (let i = 0, len = baseMaterials.data.records.length; i < len; i++) {
              _baseMaterials.push({
                material_id: baseMaterials.data.records[i].material_id,
                material_base_name: baseMaterials.data.records[i].material_base_name,
              });
            }

            this.setState({
              baseMaterials: _baseMaterials,
            });
          }
        } else if (ApiRequest.callNoResults(baseMaterials)) {
          this.setState({
            baseMaterials: [],
          });
        } else {
          loadError = true;
          if (baseMaterials.data.records.message !== undefined) {
            loadErrorMessage.push(baseMaterials.data.message);
          }
        }
      })
      .catch((err) => {
        loadError = true;
        if (err.response) {
          if (err.response.data.message !== undefined) {
            loadErrorMessage.push(err.response.data.message);
          }
        }
      })
      .finally(() => {
        console.log("Modules 'Read' Request Finished");
        console.log("Modules Error:", loadError, loadErrorMessage);
        setTimeout(() => {
          this.setState({
            loading: false,
            loadError: loadError,
            loadErrorMessage: loadErrorMessage,
          });
        }, 500);
      });
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  /* Search & Sort Functions */

  // Handle Changes in 'Live Search' of Table (Search Box) + Update Table (Hide Materials that Don't Match Criteria)
  searchChange = (value) => {
    this.setState({ searchValue: value });

    let backupModules = this.state.backupModules;

    if (backupModules.length === 0) {
      backupModules = [...this.state.modules];
      this.setState({ backupModules: backupModules });
    }

    let modules = [];

    for (let i = 0, len = backupModules.length; i < len; i++) {
      if (backupModules[i].module_name.toString().toLowerCase().includes(value.toLowerCase())) {
        modules.push(backupModules[i]);
      }
    }

    this.setState({ modules: modules });
  };

  // Clear 'Live Search' (Search Box) + Update Table (Display all Modules)
  clearSearch = () => {
    this.setState({ searchValue: "", modules: this.state.backupModules });
  };

  // Ascending/Descind Sort Materials (onClick 'th')
  sortModules = (key) => {
    let modules = this.state.modules;
    let sort = this.state.sort;

    if (this.state.sort[key] === "asc") {
      sort[key] = "desc";
      this.setState({ sort });
      modules.sort((a, b) => (a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1));
    } else {
      sort[key] = "asc";
      this.setState({ sort });
      modules.sort((a, b) => (a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1));
    }

    this.setState({ modules });
  };

  /* Form Functions */

  // Validate form (Called on Submit of Add & Edit) + Create Error Messages if Needed
  _validForm = (e, isEdit = false) => {
    let selectedModule = this.state.selectedModule;
    let errors = {};
    let formIsValid = true;

    let formData = new FormData(e.target);

    let module_name = formData.get("module_name");
    let module_description = formData.get("module_description");
    let module_size = formData.get("module_size");
    let module_kps_name = formData.get("module_kps_name");
    let module_kps_path = formData.get("module_kps_path");
    let module_model = formData.get("module_model");
    let module_model_height = formData.get("module_model_height");
    let module_model_thumbnail = formData.get("module_model_thumbnail");
    let module_base_material_id = formData.get("module_base_material_id");
    let module_base_price = formData.get("module_base_price");
    let module_height_price_scale = formData.get("module_height_price_scale");
    let module_width_price_scale = formData.get("module_width_price_scale");
    let module_depth_price_scale = formData.get("module_depth_price_scale");

    if (module_name !== "") {
      selectedModule.module_name = module_name;
    } else {
      errors["module_name"] = "Materiaal naam is verplicht";
    }
    
    if(module_description.length < 256){
      selectedModule.module_description = module_description;
    } else {
      errors["module_description"] = "Beschrijving te lang. Maximum 255 karakters. Huidige lengte: " + module_description.length;
    }

    // No validation on module_size (radio buttons)
    selectedModule.module_size = +module_size;

    if (module_kps_name !== "") {
      selectedModule.module_kps_name = module_kps_name;
    } else {
      errors["module_kps_name"] = "KPS naam is verplicht";
    }

    if (module_kps_path !== "") {
      selectedModule.module_kps_path = module_kps_path;
    } else {
      errors["module_kps_path"] = "KPS path is verplicht";
    }

    if (module_model.name !== "") {
      selectedModule.module_model_path = module_model;
    } else if (isEdit) {
      selectedModule.module_model_path = null;
    } else {
      errors["module_model"] = "Model uploaden is verplicht";
    }

    if (module_model_height !== "") {
      module_model_height = +module_model_height;
      if (module_model_height > 0) {
        selectedModule.module_model_height = module_model_height;
      } else {
        errors["module_model_height"] = "Module hoogte moet groter dan 0 zijn";
      }
    } else {
      errors["module_model_height"] = "Module hoogte is verplicht";
    }

    if (module_model_thumbnail.name !== "") {
      selectedModule.module_model_thumbnail_path = module_model_thumbnail;
    } else if (isEdit) {
      selectedModule.module_model_thumbnail_path = null;
    } else {
      errors["module_model_thumbnail"] = "Thumbnail uploaden is verplicht";
    }

    // No validation on module_base_material_id (dropdown)
    selectedModule.module_base_material_id = module_base_material_id;

    if (module_base_price !== "") {
      module_base_price = +module_base_price;
      if (module_base_price > 0) {
        selectedModule.module_base_price = module_base_price;
      } else {
        errors["module_base_price"] = "Standaard prijs moet groter dan 0 zijn";
      }
    } else {
      errors["module_base_price"] = "Standaard prijs is verplicht";
    }

    if (module_height_price_scale !== "") {
      module_height_price_scale = +module_height_price_scale;
      if (module_height_price_scale > 0) {
        selectedModule._module_height_price_scale = module_height_price_scale;
      } else {
        errors["module_height_price_scale"] = "Prijsfactor (hoogte) moet groter dan 0 zijn";
      }
    } else {
      errors["module_height_price_scale"] = "Prijsfactor (hoogte) is verplicht";
    }

    if (module_width_price_scale !== "") {
      module_width_price_scale = +module_width_price_scale;
      if (module_width_price_scale > 0) {
        selectedModule.module_width_price_scale = module_width_price_scale;
      } else {
        errors["module_width_price_scale"] = "Prijsfactor (breedte) moet groter dan 0 zijn";
      }
    } else {
      errors["module_width_price_scale"] = "Prijsfactor (breedte) is verplicht";
    }

    if (module_depth_price_scale !== "") {
      module_depth_price_scale = +module_depth_price_scale;
      if (module_depth_price_scale > 0) {
        selectedModule.module_depth_price_scale = module_depth_price_scale;
      } else {
        errors["module_depth_price_scale"] = "Prijsfactor (diepte) moet groter dan 0 zijn";
      }
    } else {
      errors["module_depth_price_scale"] = "Prijsfactor (diepte) is verplicht";
    }

    if (Object.keys(errors).length > 0) {
      formIsValid = false;
    }

    this.setState({ errors: errors, selectedModule: selectedModule });

    return formIsValid;
  };

  /* Popups (Add, Edit, Delete) */

  // Show/Hide 'Add Module' Pop-up + Clear all 'Changes' from 'fields' and 'errors'
  toggleAddModule = () => {
    this.setState({
      selectedModule: new Module(),
      errors: {},
      addModule: !this.state.addModule,
    });
  };

  // Show/Hide 'Edit Module' Pop-up + Clear 'errors' + set 'fields' (Required for Bypassing Validation when Submitting without Changing Anything)
  toggleEditModule = (module) => {
    this.setState({
      selectedModule: module !== undefined ? Object.assign(Object.create(Object.getPrototypeOf(module)), module) : this.state.selectedModule,
      errors: {},
      editModule: !this.state.editModule,
    });
  };

  // Show/Hide 'Copy Module' Pop-up + Clear 'errors' + set 'fields' (Required for Bypassing Validation when Submitting without Changing Anything)
  toggleCopyModule = (module) => {

    // HotFix - Handle Copy if copying from a copy of an existing module without reloading the page
    if (module !== undefined && module._module_id === undefined) {
      let moduleobj = new Module();
      moduleobj.setDataFromObject(module);
      this.setState({
        selectedModule: moduleobj,
        errors: {},
        copyModule: !this.state.copyModule,
      });
    } else {
      this.setState({
        selectedModule: module !== undefined ? Object.assign(Object.create(Object.getPrototypeOf(module)), module) : this.state.selectedModule,
        errors: {},
        copyModule: !this.state.copyModule,
      });
    }

    
  };

  // Show/Hide 'Delete Module' Pop-up + Set 'fields' (Used for Displaying 'module_name' + having the 'id' )
  // NOTE: Errors are not cleared, because we clear them on all others and delete doesn't have any errors linked to it as the only errors that could occur is something with the backend: eg. invalid ID,...
  toggleDeleteModule = (module) => {
    this.setState({
      deleteModule: !this.state.deleteModule,
      selectedModule: module !== undefined ? module : this.state.selectedModule,
    });
  };

  /* Update State on Successful HTTP Request */

  // Add module to 'this.state.module'
  _addStateModule = (module) => {
    let modules = this.state.modules;
    modules.push(module);
    this.setState({ modules });
  };

  // Update 'this.state.module' with new values from 'Edit'
  _updateStateModule = (id) => {
    let modules = this.state.modules;

    for (let i = 0, len = modules.length; i < len; i++) {
      if (modules[i].module_id === id) {
        modules[i] = this.state.selectedModule;
        this.setState({ modules });

        break;
      }
    }
  };

  // Delete module from 'this.state.modules'
  _deleteStateModule = (id) => {
    let modules = this.state.modules;

    for (let i = 0, len = modules.length; i < len; i++) {
      if (modules[i].module_id === id) {
        modules.splice(i, 1);

        this.setState({ modules });

        break;
      }
    }
  };

  /* Submit Forms (Add, Edit, Delete) */

  // Handle Submit 'Add Module' Pop-up
  submitAddModule = (e) => {
    e.preventDefault();

    if (this._validForm(e)) {
      let formData = new FormData(e.target);

      ApiRequest.axiosInstance({
        method: "post",
        url: "/module/createModule.php",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          console.log(res);
          if (ApiRequest.callSuccess(res) && ApiRequest.callHasRecords(res)) {
            let module = this.state.selectedModule.toJson("add");

            let data = res.data.records[0];

            if (data.hasOwnProperty("MODULE_ID")) {
              module.module_id = data.MODULE_ID;
            }

            if (data.hasOwnProperty("MODULE_MODEL_PATH")) {
              module.module_model_path = data.MODULE_MODEL_PATH;
            }

            if (data.hasOwnProperty("MODULE_MODEL_THUMBNAIL_PATH")) {
              module.module_model_thumbnail_path = data.MODULE_MODEL_THUMBNAIL_PATH;
            }

            this._addStateModule(module);
            this.toggleAddModule();
          } else {
            let errors = this.state.errors;
            errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
            this.setState({
              errors: errors,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          let errors = this.state.errors;
          errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        })
        .then(() => {
          console.log("Module 'Add' Request Finished");
        });
    }
  };

  // Handle Submit 'Edit Module' Pop-up
  submitEditModule = (e) => {
    e.preventDefault();

    if (this._validForm(e, true)) {
      let formData = new FormData(e.target);
      formData.set("module_id", this.state.selectedModule.module_id);

      ApiRequest.axiosInstance({
        method: "post",
        url: "/module/updateModule.php",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          console.log(res);
          if (ApiRequest.callSuccess(res) && ApiRequest.callHasRecords(res)) {
            let module = this.state.selectedModule.toJson("edit");

            const data = res.data.records[0];

            if (data.hasOwnProperty("MODULE_ID")) {
              module.module_id = data.MODULE_ID;
            }

            if (data.hasOwnProperty("MODULE_MODEL_PATH")) {
              module.module_model_path = data.MODULE_MODEL_PATH;
            }

            if (data.hasOwnProperty("MODULE_MODEL_THUMBNAIL_PATH")) {
              module.module_model_thumbnail_path = data.MODULE_MODEL_THUMBNAIL_PATH;
            }

            this._updateStateModule(module.module_id);
            this.toggleEditModule();
          } else {
            let errors = this.state.errors;
            errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
            this.setState({
              errors: errors,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          let errors = this.state.errors;
          errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        })
        .then(() => {
          console.log("Module 'Edit' Request Finished");
        });
    }
  };

  // Handle Submit 'Copy Module' Pop-up
  submitCopyModule = (e) => {
    e.preventDefault();

    if (this._validForm(e)) {
      let formData = new FormData(e.target);

      ApiRequest.axiosInstance({
        method: "post",
        url: "/module/createModule.php",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          console.log(res);
          if (ApiRequest.callSuccess(res) && ApiRequest.callHasRecords(res)) {
            let module = this.state.selectedModule.toJson("add");

            let data = res.data.records[0];

            if (data.hasOwnProperty("MODULE_ID")) {
              module.module_id = data.MODULE_ID;
            }

            if (data.hasOwnProperty("MODULE_MODEL_PATH")) {
              module.module_model_path = data.MODULE_MODEL_PATH;
            }

            if (data.hasOwnProperty("MODULE_MODEL_THUMBNAIL_PATH")) {
              module.module_model_thumbnail_path = data.MODULE_MODEL_THUMBNAIL_PATH;
            }

            this._addStateModule(module);
            this.toggleCopyModule();
          } else {
            let errors = this.state.errors;
            errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
            this.setState({
              errors: errors,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          let errors = this.state.errors;
          errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        })
        .then(() => {
          console.log("Module 'Copy' Request Finished");
        });
    }
  };


  // Handle Submit 'Delete Module' Pop-up
  submitDeleteModule = async (e) => {
    e.preventDefault();

    let module = this.state.selectedModule;

    let data = module.toJson("delete");

    await ApiRequest.axiosInstance
      .post("/module/deleteModule.php", data)
      .then((res) => {
        if (ApiRequest.callSuccess(res)) {
          this._deleteStateModule(data.module_id);
          this.toggleDeleteModule();
        } else {
          let errors = this.state.errors;
          errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        let errors = this.state.errors;
        errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
        this.setState({
          errors: errors,
        });
      })
      .then(() => {
        console.log("Modules 'Delete' Request Finished");
      });
  };

  /* Build Table */

  // Fill Table with Data from 'this.state.materials'
  populateTable = () => {
    let modules = this.state.modules;

    let rows = [];

    for (let i = 0, len = modules.length; i < len; i++) {
      let children = [];

      children.push(<td key={i + "-name"}>{modules[i].module_name}</td>);
      children.push(<td key={i + "-size"}>{modules[i].module_size}</td>);
      children.push(<td key={i + "-model_height"}>{modules[i].module_model_height + "cm"}</td>);
      children.push(<td key={i + "-kps_name"}>{modules[i].module_kps_name}</td>);
      children.push(<td key={i + "-base_price"}>€ {formatPrice(+modules[i].module_base_price)}</td>);
      children.push(<td key={i + "-height_price_scale"}>{modules[i].module_height_price_scale}</td>);
      children.push(<td key={i + "-width_price_scale"}>{modules[i].module_width_price_scale}</td>);
      children.push(<td key={i + "-depth_price_scale"}>{modules[i].module_depth_price_scale}</td>);
      children.push(
        <td key={i + "-edit"} className='td-icon' onClick={() => this.toggleEditModule(modules[i])}>
          <FaPencilAlt />
        </td>
      );
      children.push(
        <td key={i + "-copy"} className='td-icon' onClick={() => this.toggleCopyModule(modules[i])}>
          <FaCopy />
        </td>
      );
      children.push(
        <td key={i + "-delete"} className='td-icon' onClick={() => this.toggleDeleteModule(modules[i])}>
          <FaTrashAlt />
        </td>
      );

      rows.push(
        <tr key={i} id={i}>
          {children}
        </tr>
      );
    }

    return rows;
  };

  render() {
    return (
      <div>
        <SideNavContainer />
        <Modules
          state={this.state}
          toggleAddModule={this.toggleAddModule}
          toggleEditModule={this.toggleEditModule}
          toggleCopyModule={this.toggleCopyModule}
          toggleDeleteModule={this.toggleDeleteModule}
          submitAddModule={this.submitAddModule}
          submitEditModule={this.submitEditModule}
          submitCopyModule={this.submitCopyModule}
          submitDeleteModule={this.submitDeleteModule}
          searchChange={this.searchChange}
          clearSearch={this.clearSearch}
          populateTable={this.populateTable}
          sortModules={this.sortModules}
        />
      </div>
    );
  }
}

export default ModulesContainer;
