import React from "react";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaPlus, FaCaretDown, FaCaretUp, FaTimesCircle } from "react-icons/fa";

import AddModuleModal from "./popups/modules/AddModuleModal";
import EditModuleModal from "./popups/modules/EditModuleModal";
import CopyModuleModal from "./popups/modules/CopyModuleModal";
import DeleteModuleModal from "./popups/modules/DeleteModuleModal";
import Loading from "./Loading";
import Error from "./Error";

class Modules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='main-container'>
        {this.props.state.loading ? (
          <div className='loader'>
            <Loading />
          </div>
        ) : this.props.state.loadError ? (
          <Error message={this.props.state.loadErrorMessage} />
        ) : (
          <div>
            <h1>Modules</h1>
            <hr />

            {/* Pop-up form (Add Module) */}
            <AddModuleModal addModule={this.props.state.addModule} toggleAddModule={this.props.toggleAddModule} submitAddModule={this.props.submitAddModule} baseMaterials={this.props.state.baseMaterials} formErrors={this.props.state.errors} />

            <EditModuleModal editModule={this.props.state.editModule} toggleEditModule={this.props.toggleEditModule} submitEditModule={this.props.submitEditModule} selectedModule={this.props.state.selectedModule} baseMaterials={this.props.state.baseMaterials} formErrors={this.props.state.errors} />
           
            <CopyModuleModal copyModule={this.props.state.copyModule} toggleCopyModule={this.props.toggleCopyModule} submitCopyModule={this.props.submitCopyModule} selectedModule={this.props.state.selectedModule} baseMaterials={this.props.state.baseMaterials} formErrors={this.props.state.errors} />

            {/* Pop-up form (Add Material) */}
            <DeleteModuleModal deleteModule={this.props.state.deleteModule} toggleDeleteModule={this.props.toggleDeleteModule} submitDeleteModule={this.props.submitDeleteModule} selectedModule={this.props.state.selectedModule} formErrors={this.props.state.errors} />

            <Row>
              <Col lg={{ span: 2, offset: 0 }} className='search-outer-container'>
                <div className='search-container'>
                  <Form.Control type='text' placeholder='Zoeken op module...' value={this.props.state.searchValue} onChange={(e) => this.props.searchChange(e.target.value)} />
                  <span className={this.props.state.searchValue !== "" ? "search-clear" : "hide"} onClick={this.props.clearSearch}>
                    <FaTimesCircle />
                  </span>
                </div>
              </Col>

              <Col lg={{ span: 1, offset: 9 }} className='add-btn-container'>
                <Button variant='primary' onClick={this.props.toggleAddModule}>
                  <FaPlus /> Nieuwe Module
                </Button>
              </Col>
            </Row>
            <br />
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    <span onClick={() => this.props.sortModules("module_name")}>
                      Module
                      {this.props.state.sort.module_name === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortModules("module_size")}>
                      Module Grootte (1 of 2)
                      {this.props.state.sort.module_size === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortModules("module_model_height")}>
                      Module Hoogte
                      {this.props.state.sort.module_height === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortModules("module_kps_name")}>
                      KPS Naam
                      {this.props.state.sort.module_kps_name === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortModules("module_base_price")}>
                      Standaard Prijs
                      {this.props.state.sort.module_base_price === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortModules("module_height_price_scale")}>
                      Prijs Factor (Hoogte)
                      {this.props.state.sort.module_height_price_scale === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortModules("module_width_price_scale")}>
                      Prijs Factor (Breedte)
                      {this.props.state.sort.module_width_price_scale === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortModules("module_depth_price_scale")}>
                      Prijs Factor (Diepte)
                      {this.props.state.sort.module_depth_price_scale === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th className='th-icon'>Aanpassen</th>
                  <th className='th-icon'>Kopiëren</th>
                  <th className='th-icon'>Verwijderen</th>
                </tr>
              </thead>
              <tbody>{this.props.populateTable()}</tbody>
            </Table>
          </div>
        )}
      </div>
    );
  }
}

export default Modules;
