import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import CreatableSelect from 'react-select/creatable';

class ViewOrderModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [
        { value: "Nieuw", label: "Nieuw" },
        { value: "Gedownload", label: "Gedownload" },
        { value: "Afgewerkt", label: "Afgewerkt" },
        { value: "Afgeleverd", label: "Afgeleverd" },
      ]
    };
  }

  componentDidMount() {
    let options = [
      { value: "Nieuw", label: "Nieuw" },
      { value: "Gedownload", label: "Gedownload" },
      { value: "Afgewerkt", label: "Afgewerkt" },
      { value: "Afgeleverd", label: "Afgeleverd" },
    ]

    this.setState({ options: options })
  }

  _setHandlingStatus = e => {
    console.log(e)
    if (e === null)
      this.props.setHandlingStatus(this.props.selectedOrder, "");
    else
      this.props.setHandlingStatus(this.props.selectedOrder, e.value);

  }

  // Build Footer (Pagination ONLY IF MORE THAN 1 DOCUMENT)
  _buildFooter = () => {
    let footer = [];

    let documentLength = this.props.selectedOrder.order_JSON.Documents.length;

    if (documentLength > 1) {
      footer.push(
        <Modal.Footer key='footer' className='modal-footer-order'>
          <Button onClick={this.props.decrementDocument} style={this.props.currentDocument > 0 ? { visibility: "visible" } : { visibility: "hidden" }}>
            Vorige
          </Button>
          <Button onClick={this.props.incrementDocument} style={this.props.currentDocument < documentLength - 1 ? { visibility: "visible" } : { visibility: "hidden" }}>
            Volgende
          </Button>
        </Modal.Footer>
      );
    }

    return footer;
  };

  _buildOrder = () => {
    let orderItems = [];

    if (this._orderHasDocuments()) {
      let order = this.props.selectedOrder.order_JSON.Documents;

      const orderDate = this.props.selectedOrder.order_date;
      const orderPaymentMethod = this.props.selectedOrder.order_payment_method;
      const orderPaymentStatus = this.props.selectedOrder.order_payment_status;
      const orderHandlingStatus = this.props.selectedOrder.order_handling_status;

      const deliveryAddress = this.props.selectedOrder.getFormattedAddress();

      let i = this.props.currentDocument;

      // Algemeen - Strings

      const file = order[i].File;
      const xSize = order[i].Xsize;
      const ySize = order[i].ReplaceVariables.find(item => item.VariableName === "KAST_DIEPTE").NewValue;
      const zSize = order[i].Zsize;
      const client = order[i].Client !== undefined ? order[i].Client : order[0].Client;
      const commision = order[i].Commision !== undefined ? order[i].Commision : order[0].Commision;

      // Items - Arrays (of Strings)
      const replaceMaterials = order[i].ReplaceMaterials;
      const replaceModules = order[i].ReplaceObjects;
      const replaceFeatures = order[i].ReplaceMount;
      const replaceCoatingMaterials = order[i].ReplaceCoatingMaterials;
      const replaceVariables = order[i].ReplaceVariables;

      // Algemene Items
      orderItems.push(
        <p key={i + "-download"}>
          <Button onClick={this._downloadOrderJSON}>Download JSON</Button>
        </p>
      )

      orderItems.push(
        <p key={i + "-edit-status"}>
          <span className='order-subtitle'>Verwerkingsstatus: </span>
          <CreatableSelect isClearable onChange={this._setHandlingStatus} options={this.state.options}></CreatableSelect>
        </p>
      )


      orderItems.push(
        <p key={i + "-client"}>
          <span className='order-subtitle'>Klant: </span>
          {client}
        </p>
      );
      orderItems.push(
        <p key={i + "-commision"}>
          <span className='order-subtitle'>Commissie: </span>
          {commision}
        </p>
      );

      const dateOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      orderItems.push(
        <p key={i + "-orderDate"}>
          <span className='order-subtitle'>Besteldatum: </span>
          {orderDate != null ? orderDate.toLocaleDateString("nl-NL", dateOptions) : ""}
        </p>
      );
      orderItems.push(
        <p key={i + "-orderPaymentMethod"}>
          <span className='order-subtitle'>Betaalmethode: </span>
          {orderPaymentMethod}
        </p>
      );
      orderItems.push(
        <p key={i + "-orderPaymentStatus"}>
          <span className='order-subtitle'>Betaalstatus: </span>
          {orderPaymentStatus}
        </p>
      );
      orderItems.push(
        <p key={i + "-deliveryAddress"}>
          <span className='order-subtitle'>Leveringsadres: </span>
          {deliveryAddress}
        </p>
      );

      orderItems.push(<hr key={i + "-hr"} />);
      orderItems.push(
        <p key={i + "-file"}>
          <span className='order-subtitle'>Bestand: </span>
          {file}
        </p>
      );
      orderItems.push(
        <p key={i + "-ySize"}>
          <span className='order-subtitle'>Hoogte: </span>
          {zSize}
        </p>
      );
      orderItems.push(
        <p key={i + "-xSize"}>
          <span className='order-subtitle'>Breedte: </span>
          {xSize}
        </p>
      );
      orderItems.push(
        <p key={i + "-zSize"}>
          <span className='order-subtitle'>Diepte: </span>
          {ySize}
        </p>
      );
      orderItems.push(<br key={i + "-br"} />);

      // Materials Table
      if (replaceMaterials !== undefined && replaceMaterials.length > 0) {
        let materialTable = [];
        for (let j = 0, len = replaceMaterials.length; j < len; j++) {
          materialTable.push(
            <tr key={i + "-" + j + "-material"}>
              <td>{replaceMaterials[j].NewValue}</td>
            </tr>
          );
        }

        orderItems.push(
          <Table key={i + "-material-table"} responsive bordered hover>
            <thead className='thead-light'>
              <tr>
                <th>Materialen</th>
              </tr>
            </thead>
            <tbody>{materialTable}</tbody>
          </Table>
        );
      }

      // Modules Table
      if (replaceModules !== undefined && replaceModules.length > 0) {
        let moduleTable = [];

        for (let j = 0, len = replaceModules.length; j < len; j++) {
          moduleTable.push(
            <tr key={i + "-" + j + "-module"}>
              <td>{replaceModules[j].NewValue}</td>
            </tr>
          );
        }

        orderItems.push(
          <Table key={i + "-module-table"} responsive bordered hover>
            <thead className='thead-light'>
              <tr>
                <th>Modules</th>
              </tr>
            </thead>
            <tbody>{moduleTable}</tbody>
          </Table>
        );
      }

      // Features Table
      if (replaceFeatures !== undefined && replaceFeatures.length > 0) {
        let featureTable = [];

        for (let j = 0, len = replaceFeatures.length; j < len; j++) {
          featureTable.push(
            <tr key={i + "-" + j + "-feature"}>
              <td>{replaceFeatures[j].NewValue}</td>
            </tr>
          );
        }

        orderItems.push(
          <Table key={i + "-feature-table"} responsive bordered hover>
            <thead className='thead-light'>
              <tr>
                <th>Features</th>
              </tr>
            </thead>
            <tbody>{featureTable}</tbody>
          </Table>
        );
      }

      // Coating Table
      if (replaceCoatingMaterials !== undefined && replaceCoatingMaterials.length > 0) {
        let coatingTable = [];

        for (let j = 0, len = replaceCoatingMaterials.length; j < len; j++) {
          coatingTable.push(
            <tr key={i + "-" + j + "-coating"}>
              <td>{replaceCoatingMaterials[j].NewValue}</td>
            </tr>
          );
        }

        orderItems.push(
          <Table key={i + "-coating-table"} responsive bordered hover>
            <thead className='thead-light'>
              <tr>
                <th>Afplakken</th>
              </tr>
            </thead>
            <tbody>{coatingTable}</tbody>
          </Table>
        );
      }

      if (replaceVariables !== undefined && replaceVariables.length > 0) {
        let variableTable = [];

        for (let j = 0, len = replaceVariables.length; j < len; j++) {
          variableTable.push(
            <tr key={i + "-" + j + "-variable"}>
              <td>{replaceVariables[j].VariableName}</td>
              <td>{replaceVariables[j].NewValue}</td>
            </tr>
          );
        }

        orderItems.push(
          <Table key={i + "-variable-table"} responsive bordered hover>
            <thead className='thead-light'>
              <tr>
                <th>Variabel Naam</th>
                <th>Waarde</th>
              </tr>
            </thead>
            <tbody>{variableTable}</tbody>
          </Table>
        );
      }
    }

    return orderItems;
  };

  _orderExists() {
    if (this.props.selectedOrder.order_JSON && this.props.selectedOrder.order_JSON !== undefined) {
      return true;
    }

    return false;
  }

  _orderHasDocuments() {
    if (this._orderExists()) {
      if (this.props.selectedOrder.order_JSON.Documents && this.props.selectedOrder.order_JSON.Documents.length > 0) {
        return true;
      }
    }

    return false;
  }

  _downloadOrderJSON = () => {
    let fileContents = this.props.selectedOrder.order_JSON;
    let fileName = "KPWJSON_" + this.props.selectedOrder.order_id;

    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(fileContents));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", fileName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  render() {
    return (
      <Modal show={this.props.viewOrder} onHide={this.props.toggleViewOrder}>
        <Modal.Header closeButton>
          <Modal.Title>
            Bestelling #{this.props.selectedOrder.order_id}
            {this._orderHasDocuments() ? (
              <div>
                <small>
                  Document {this.props.currentDocument + 1}/{this.props.selectedOrder.order_JSON.Documents.length}
                </small>
              </div>
            ) : (
              ""
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{!this._orderExists() ? "Bestelling kon niet geladen worden of is leeg" : this._buildOrder()}</Modal.Body>
        {this._orderHasDocuments() ? this._buildFooter() : ""}
      </Modal>
    );
  }
}

export default ViewOrderModal;
