import React from "react";
import SideNavContainer from "../components/SideNavContainer";
import Helps from "../components/Helps";

import Help from "../models/Help";

import { FaBookOpen, FaPencilAlt, FaTrashAlt } from "react-icons/fa";

import ApiRequest from "../services/ApiRequest";

class HelpsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadError: false,
      loadErrorMessage: [],
      /* Toggle Popup */
      addHelp: false, // Toggle AddHelpPopup
      editHelp: false, // Toggle EditHelpPopup
      exampleHelp: false, // Toggle ExampleHelpPopup
      deleteHelp: false, // Toggle DeleteHelpPopup

      /* Form */
      errors: {}, // Form Errors
      selectedHelp: new Help(), // Handle onChange of FormElements (Delete)

      /* Search + Sort */
      sort: {
        help_step_id: "desc",
        help_gif_path: "desc",
        help_text: "desc",
      },
      searchValue: "", //Search Value

      /* Helps */
      backupHelps: [], //Used for filtering
      helps: [],
    };
  }

  // On mount, get all data from database (Helps + HelpTypes)
  async componentDidMount() {
    let loadError = false;
    let loadErrorMessage = [];

    await ApiRequest.axiosInstance
      .post("/help/readHelp.php")
      .then((res) => {
        if (ApiRequest.callSuccess(res)) {
          if (ApiRequest.callHasRecords(res)) {
            let helps = [];
            for (let i = 0, len = res.data.records.length; i < len; i++) {
              let help = new Help();
              help.setDataFromObject(res.data.records[i]);
              helps.push(help);
            }

            helps.sort((a, b) => (a.help_step_id > b.help_step_id ? 1 : -1));

            this.setState({
              helps: helps,
            });
          }
        } else if (ApiRequest.callNoResults(res)) {
          this.setState({
            helps: [],
          });
        } else {
          loadError = true;
          if (res.data.message !== undefined) {
            loadErrorMessage.push(res.data.message);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        loadError = true;
        if (err.response.data.messsage !== undefined) {
          loadErrorMessage.push(err.response.data.message);
        }
      })
      .then(() => {
        console.log("Helps 'Read' Request Finished");
        setTimeout(() => {
          this.setState({
            loading: false,
            loadError: loadError,
            loadErrorMessage: loadErrorMessage,
          });
        }, 500);
      });
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  /* Search & Sort Functions */

  // Handle Changes in 'Live Search' of Table (Search Box) + Update Table (Hide Helps that Don't Match Criteria)
  searchChange = (value) => {
    this.setState({ searchValue: value });

    let backupHelps = this.state.backupHelps;

    if (backupHelps.length === 0) {
      backupHelps = [...this.state.helps];
      this.setState({ backupHelps: backupHelps });
    }

    let helps = [];

    for (let i = 0, len = backupHelps.length; i < len; i++) {
      if (backupHelps[i].help_text.toLowerCase().includes(value.toLowerCase())) {
        helps.push(backupHelps[i]);
      }
    }

    this.setState({ helps: helps });
  };

  // Clear 'Live Search' (Search Box) + Update Table (Display all Helps)
  clearSearch = () => {
    this.setState({ searchValue: "", helps: this.state.backupHelps });
  };

  // Ascending/Descending Sort Helps (onClick 'th')
  sortHelps = (key) => {
    let helps = this.state.helps;
    let sort = this.state.sort;

    if (this.state.sort[key] === "asc") {
      sort[key] = "desc";
      this.setState({ sort });
      helps.sort((a, b) => (a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1));
    } else {
      sort[key] = "asc";
      this.setState({ sort });
      helps.sort((a, b) => (a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1));
    }

    this.setState({ helps });
  };

  /* Form functions */

  // Validate Form (Called on Submit of Add & Edit) + Create Error Messages if Needed
  _validForm = (e, isEdit = false) => {
    let selectedHelp = this.state.selectedHelp;
    let errors = {};
    let formIsValid = true;

    let formData = new FormData(e.target);

    let help_step_id = formData.get("help_step_id");
    let help_gif = formData.get("help_gif");
    let help_text = formData.get("help_text");

    if (help_step_id !== "") {
      help_step_id = +help_step_id;
      if (help_step_id >= 0)
        selectedHelp.help_step_id = help_step_id;
      else
        errors["help_step_id"] = "Stap ID moet een getal groter of gelijk aan 0 zijn";
    } else {
      errors["help_step_id"] = "Stap ID is verplicht";
    }

    if (help_gif.name !== "") {
      selectedHelp.help_gif_path = help_gif;
    } else if (isEdit) {
      selectedHelp["help_gif"] = null;
    } else {
      errors["help_gif"] = "Model uploaden is verplicht";
    }

    if (help_text !== "") {
      if (help_text.length > 500)
        errors["help_text"] = "Hulptekst kan maximaal 500 karakters bevatten. Huidige lengte: " + help_text.length;
      else
        selectedHelp.help_text = help_text;
    } else {
      errors["help_text"] = "Hulptekst is verplicht";
    }

    if (Object.keys(errors).length > 0) {
      formIsValid = false;
    }

    this.setState({ errors: errors, selectedHelp: selectedHelp });

    return formIsValid;
  };

  /* Popups (Add, Edit, Delete) */

  // Show/Hide 'Add Help' Pop-up + Clear all 'Changs' from 'fields' and 'errors'
  toggleAddHelp = () => {
    this.setState({
      selectedHelp: new Help(),
      errors: {},
      addHelp: !this.state.addHelp,
    });
  };

  // Show/Hide 'Edit Help' Pop-up + Clear 'errors' + set 'fields' (Required for Bypassing Validation when Submitting without Changing Anything)
  // + set 'selectedHelp' (Required for Changing Values without updating the state) ==> If this is not used then when user cancels input, it still displays the changed values in the table
  toggleExampleHelp = (help) => {
    this.setState({
      errors: {},
      exampleHelp: !this.state.exampleHelp,
      selectedHelp: help !== undefined ? Object.assign(Object.create(Object.getPrototypeOf(help)), help) : this.state.selectedHelp,
    });
  };

  // Show/Hide 'Edit Help' Pop-up + Clear 'errors' + set 'fields' (Required for Bypassing Validation when Submitting without Changing Anything)
  // + set 'selectedHelp' (Required for Changing Values without updating the state) ==> If this is not used then when user cancels input, it still displays the changed values in the table
  toggleEditHelp = (help) => {
    this.setState({
      errors: {},
      editHelp: !this.state.editHelp,
      selectedHelp: help !== undefined ? Object.assign(Object.create(Object.getPrototypeOf(help)), help) : this.state.selectedHelp,
    });
  };

  // Show/Hide 'Delete Help' Pop-up + Set 'fields' (Used for Displaying 'Help_name' + having the 'id' )
  // NOTE: Errors are not cleared, because we clear them on all others and delete doesn't have any errors linked to it as the only errors that could occur is something with the backend: eg. invalid ID,...
  toggleDeleteHelp = (help) => {
    this.setState({
      deleteHelp: !this.state.deleteHelp,
      selectedHelp: help !== undefined ? help : this.state.selectedHelp,
    });
  };

  /* Update State on Successful HTTP Request */

  // Add Help to 'this.state.Helps'
  _addStateHelp = (help) => {
    let helps = this.state.helps;
    helps.push(help);
    this.setState({ helps });
  };

  // Update 'this.state.Helps' with new values from 'Edit'
  _updateStateHelp = (id) => {
    let helps = this.state.helps;

    for (let i = 0, len = helps.length; i < len; i++) {
      if (helps[i].help_step_id === id) {
        helps[i] = this.state.selectedHelp;
        this.setState({ helps });
        break;
      }
    }
  };

  // Delete Help from 'this.state.featurs'
  _deleteStateHelp = (id) => {
    let helps = this.state.helps;

    for (let i = 0; i < helps.length; i++) {
      if (helps[i].help_step_id === id) {
        helps.splice(i, 1);
        this.setState({ helps });
        break;
      }
    }
  };

  /* Submit Forms (Add, Edit, Delete) */

  // Handle Submit 'Add Help' Pop-up
  submitAddHelp = (e) => {
    e.preventDefault();

    if (this._validForm(e)) {
      let formData = new FormData(e.target);

      ApiRequest.axiosInstance({
        method: "post",
        url: "/help/createHelp.php",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          console.log(res);

          if (ApiRequest.callSuccess(res) && ApiRequest.callHasRecords(res)) {
            let help = this.state.selectedHelp.toJson("add");

            let data = res.data.records[0];

            if (data.hasOwnProperty("HELP_STEP_ID")) {
              help.help_step_id = data.HELP_STEP_ID;
            }

            if (data.hasOwnProperty("HELP_GIF_PATH")) {
              help.help_gif_path = data.HELP_GIF_PATH;
            }

            this._addStateHelp(help);
            this.toggleAddHelp();
          } else {
            let errors = this.state.errors;
            errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
            this.setState({
              errors: errors,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          let errors = this.state.errors;
          errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        })
        .then(() => {
          console.log("Help 'Add' Request Finished");
        });
    }
  };

  // Handle Submit 'Edit Help' Pop-up
  submitEditHelp = (e) => {
    e.preventDefault();

    if (this._validForm(e, true)) {
      let formData = new FormData(e.target);
      formData.set("help_step_id", this.state.selectedHelp.help_step_id);

      ApiRequest.axiosInstance({
        method: "post",
        url: "/help/updateHelp.php",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          console.log(res);

          if (ApiRequest.callSuccess(res) && ApiRequest.callHasRecords(res)) {
            let help = this.state.selectedHelp.toJson("edit");

            let data = res.data.records[0];

            if (data.hasOwnProperty("HELP_STEP_ID")) {
              help.help_step_id = data.HELP_STEP_ID;
            }

            if (data.hasOwnProperty("HELP_GIF_PATH")) {
              help.help_gif_path = data.HELP_GIF_PATH;
            }

            this._updateStateHelp(help.help_step_id);
            this.toggleEditHelp();
          } else {
            let errors = this.state.errors;
            errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
            this.setState({
              errors: errors,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          let errors = this.state.errors;
          errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        })
        .then(() => {
          console.log("Help 'Edit' Request Finished");
        });
    }
  };

  // Handle Submit 'Delete Help' Pop-up
  submitDeleteHelp = async (e) => {
    e.preventDefault();

    let help = this.state.selectedHelp;

    let data = help.toJson("delete");

    await ApiRequest.axiosInstance
      .post("/help/deleteHelp.php", data)
      .then((res) => {
        if (ApiRequest.callSuccess(res)) {
          this._deleteStateHelp(data.help_step_id);
          this.toggleDeleteHelp();
        } else {
          let errors = this.state.errors;
          errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        let errors = this.state.errors;
        errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
        this.setState({
          errors: errors,
        });
      })
      .then(() => {
        console.log("Helps 'Delete' Request Finished");
      });
  };

  /* Build Table */

  // Fill Table with Data from 'this.state.helps'
  populateTable = () => {
    let helps = this.state.helps;

    let rows = [];

    for (let i = 0, len = helps.length; i < len; i++) {
      let children = [];

      children.push(<td key={i + "-step-id"}>{helps[i].help_step_id}</td>);
      children.push(<td key={i + "-gif-path"}>{helps[i].help_gif_path}</td>);
      children.push(<td key={i + "-text"}>{helps[i].help_text}</td>);
      children.push(
        <td key={i + "-example"} className='td-icon' onClick={() => this.toggleExampleHelp(helps[i])}>
          <FaBookOpen />
        </td>
      );
      children.push(
        <td key={i + "-edit"} className='td-icon' onClick={() => this.toggleEditHelp(helps[i])}>
          <FaPencilAlt />
        </td>
      );
      children.push(
        <td key={i + "-delete"} className='td-icon' onClick={() => this.toggleDeleteHelp(helps[i])}>
          <FaTrashAlt />
        </td>
      );

      rows.push(
        <tr key={i} id={i}>
          {children}
        </tr>
      );
    }

    return rows;
  };

  render() {
    return (
      <div>
        <SideNavContainer />
        <Helps
          state={this.state}
          toggleAddHelp={this.toggleAddHelp}
          toggleEditHelp={this.toggleEditHelp}
          toggleExampleHelp={this.toggleExampleHelp}
          toggleDeleteHelp={this.toggleDeleteHelp}
          submitAddHelp={this.submitAddHelp}
          submitEditHelp={this.submitEditHelp}
          submitDeleteHelp={this.submitDeleteHelp}
          searchChange={this.searchChange}
          clearSearch={this.clearSearch}
          populateTable={this.populateTable}
          sortHelps={this.sortHelps}
        />
      </div>
    );
  }
}

export default HelpsContainer;
