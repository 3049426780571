import React from "react";
import { Link } from "react-router-dom";

import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";

import { Redirect } from "react-router";

import logo from "../images/vlecad-logo.png";
import { FiX, FiMenu } from "react-icons/fi";

export default class SideNavContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: window.sessionStorage.getItem("isLoggedIn"),
      sidebarActive: true,
    };
  }

  doLogout = () => {
    // Logout here
    sessionStorage.removeItem("isLoggedIn");

    window.location.href = "/";
  };

  toggleSidebar = (e) => {
    const sidebar = document.querySelector(".side-bar");
    const mainContainer = document.querySelector(".main-container");

    sidebar.classList.toggle("side-bar-hidden");
    mainContainer.classList.toggle("main-container-expanded");

    this.setState({
      sidebarActive: !this.state.sidebarActive,
    });
  };

  render() {
    if (!this.state.isLoggedIn) {
      return <Redirect to='/' />;
    }

    // 'hack' to deal with browser clipping of items because the browser tabs are part of vh
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // To deal with resizes like for example: screen rotation on mobile devices
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    return (
      <div className='side-bar background-blue height-100 noselect'>
        <button onClick={this.toggleSidebar}>{this.state.sidebarActive ? <FiX /> : <FiMenu />}</button>
        <div className='side-bar-img-container'>
          <Link to='/dashboard'>
            <Image src={logo} className='side-bar-logo nodrag' />
          </Link>
        </div>
        <ListGroup>
          <Link to='/dashboard'>
            <ListGroup.Item className='background-blue text-left' as='li'>
              Dashboard
            </ListGroup.Item>
          </Link>

          <Link to='/materials'>
            <ListGroup.Item className='background-blue text-left' as='li'>
              Materialen
            </ListGroup.Item>
          </Link>

          <Link to='/modules'>
            <ListGroup.Item className='background-blue text-left' as='li'>
              Modules
            </ListGroup.Item>
          </Link>

          <Link to='/room'>
            <ListGroup.Item className='background-blue text-left' as='li'>
              Kamer
            </ListGroup.Item>
          </Link>


          <Link to='/features'>
            <ListGroup.Item className='background-blue text-left' as='li'>
              Features
            </ListGroup.Item>
          </Link>

          <Link to='/customers'>
            <ListGroup.Item className='background-blue text-left' as='li'>
              Klanten
            </ListGroup.Item>
          </Link>

          <Link to='/orders'>
            <ListGroup.Item className='background-blue text-left' as='li'>
              Bestellingen
            </ListGroup.Item>
          </Link>

          <Link to='/helps'>
            <ListGroup.Item className='background-blue text-left border-bottom' as='li'>
              Hulpvensters
            </ListGroup.Item>
          </Link>

          <Link to='/settings'>
            <ListGroup.Item className='background-blue text-left border-bottom' as='li'>
              Instellingen
            </ListGroup.Item>
          </Link>

          <ListGroup.Item className='background-blue text-left' onClick={this.doLogout} as='li'>
            Uitloggen
          </ListGroup.Item>
        </ListGroup>
      </div>
    );
  }
}
