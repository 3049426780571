import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import App from "./App";
import DashboardContainer from "./pages/DashboardContainer";
import MaterialsContainer from "./pages/MaterialsContainer";
import ModulesContainer from "./pages/ModulesContainer";
import RoomContainer from "./pages/RoomContainer";
import OrdersContainer from "./pages/OrdersContainer";
import CustomersContainer from "./pages/CustomersContainer";
import FeaturesContainer from "./pages/FeaturesContainer";
import SettingsContainer from "./pages/SettingsContainer";
import HelpsContainer from "./pages/HelpsContainer";
import NotFound from "./components/NotFound";

ReactDOM.render(
  <Router basename='/Mintjens/KPWD'>
    <Switch>
      <Route exact path='/' component={App} />
      <Route path='/dashboard' component={DashboardContainer} />
      <Route path='/materials' component={MaterialsContainer} />
      <Route path='/modules' component={ModulesContainer} />
      <Route path='/room' component={RoomContainer} />
      <Route path='/orders' component={OrdersContainer} />
      <Route path='/customers' component={CustomersContainer} />
      <Route path='/features' component={FeaturesContainer} />
      <Route path='/settings' component={SettingsContainer} />
      <Route path='/helps' component={HelpsContainer} />
      <Route component={NotFound} />
    </Switch>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
