import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { BsQuestionCircleFill } from "react-icons/bs";

function ModuleNameTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Naam voor de module</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function ModuleDescriptionTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Teskt die in de popover in de configurator getoond wordt.</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function ModuleSizeTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Aantal posities dat de module opneemt in de editor</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function ModuleKorpusNameTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Naam voor het korpus bestand</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function ModuleKorpusFileTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Locatie van het korpus bestand. bv: 'C:\Users\Gebruiker\Desktop\bestand.kps</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function ModuleModelFileTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Model bestand voor de module (.GLFT, .GLB)</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function ModuleModelHeightTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Hoogte van het model (in mm)</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function ModuleThumbnailFileTooltip() {
  return (
    <OverlayTrigger
      placement='right'
      overlay={
        <Tooltip>
          Thumbnail voor de module
          <br />
          (.jpg, .jpeg, .png,...)
        </Tooltip>
      }>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function ModuleBaseMaterialTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Basis materiaal voor de module</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function ModuleBasePriceTooltip() {
  return (
    <OverlayTrigger placement='right' overlay={<Tooltip>Standaard prijs voor de module</Tooltip>}>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

function ModulePriceScaleTooltip() {
  return (
    <OverlayTrigger
      placement='right'
      overlay={
        <Tooltip>
          Schaling voor prijs
          <br /> 1 = geen verschil in prijs
          <br /> &gt; 1 = hogere prijs
        </Tooltip>
      }>
      <BsQuestionCircleFill />
    </OverlayTrigger>
  );
}

export { ModuleNameTooltip, ModuleDescriptionTooltip, ModuleSizeTooltip, ModuleKorpusNameTooltip, ModuleKorpusFileTooltip, ModuleModelFileTooltip, ModuleModelHeightTooltip, ModuleThumbnailFileTooltip, ModuleBaseMaterialTooltip, ModuleBasePriceTooltip, ModulePriceScaleTooltip };
