import React from "react";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaPlus, FaTimesCircle, FaCaretUp, FaCaretDown } from "react-icons/fa";

import Loading from "./Loading";
import Error from "./Error";

import AddCustomerModal from "./popups/customers/AddCustomerModal";
import EditCustomerModal from "./popups/customers/EditCustomerModal";
import DeleteCustomerModal from "./popups/customers/DeleteCustomerModal";

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    console.log(this.props)
    return (
      <div className='main-container'>
        {this.props.state.loading ? (
          <div className='loader'>
            <Loading />
          </div>
        ) : this.props.state.loadError ? (
          <Error message={this.props.state.loadErrorMessage} />
        ) : (
          <div>
            <h1>Klanten</h1>
            <hr />

            <AddCustomerModal addCustomer={this.props.state.addCustomer} toggleAddCustomer={this.props.toggleAddCustomer} submitAddCustomer={this.props.submitAddCustomer} formErrors={this.props.state.errors} countries={this.props.state.countries} />

            <EditCustomerModal
              editCustomer={this.props.state.editCustomer}
              toggleEditCustomer={this.props.toggleEditCustomer}
              submitEditCustomer={this.props.submitEditCustomer}
              selectedCustomer={this.props.state.selectedCustomer}
              formErrors={this.props.state.errors}
              countries={this.props.state.countries}
            />

            <DeleteCustomerModal deleteCustomer={this.props.state.deleteCustomer} toggleDeleteCustomer={this.props.toggleDeleteCustomer} submitDeleteCustomer={this.props.submitDeleteCustomer} selectedCustomer={this.props.state.selectedCustomer} formErrors={this.props.state.errors} />

            <Row>
              <Col lg={{ span: 2, offset: 0 }} className='search-outer-container'>
                <div className='search-container'>
                  <Form.Control type='text' placeholder='Zoeken op naam...' value={this.props.state.searchValue} onChange={(e) => this.props.searchChange(e.target.value)} />
                  <span className={this.props.state.searchValue !== "" ? "search-clear" : "hide"} onClick={this.props.clearSearch}>
                    <FaTimesCircle />
                  </span>
                </div>
              </Col>

              <Col lg={{ span: 1, offset: 9 }} className='add-btn-container'>
                <Button variant='primary' onClick={this.props.toggleAddCustomer}>
                  <FaPlus /> Nieuwe Klant
                </Button>
              </Col>
            </Row>
            <br />
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>
                    <span onClick={() => this.props.sortCustomers("customer_name")}>
                      Naam
                      {this.props.state.sort.customer_name === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortCustomers("customer_email")}>
                      Email
                      {this.props.state.sort.customer_email === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortCustomers("customer_address")}>
                      Adres
                      {this.props.state.sort.customer_address === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortCustomers("customer_btw")}>
                      BTW-nummer
                      {this.props.state.sort.customer_btw === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th className='th-icon'>Aanpassen</th>
                  <th className='th-icon'>Verwijderen</th>
                </tr>
              </thead>
              <tbody>{this.props.populateTable()}</tbody>
            </Table>
          </div>
        )}
      </div>
    );
  }
}

export default Customers;
