export default class FeatureType {
  constructor(feature_type_id = "", feature_type_name = "") {
    this.feature_type_id = feature_type_id;
    this.feature_type_name = feature_type_name;
  }

  get feature_type_id() {
    return this._feature_type_id;
  }

  set feature_type_id(id) {
    this._feature_type_id = id !== undefined ? id : "";
  }

  get feature_type_name() {
    return this._feature_type_name;
  }

  set feature_type_name(name) {
    this._feature_type_name = name !== undefined ? name : "";
  }

  setDataFromObject(obj) {
    Object.assign(this, obj);
  }
}
