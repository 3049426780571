export default class Room {
  // Constructor
  constructor(
    room_id = "",
    room_description = "",
    room_type = "",
    room_thumbnail_path = ""
  ) {
    this.room_id = room_id;
    this.room_description = room_description;
    this.room_type = room_type;
    this.room_thumbnail_path = room_thumbnail_path;
  }

  // Getters & Setters
  get room_id() {
    return this._room_id;
  }

  set room_id(id) {
    this._room_id = id !== undefined ? id : "";
  }

  get room_description() {
    return this._room_description;
  }

  set room_description(description) {
    this._room_description = description !== undefined ? description : "";
  }

  get room_type() {
    return this._room_type;
  }

  set room_type(type) {
    this._room_type = type !== undefined ? type : "";
  }

  get room_thumbnail_path() {
    return this._room_thumbnail_path;
  }

  set room_thumbnail_path(path) {
    this._room_thumbnail_path = path !== undefined ? path : "";
  }

  setDataFromObject(obj) {
    Object.assign(this, obj);
  }

  toJson(type) {
    let json = {};

    switch (type.toLowerCase()) {
      case "add":
        json = {
          room_description: this.room_description,
          room_type: this.room_type,
          room_thumbnail: this.room_thumbnail_path,
        };
        break;
      case "edit":
        json = {
          room_id: this.room_id,
          room_description: this.room_description,
          room_type: this.room_type,
          room_thumbnail: this.room_thumbnail_path,
        };
        break;
      case "delete":
        json = {
          room_id: this.room_id,
        };
        break;
      default:
        break;
    }

    return json;
  }

}
