import React from "react";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Loader from "./Loading";
import Error from "./Error";

import { FaPlus, FaTimesCircle, FaCaretDown, FaCaretUp } from "react-icons/fa";

// import "../css/default.css";
import "../css/style.css";

import AddMaterialModal from "./popups/materials/AddMaterialModal";
import EditMaterialModal from "./popups/materials/EditMaterialModal";
import DeleteMaterialModal from "./popups/materials/DeleteMaterialModal";

class Materials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className='main-container'>
        {this.props.state.loading ? (
          <div className='loader'>
            <Loader />
          </div>
        ) : this.props.state.loadError ? (
          <Error message={this.props.state.loadErrorMessage} />
        ) : (
          <div>
            <h1>Materialen</h1>
            <hr />

            {/* Pop-up form (Add Material) */}
            <AddMaterialModal addMaterial={this.props.state.addMaterial} toggleAddMaterial={this.props.toggleAddMaterial} submitAddMaterial={this.props.submitAddMaterial} formErrors={this.props.state.errors} />

            {/* Pop-up form (Edit Material) */}
            <EditMaterialModal editMaterial={this.props.state.editMaterial} toggleEditMaterial={this.props.toggleEditMaterial} submitEditMaterial={this.props.submitEditMaterial} selectedMaterial={this.props.state.selectedMaterial} formErrors={this.props.state.errors} />

            {/* Pop-up form (Delete Material) */}
            <DeleteMaterialModal deleteMaterial={this.props.state.deleteMaterial} toggleDeleteMaterial={this.props.toggleDeleteMaterial} submitDeleteMaterial={this.props.submitDeleteMaterial} selectedMaterial={this.props.state.selectedMaterial} formErrors={this.props.state.errors} />

            <Row>
              <Col lg={{ span: 2, offset: 0 }} className='search-outer-container'>
                <div className='search-container'>
                  <Form.Control type='text' placeholder='Zoeken op materiaal...' value={this.props.state.searchValue} onChange={(e) => this.props.searchChange(e.target.value)} />
                  <span className={this.props.state.searchValue !== "" ? "search-clear" : "hide"} onClick={this.props.clearSearch}>
                    <FaTimesCircle />
                  </span>
                </div>
              </Col>

              <Col lg={{ span: 1, offset: 9 }} className='add-btn-container'>
                <Button variant='primary' onClick={this.props.toggleAddMaterial}>
                  <FaPlus /> Nieuw Materiaal
                </Button>
              </Col>
            </Row>

            <br />
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                <th>
                    <span onClick={() => this.props.sortMaterials("material_base_name")}>
                      Materiaalgroep naam
                      {this.props.state.sort.material_base_name === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortMaterials("material_real_name")}>
                      Reële Materiaalnaam
                      {this.props.state.sort.material_real_name === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortMaterials("material_replace_variable")}>
                      Vervang Materiaal
                      {this.props.state.sort.material_replace_variable === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortMaterials("material_type")}>
                      Materiaal Type
                      {this.props.state.sort.material_type === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th>
                    <span onClick={() => this.props.sortMaterials("material_price_scale")}>
                      Prijs factor
                      {this.props.state.sort.material_price_scale === "desc" ? <FaCaretDown /> : <FaCaretUp />}
                    </span>
                  </th>
                  <th className='th-icon'>Aanpassen</th>
                  <th className='th-icon'>Verwijderen</th>
                </tr>
              </thead>
              <tbody>{this.props.populateTable()}</tbody>
            </Table>
          </div>
        )}
      </div>
    );
  }
}

export default Materials;
