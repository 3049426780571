import React from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import { CustomerNameTooltip, CustomerEmailTooltip, CustomerAddressTooltip, CustomerBtwTooltip } from "./../../tooltips/CustomerTooltips";

import { FaEye, FaEyeSlash } from "react-icons/fa";
import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi";

class EditCustomerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidePassword: true,
    };
  }

  _generatePassword = (e) => {
    e.preventDefault();

    const length = 10;

    let chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
    let password = "";
    for (let x = 0; x < length; x++) {
      let i = Math.floor(Math.random() * chars.length);
      password += chars.charAt(i);
    }

    document.getElementsByName("customer_password")[0].value = password;
  };

  _buildCountriesDropdown = () => {
    let countries = this.props.countries;

    let options = [];
    for (let i = 0, len = countries.length; i < len; i++) {
      options.push(
        <option key={i} value={countries[i].country_id}>
          {countries[i].country_name}
        </option>
      );
    }

    return options;
  };

  render() {
    return (
      <Modal show={this.props.editCustomer} onHide={this.props.toggleEditCustomer}>
        <Modal.Header closeButton>
          <Modal.Title>Klant aanpassen</Modal.Title>
        </Modal.Header>
        <Form method='POST' encType='multipart/form-data' onSubmit={this.props.submitEditCustomer}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>
                Klantnaam <CustomerNameTooltip />
              </Form.Label>
              <Form.Control type='text' placeholder='Klantnaam' name='customer_name' defaultValue={this.props.selectedCustomer.customer_name} />
              <span className={this.props.formErrors["customer_name"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["customer_name"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Email <CustomerEmailTooltip />
              </Form.Label>
              <Form.Control type='email' placeholder='Email' name='customer_email' defaultValue={this.props.selectedCustomer.customer_email} />
              <span className={this.props.formErrors["customer_email"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["customer_email"]}</span>
            </Form.Group>
            {/* <Form.Group>
                <Form.Label>
                  Wachtwoord{" "}
                  <span>
                    <GiPerspectiveDiceSixFacesRandom
                      className="password-random noselect"
                      onClick={this._generatePassword}
                    />
                  </span>
                </Form.Label>
                <div className="password-group">
                  <Form.Control
                    type={this.state.hidePassword ? "password" : "text"}
                    placeholder="Wachtwoord"
                    name="customer_password"
                    className="password-field"
                    defaultValue={this.props.selectedCustomer.customer_password}
                  />
                  <span className="password-eye">
                    {this.state.hidePassword ? (
                      <FaEye
                        onClick={() => this.setState({ hidePassword: false })}
                      />
                    ) : (
                      <FaEyeSlash
                        onClick={() => this.setState({ hidePassword: true })}
                      />
                    )}
                  </span>
                </div>
                <span
                  className={
                    this.props.formErrors["customer_password"]
                      ? "form-error"
                      : "form-error-hidden"
                  }
                >
                  {this.props.formErrors["customer_password"]}
                </span>
              </Form.Group> */}
            <Form.Group>
              <Form.Label>
                Adres <CustomerAddressTooltip />
              </Form.Label>
              <Form.Control type='text' placeholder='Straat' name='customer_address_streetname' defaultValue={this.props.selectedCustomer.customer_address_streetname} />
              <span className={this.props.formErrors["customer_address_streetname"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["customer_address_streetname"]}</span>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col>
                  <Form.Control type='number' placeholder='Huisnummer' name='customer_address_number' defaultValue={this.props.selectedCustomer.customer_address_number} />
                  <span className={this.props.formErrors["customer_address_number"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["customer_address_number"]}</span>
                </Col>
                <Col>
                  <Form.Control type='text' placeholder='Toevoeging' name='customer_address_number_extra' defaultValue={this.props.selectedCustomer.customer_address_number_extra} />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col>
                  <Form.Control type='text' placeholder='Postcode' name='customer_address_postal' defaultValue={this.props.selectedCustomer.customer_address_postal} />
                  <span className={this.props.formErrors["customer_address_postal"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["customer_address_postal"]}</span>
                </Col>
                <Col>
                  <Form.Control type='text' placeholder='Gemeente' name='customer_address_city' defaultValue={this.props.selectedCustomer.customer_address_city} />
                  <span className={this.props.formErrors["customer_address_city"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["customer_address_city"]}</span>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Form.Control as='select' name='customer_address_country' defaultValue={this.props.selectedCustomer.customer_address_country} className='custom-select'>
                {this._buildCountriesDropdown()}
              </Form.Control>
              <span className={this.props.formErrors["customer_address_country"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["customer_address_country"]}</span>
            </Form.Group>

            {/* <Form.Group>
                <label className="checkbox-container">
                  Het leveringsadres is hetzelfde als het adres
                  <input
                    type="checkbox"
                    id="id-checkbox-address"
                    name="customer_delivery_equals_address"
                    defaultChecked={this.state.hideDelivery ? true : false}
                    onChange={this.toggleDelivery}
                  />
                  <span className="checkmark"></span>
                </label>
              </Form.Group>
              <div className={this.state.hideDelivery ? "hide" : ""}>
                <Form.Group>
                  <Form.Label>Leveringsadres <CustomerDeliveryTooltip /></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Straat"
                    name="customer_delivery_streetname"
                    defaultValue={
                      this.props.selectedCustomer.customer_delivery_streetname
                    }
                  />
                  <span
                    className={
                      this.props.formErrors["customer_delivery_streetname"]
                        ? "form-error"
                        : "form-error-hidden"
                    }
                  >
                    {this.props.formErrors["customer_delivery_streetname"]}
                  </span>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col>
                      <Form.Control
                        type="number"
                        placeholder="Huisnummer"
                        name="customer_delivery_number"
                        defaultValue={
                          this.props.selectedCustomer.customer_delivery_number
                        }
                      />
                      <span
                        className={
                          this.props.formErrors["customer_delivery_number"]
                            ? "form-error"
                            : "form-error-hidden"
                        }
                      >
                        {this.props.formErrors["customer_delivery_number"]}
                      </span>
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="Toevoeging"
                        name="customer_delivery_number_extra"
                        defaultValue={
                          this.props.selectedCustomer
                            .customer_delivery_number_extra
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="Postcode"
                        name="customer_delivery_postal"
                        defaultValue={
                          this.props.selectedCustomer.customer_delivery_postal
                        }
                      />
                      <span
                        className={
                          this.props.formErrors["customer_delivery_postal"]
                            ? "form-error"
                            : "form-error-hidden"
                        }
                      >
                        {this.props.formErrors["customer_delivery_postal"]}
                      </span>
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="Gemeente"
                        name="customer_delivery_city"
                        defaultValue={
                          this.props.selectedCustomer.customer_delivery_city
                        }
                      />
                      <span
                        className={
                          this.props.formErrors["customer_delivery_city"]
                            ? "form-error"
                            : "form-error-hidden"
                        }
                      >
                        {this.props.formErrors["customer_delivery_city"]}
                      </span>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    as="select"
                    name="customer_delivery_country"
                    defaultValue={
                      this.props.selectedCustomer.customer_delivery_country
                    }
                    className="custom-select"
                  >
                    {this._buildCountriesDropdown()}
                  </Form.Control>
                  <span
                    className={
                      this.props.formErrors["customer_delivery_country"]
                        ? "form-error"
                        : "form-error-hidden"
                    }
                  >
                    {this.props.formErrors["customer_delivery_country"]}
                  </span>
                </Form.Group>
              </div> */}
            <Form.Group>
              <Form.Label>
                BTW-nummer <CustomerBtwTooltip />
              </Form.Label>
              <Form.Control type='text' placeholder='BTW-nummer' name='customer_btw' defaultValue={this.props.selectedCustomer.customer_btw} />
              <span className={this.props.formErrors["customer_btw"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["customer_btw"]}</span>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {this.props.formErrors["submitError"] ? <Alert variant='danger'>{this.props.formErrors["submitError"]}</Alert> : null}
            <Button variant='primary' type='submit'>
              Aanpassen
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default EditCustomerModal;
