export default class Module {
  constructor(
    module_id = "",
    module_name = "",
    module_description = "",
    module_size = "",
    module_model_height = "",
    module_kps_name = "",
    module_kps_path = "",
    module_model_path = "",
    module_model_thumbnail_path = "",
    module_base_price = "",
    module_base_material_id = "",
    module_width_price_scale = "",
    module_height_price_scale = "",
    module_depth_price_scale = ""
  ) {
    this.module_id = module_id;
    this.module_name = module_name;
    this.module_description = module_description;
    this.module_size = module_size;
    this.module_model_height = module_model_height;
    this.module_kps_name = module_kps_name;
    this.module_kps_path = module_kps_path;
    this.module_model_path = module_model_path;
    this.module_model_thumbnail_path = module_model_thumbnail_path;
    this.module_base_price = module_base_price;
    this.module_base_material_id = module_base_material_id;
    this.module_width_price_scale = module_width_price_scale;
    this.module_height_price_scale = module_height_price_scale;
    this.module_depth_price_scale = module_depth_price_scale;
  }

  get module_id() {
    return this._module_id;
  }

  set module_id(id) {
    this._module_id = id !== undefined ? id : "";
  }

  get module_name() {
    return this._module_name;
  }

  set module_name(name) {
    // .replace white spaces by an actual space (otherwise the searchfield would never show items that contain a space (after searching for an item using a space in the searchQuery))
    this._module_name = name !== undefined ? name.replace(/\s+/g, " ") : "";
  }

  get module_description() {
    return this._module_description;
  }

  set module_description(description) {
    // .replace white spaces by an actual space (otherwise the searchfield would never show items that contain a space (after searching for an item using a space in the searchQuery))
    this._module_description = description !== undefined && description !== null ? description.replace(/\s+/g, " ") : "";
  }

  get module_size() {
    return this._module_size;
  }

  set module_size(size) {
    this._module_size = size !== undefined ? Number(size) : "";
  }

  get module_model_height() {
    return this._module_model_height;
  }

  set module_model_height(height) {
    this._module_model_height = height !== undefined ? Number(height) : "";
  }

  get module_kps_name() {
    return this._module_kps_name;
  }

  set module_kps_name(kps_name) {
    this._module_kps_name = kps_name !== undefined ? kps_name.replace(/\s+/g, " ") : "";
  }

  get module_kps_path() {
    return this._module_kps_path;
  }

  set module_kps_path(kps_path) {
    this._module_kps_path = kps_path !== undefined ? kps_path : "";
  }

  get module_model_path() {
    return this._module_model_path;
  }

  set module_model_path(model_path) {
    this._module_model_path = model_path;
  }

  get module_model_thumbnail_path() {
    return this._module_model_thumbnail_path;
  }

  set module_model_thumbnail_path(thumbnail_path) {
    this._module_model_thumbnail_path = thumbnail_path !== undefined ? thumbnail_path : "";
  }

  get module_base_material_id() {
    return this._module_base_material_id;
  }

  set module_base_material_id(id) {
    this._module_base_material_id = id !== undefined ? Number(id) : "";
  }

  get module_base_price() {
    return this._module_base_price;
  }

  set module_base_price(price) {
    this._module_base_price = price !== undefined ? price : "";
  }

  get module_width_price_scale() {
    return this._module_width_price_scale;
  }

  set module_width_price_scale(width_price_scale) {
    this._module_width_price_scale = width_price_scale !== undefined ? Number(width_price_scale).toFixed(2) : "";
  }

  get module_height_price_scale() {
    return this._module_height_price_scale;
  }

  set module_height_price_scale(height_price_scale) {
    this._module_height_price_scale = height_price_scale !== undefined ? Number(height_price_scale).toFixed(2) : "";
  }

  get module_depth_price_scale() {
    return this._module_depth_price_scale;
  }

  set module_depth_price_scale(depth_price_scale) {
    this._module_depth_price_scale = depth_price_scale !== undefined ? Number(depth_price_scale).toFixed(2) : "";
  }

  setDataFromObject(obj) {
    Object.assign(this, obj);
  }

  toJson(type) {
    let json = {};

    switch (type.toLowerCase()) {
      case "add":
        json = {
          module_name: this.module_name,
          module_description: this.module_description,
          module_size: this.module_size,
          module_model_height: this.module_model_height,
          module_kps_name: this.module_kps_name,
          module_kps_path: this.module_kps_path,
          module_model_path: this.module_model_path,
          module_model_thumbnail_path: this.module_model_thumbnail_path,
          module_base_price: this.module_base_price,
          module_base_material_id: this.module_base_material_id,
          module_width_price_scale: this.module_width_price_scale,
          module_height_price_scale: this.module_height_price_scale,
          module_depth_price_scale: this.module_depth_price_scale,
        };
        break;
      case "edit":
        json = {
          module_id: this.module_id,
          module_name: this.module_name,
          module_description: this.module_description,
          module_size: this.module_size,
          module_model_height: this.module_model_height,
          module_kps_name: this.module_kps_name,
          module_kps_path: this.module_kps_path,
          module_model_path: this.module_model_path,
          module_model_thumbnail_path: this.module_model_thumbnail_path,
          module_base_price: this.module_base_price,
          module_base_material_id: this.module_base_material_id,
          module_width_price_scale: this.module_width_price_scale,
          module_height_price_scale: this.module_height_price_scale,
          module_depth_price_scale: this.module_depth_price_scale,
        };
        break;
      case "delete":
        json = {
          module_id: this.module_id,
        };
        break;
      default:
        break;
    }

    return json;
  }
}
