import React from "react";
import SideNavContainer from "../components/SideNavContainer";
import Customers from "../components/Customers";

import Customer from "../models/Customer";
import Country from "../models/Country";

import { FaTrashAlt, FaPencilAlt } from "react-icons/fa";
import ApiRequest from "../services/ApiRequest";

class CustomersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadError: false,
      loadErrorMessage: [],
      /* Toggle Popup */
      addCustomer: false, // Toggle AddCustomer
      editCustomer: false, // Toggle EditCustomer
      deleteCustomer: false, // Toggle DeleteCustomer

      /* Form */
      selectedCustomer: new Customer(),
      fields: {}, //Handle onChange of FormElements (Add)
      errors: {}, // Form Errors

      /* Countries (used in 'Add' & 'Edit' Customers) */
      countries: [new Country("1", "Nederland"), new Country("2", "Duitsland")],

      /* Search + Sort */
      sort: {
        customer_name: "desc",
        customer_email: "desc",
        customer_address: "desc",
        customer_btw: "desc",
      },
      searchValue: "", // Search Value

      /* Customers */
      backupCustomers: [], //Used for as backup for filter
      customers: [],
    };
  }

  async componentDidMount() {
    function _getCustomers() {
      return ApiRequest.axiosInstance.post("/customer/readAllCustomers.php");
    }

    // function _getCountries() {
    // return ApiRequest.axiosInstance.post('/country/readAllCountries.php');
    // }

    let loadError = false;
    let loadErrorMessage = [];

    await Promise.all([_getCustomers()]) //, _getCountries()
      .then((results) => {
        let customers = results[0];
        if (ApiRequest.callSuccess(customers)) {
          console.log("call success")
          if (ApiRequest.callHasRecords(customers)) {
            console.log("call has records")
            console.log(customers.data.records)

            let customerData = [];

            // console.log(customers.data.records.length)
            for (let i = 0, len = customers.data.records.length; i < len; i++) {
              console.log(customers.data.records[i])

              let customer = new Customer();
              customer.setDataFromObject(customers.data.records[i]);
              console.log(customer)

              customerData.push(customer);
            }
            console.log(customerData)
            //customerData.sort((a, b) => (a.customer_id > b.customer_id ? 1 : -1));

            console.log(customerData)
            this.setState({
              customers: customerData,
            });
          }
        } else if (ApiRequest.callNoResults(customers)) {
          this.setState({
            customers: [],
          });
        } else {
          if (customers.data.message !== undefined) {
            loadErrorMessage.push(customers.data.message);
          }
          loadError = true;
        }

        // let countries = results[1];
        // if (ApiRequest.callSuccess(countries)) {
        //   if (ApiRequest.callHasRecords(countries)) {
        //     let countryData = [];

        //     for (let i = 0, len = countries.data.records.length; i < len; i++) {
        //       let country = new Country();
        //       country.setDataFromObject(countries.data.records[i]);
        //       countryData.push(country);
        //     }

        //     countryData.sort((a, b) => {
        //       return a.country_name.toLowerCase() < b.country_name.toLowerCase() ? 1 : -1;
        //     });

        //     this.setState({
        //       countries: countryData,
        //     });
        //   }
        // } else if (ApiRequest.callNoResults(countries)) {
        //   this.setState({
        //     countries: [],
        //   });
        // } else {
        // if (countries.data.message !== undefined) {
        //   loadErrorMessage.push(countries.data.message);
        // }
        //   loadError = true;
        // }
      })
      .catch((err) => {
        console.log("Customers 'Read' Request Error");
        if (err.response) {
          if (err.response.data.message !== undefined) {
            loadErrorMessage.push(err.response.data.message);
          }
        }
        loadError = true;
      })
      .finally(() => {
        console.log("Customers 'Read' Request Finished");
        setTimeout(() => {
          this.setState({
            loading: false,
            loadError: loadError,
            loadErrorMessage: loadErrorMessage,
          });
        }, 500);
      });
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  /* Search & Sort Functions */

  // Handle Change in 'Live Search' of Table (Search Box) + Update Table (Hide Customers that Don't Match Criteria)
  searchChange = (value) => {
    this.setState({ searchValue: value });

    let backupCustomers = this.state.backupCustomers;

    if (backupCustomers.length === 0) {
      backupCustomers = [...this.state.customers];
      this.setState({ backupCustomers: backupCustomers });
    }

    let customers = [];

    for (let i = 0, len = backupCustomers.length; i < len; i++) {
      if (backupCustomers[i].customer_name.toLowerCase().includes(value.toLowerCase())) {
        customers.push(backupCustomers[i]);
      }
    }

    this.setState({ customers: customers });
  };

  // Clear 'Live Search' (Search Box) + Update Table (Display all Customers)
  clearSearch = () => {
    this.setState({ searchValue: "", customers: this.state.backupCustomers });
  };

  // Ascending/Descending Sort Customers (onClick 'th')
  sortCustomers = (key) => {
    let customers = this.state.customers;
    let sort = this.state.sort;

    if (this.state.sort[key] === "asc") {
      sort[key] = "desc";
      this.setState({ sort });
      if (key === "customer_address") {
        customers.sort((a, b) => {
          return a.getFormattedAddress().toLowerCase() > b.getFormattedAddress().toLowerCase() ? 1 : -1;
        });
      } else {
        customers.sort((a, b) => (a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1));
      }
    } else {
      sort[key] = "asc";
      this.setState({ sort });
      if (key === "customer_address") {
        customers.sort((a, b) => {
          return a.getFormattedAddress().toLowerCase() < b.getFormattedAddress().toLowerCase() ? 1 : -1;
        });
      } else {
        customers.sort((a, b) => (a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1));
      }
    }

    this.setState({ customers });
  };

  /* Form Functions */

  // Validate Form (Called on Submit of Add & Edit) + Create Error Messages if Needed
  _validForm = (e) => {
    let customer = this.state.selectedCustomer;
    let errors = {};
    let formIsValid = true;

    let formData = new FormData(e.target);

    let customer_name = formData.get("customer_name");
    let customer_email = formData.get("customer_email");
    // let customer_password = formData.get('customer_password');
    let customer_address_streetname = formData.get("customer_address_streetname");
    let customer_address_number = formData.get("customer_address_number");
    let customer_address_number_extra = formData.get("customer_address_number_extra");
    let customer_address_postal = formData.get("customer_address_postal");
    let customer_address_city = formData.get("customer_address_city");
    let customer_address_country = formData.get("customer_address_country");

    let customer_btw = formData.get("customer_btw");

    if (customer_name !== "") {
      customer.customer_name = customer_name;
    } else {
      errors["customer_name"] = "Klantnaam is verplicht";
    }

    if (customer_email !== "") {
      customer.customer_email = customer_email;
    } else {
      errors["customer_email"] = "Email is verplicht";
    }

    // if (customer_password !== "") {
    //   if (customer_password.length >= 8) {
    //     customer.customer_password = customer_password;
    //   } else {
    //     errors["customer_password"] =
    //       "Wachtwoord moet minstens 8 tekens lang zijn";
    //   }
    // } else {
    //   errors["customer_password"] = "Wachtwoord is verplicht";
    // }

    if (customer_address_streetname !== "") {
      customer.customer_address_streetname = customer_address_streetname;
    } else {
      errors["customer_address_streetname"] = "Straat is verplicht";
    }

    if (customer_address_number !== "") {
      customer.customer_address_number = customer_address_number;
    } else {
      errors["customer_address_number"] = "Huisnummer is verplicht";
    }

    // No validation required (optional input)
    customer.customer_address_number_extra = customer_address_number_extra;

    if (customer_address_postal !== "") {
      customer.customer_address_postal = customer_address_postal;
    } else {
      errors["customer_address_postal"] = "Postcode is verplicht";
    }

    if (customer_address_city !== "") {
      customer.customer_address_city = customer_address_city;
    } else {
      errors["customer_address_city"] = "Gemeente is verplicht";
    }

    if (customer_btw !== "") {
      customer.customer_btw = customer_btw;
    } else {
      errors["customer_btw"] = "BTW-nummer is verplicht";
    }

    // No validation required (dropdown)
    customer.customer_address_country = customer_address_country;

    if (Object.keys(errors).length > 0) {
      formIsValid = false;
    }

    this.setState({ errors: errors, customer: customer });

    return formIsValid;
  };

  /* Popups (Add, Edit, Delete) */

  // Show/Hide 'Add Customer' Pop-up + Clear all 'Changes' from 'fields' and 'errors'
  toggleAddCustomer = () => {
    this.setState({
      selectedCustomer: new Customer(),
      errors: {},
      addCustomer: !this.state.addCustomer,
    });
  };

  // Show/Hide 'Edit Customer' Pop-up + Clear 'errors' + set'fields' (Required for Bypassing Validation when Submitting without Changing Anything)
  toggleEditCustomer = (customer) => {
    this.setState({
      // fields: customer !== undefined ? customer : this.state.fields,
      selectedCustomer: customer !== undefined ? Object.assign(Object.create(Object.getPrototypeOf(customer)), customer) : this.state.selectedCustomer,
      errors: {},
      editCustomer: !this.state.editCustomer,
    });
  };

  // Show/Hide 'Delete Customer' Pop-up + Set 'fields' (Used for Displaying 'customer_name' + having the 'id' )
  // NOTE: Errors are not cleared, because we clear them on all others and delete doesn't have any errors linked to it as the only errors that could occur is something with the backend: eg. invalid ID,...
  toggleDeleteCustomer = (customer) => {
    this.setState({
      selectedCustomer: customer !== undefined ? customer : this.state.selectedCustomer,
      deleteCustomer: !this.state.deleteCustomer,
      // fields: customer !== undefined ? customer : this.state.fields,
    });
  };

  /* Update State on Successful HTTP Request*/

  // Add customer to 'this.state.customers'
  _addStateCustomer = (customer) => {
    let customers = this.state.customers;
    customers.push(customer);
    this.setState({ customers });
  };

  // Update 'this.state.customers' with new value from 'Edit'
  _updateStateCustomer = (id) => {
    let customers = this.state.customers;

    for (let i = 0, len = customers.length; i < len; i++) {
      if (customers[i].customer_id === id) {
        customers[i] = this.state.selectedCustomer;
        this.setState({ customers });

        break;
      }
    }
  };

  // Delete customer from 'this.state.customers'
  _deleteStateCustomer = (id) => {
    let customers = this.state.customers;

    for (let i = 0, len = customers.length; i < len; i++) {
      if (customers[i].customer_id === id) {
        customers.splice(i, 1);
        this.setState({ customers });

        break;
      }
    }
  };

  /* Submit Forms (Add, Edit, Delete) */

  // Handle Submit 'Add Customer' Pop-up
  submitAddCustomer = async (e) => {
    e.preventDefault();

    if (this._validForm(e)) {
      let customer = this.state.selectedCustomer;

      let data = customer.toJson("add");

      await ApiRequest.axiosInstance
        .post("/customer/createCustomer.php", data)
        .then((res) => {
          if (ApiRequest.callSuccess(res)) {
            if (ApiRequest.callHasRecords(res)) {
              let customer = this.state.selectedCustomer;

              if (res.data.records[0].hasOwnProperty("CUSTOMER_ID")) {
                customer.customer_id = res.data.records[0].CUSTOMER_ID;
              }

              this._addStateCustomer(customer);
              this.toggleAddCustomer();
            }
          } else {
            let errors = this.state.errors;
            errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
            this.setState({
              errors: errors,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          let errors = this.state.errors;
          errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        })
        .then(() => {
          console.log("Customer 'Create' Request Finished");
        });
    }
  };

  // Handle Submit 'Edit Customer' Pop-up
  submitEditCustomer = async (e) => {
    e.preventDefault();

    if (this._validForm(e)) {
      let customer = this.state.selectedCustomer; // all input data

      let data = customer.toJson("edit");

      await ApiRequest.axiosInstance
        .post("/customer/updateCustomer.php", data)
        .then((res) => {
          if (ApiRequest.callSuccess(res)) {
            this._updateStateCustomer(customer.customer_id);
            this.toggleEditCustomer();
          } else {
            let errors = this.state.errors;
            errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
            this.setState({
              errors: errors,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          let errors = this.state.errors;
          errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        })
        .then(() => {
          console.log("Customers 'Update' Request Finished");
        });
    }
  };

  // Handle Submit 'Delete Customer' Pop-up
  submitDeleteCustomer = async (e) => {
    e.preventDefault();

    let customer = this.state.selectedCustomer;

    let data = customer.toJson("delete");

    await ApiRequest.axiosInstance
      .post("/customer/deleteCustomer.php", data)
      .then((res) => {
        if (ApiRequest.callSuccess(res)) {
          this._deleteStateCustomer(data.customer_id);
          this.toggleDeleteCustomer();
        } else {
          let errors = this.state.errors;
          errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        }
      })
      .catch((err) => {
        console.error(err);

        let errors = this.state.errors;
        errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
        this.setState({
          errors: errors,
        });
      })
      .then(() => {
        console.log("Customers 'Delete' Request Finished");
      });
  };

  /* Build Table */

  // Fill Table with Data from 'this.state.customers'
  populateTable = () => {
    let customers = this.state.customers;

    let rows = [];

    for (let i = 0, len = customers.length; i < len; i++) {
      let children = [];

      children.push(<td key={i + "-name"}>{customers[i].customer_name}</td>);
      children.push(<td key={i + "-email"}>{customers[i].customer_email}</td>);
      children.push(<td key={i + "-address"}>{customers[i].getFormattedAddress()}</td>);
      children.push(<td key={i + "-btw"}>{customers[i].customer_btw}</td>);
      children.push(
        <td key={i + "-edit"} className='td-icon' onClick={() => this.toggleEditCustomer(customers[i])}>
          <FaPencilAlt />
        </td>
      );
      children.push(
        <td key={i + "-delete"} className='td-icon' onClick={() => this.toggleDeleteCustomer(customers[i])}>
          <FaTrashAlt />
        </td>
      );

      rows.push(
        <tr key={i} id={i}>
          {children}
        </tr>
      );
    }

    return rows;
  };

  render() {
    return (
      <div>
        <SideNavContainer />
        <Customers
          state={this.state}
          toggleAddCustomer={this.toggleAddCustomer}
          toggleEditCustomer={this.toggleEditCustomer}
          toggleDeleteCustomer={this.toggleDeleteCustomer}
          submitAddCustomer={this.submitAddCustomer}
          submitEditCustomer={this.submitEditCustomer}
          submitDeleteCustomer={this.submitDeleteCustomer}
          searchChange={this.searchChange}
          clearSearch={this.clearSearch}
          populateTable={this.populateTable}
          sortCustomers={this.sortCustomers}
        />
      </div>
    );
  }
}

export default CustomersContainer;
