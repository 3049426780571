import React from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

class DeleteCustomerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal show={this.props.deleteCustomer} onHide={this.props.toggleDeleteCustomer}>
        <Modal.Header closeButton>
          <Modal.Title>Klant Verwijderen</Modal.Title>
        </Modal.Header>
        <Form method='POST' encType='multipart/form-data' onSubmit={this.props.submitDeleteCustomer}>
          <Modal.Body>Bent u zeker dat u "{this.props.selectedCustomer.customer_name}" wilt verwijderen?</Modal.Body>
          <Modal.Footer>
            {this.props.formErrors["submitError"] ? <Alert variant='danger'>{this.props.formErrors["submitError"]}</Alert> : null}

            <Button variant='primary' type='submit'>
              Verwijderen
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default DeleteCustomerModal;
