export default class Feature {
  constructor(feature_id = "", feature_name = "", feature_type = "", feature_type_name = "", feature_model_path = "", feature_thumbnail_path = "", feature_price = "") {
    this.feature_id = feature_id;
    this.feature_name = feature_name;
    this.feature_type = feature_type;
    this.feature_type_name = feature_type_name;
    this.feature_model_path = feature_model_path;
    this.feature_thumbnail_path = feature_thumbnail_path;
    this.feature_price = feature_price;
  }

  get feature_id() {
    return this._feature_id;
  }

  set feature_id(id) {
    this._feature_id = id !== undefined ? id : "";
  }

  get feature_name() {
    return this._feature_name;
  }

  set feature_name(name) {
    // .replace white spaces by an actual space (otherwise the searchfield would never show items that contain a space (after searching for an item using a space in the searchQuery))
    this._feature_name = name !== undefined ? name.replace(/\s+/g, " ") : "";
  }

  get feature_type() {
    return this._feature_type;
  }

  set feature_type(type) {
    this._feature_type = type !== undefined ? Number(type) : "";
  }

  get feature_type_name() {
    return this._feature_type_name;
  }

  set feature_type_name(type_name) {
    this._feature_type_name = type_name !== undefined ? type_name.replace(/\s+/g, " ") : "";
  }

  get feature_model_path() {
    return this._feature_model_path;
  }

  set feature_model_path(model_path) {
    this._feature_model_path = model_path !== undefined ? model_path : "";
  }

  get feature_thumbnail_path() {
    return this._feature_thumbnail_path;
  }

  set feature_thumbnail_path(thumbnail_path) {
    this._feature_thumbnail_path = thumbnail_path !== undefined ? thumbnail_path : "";
  }

  get feature_price() {
    return this._feature_price;
  }

  set feature_price(price) {
    this._feature_price = price !== undefined ? price : "";
  }

  setDataFromObject(obj) {
    Object.assign(this, obj);
  }

  toJson(type) {
    let json = {};

    switch (type.toLowerCase()) {
      case "add":
        json = {
          feature_name: this.feature_name,
          feature_type: this.feature_type,
          feature_type_name: this.feature_type_name,
          feature_model_path: this.feature_model_path,
          feature_thumbnail_path: this.feature_thumbnail_path,
          feature_price: this.feature_price,
        };
        break;
      case "edit":
        json = {
          feature_id: this.feature_id,
          feature_name: this.feature_name,
          feature_type: this.feature_type,
          feature_type_name: this.feature_type_name,
          feature_model_path: this.feature_model_path,
          feature_thumbnail_path: this.feature_thumbnail_path,
          feature_price: this.feature_price,
        };
        break;
      case "delete":
        json = {
          feature_id: this._feature_id,
        };
        break;
      default:
        break;
    }

    return json;
  }
}
