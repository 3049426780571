import React from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import { FeatureNameTooltip, FeatureTypeTooltip, FeatureModelFileTooltip, FeatureThumbnailFileTooltip, FeaturePriceTooltip } from "./../../tooltips/FeatureTooltips";

class AddFeaturesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  populateDropDown = () => {
    let dropdownItems = [];
    let featureTypes = this.props.featureTypes;
    for (let i = 0, len = featureTypes.length; i < len; i++) {
      dropdownItems.push(
        <option key={i} value={featureTypes[i].feature_type_id}>
          {featureTypes[i].feature_type_name}
        </option>
      );
    }

    return dropdownItems;
  };

  render() {
    return (
      <Modal show={this.props.addFeature} onHide={this.props.toggleAddFeature}>
        <Modal.Header closeButton>
          <Modal.Title>Feature Toevoegen</Modal.Title>
        </Modal.Header>
        <Form method='POST' encType='multipart/form-data' onSubmit={this.props.submitAddFeature}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>
                Feature Naam <FeatureNameTooltip />
              </Form.Label>
              <Form.Control type='text' placeholder='Feature Naam' name='feature_name' />
              <span className={this.props.formErrors["feature_name"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["feature_name"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Type <FeatureTypeTooltip />
              </Form.Label>
              <Form.Control as='select' name='feature_type' defaultValue={this.props.featureTypes.length > 0 ? this.props.featureTypes[0].feature_type_id : null} className='custom-select'>
                {this.populateDropDown()}
              </Form.Control>
              <span className={this.props.formErrors["feature_type"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["feature_type"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Model Uploaden <FeatureModelFileTooltip />
              </Form.Label>
              <Form.Control type='file' name='feature_model' accept='.gltf, .glb' />
              <span className={this.props.formErrors["feature_model"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["feature_model"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Thumbnail Uploaden <FeatureThumbnailFileTooltip />
              </Form.Label>
              <Form.Control type='file' name='feature_thumbnail' accept='.png, .jpg, .jpeg' />
              <span className={this.props.formErrors["feature_thumbnail"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["feature_thumbnail"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Prijs <FeaturePriceTooltip />
              </Form.Label>
              <Form.Control type='number' step='0.01' placeholder='Prijs' name='feature_price' />
              <span className={this.props.formErrors["feature_price"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["feature_price"]}</span>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {this.props.formErrors["submitError"] ? <Alert variant='danger'>{this.props.formErrors["submitError"]}</Alert> : null}
            <Button variant='primary' type='submit'>
              Toevoegen
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default AddFeaturesModal;
