import React from "react";
import SideNavContainer from "../components/SideNavContainer";
import Features from "../components/Features";
import formatPrice from "../misc/functions";

import Feature from "../models/Feature";
import FeatureType from "../models/FeatureType";

import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

import ApiRequest from "./../services/ApiRequest";

class FeaturesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadError: false,
      loadErrorMessage: [],
      /* Toggle Popup */
      addFeature: false, // Toggle AddFeaturePopup
      editFeature: false, // Toggle EditFeaturePopup
      deleteFeature: false, // Toggle DeleteFeaturePopup

      /* Form */
      errors: {}, // Form Errors
      selectedFeature: new Feature(), // Handle onChange of FormElements (Delete)

      /* Search + Sort */
      sort: {
        feature_name: "desc",
        feature_type_name: "desc",
        feature_price: "desc",
      },
      searchValue: "", //Search Value

      /* Features */
      backupFeatures: [], //Used for filtering
      features: [],
      featureTypes: [],
    };
  }

  // On mount, get all data from database (features + featureTypes)
  async componentDidMount() {
    function _getFeaturesWithTypeName() {
      return ApiRequest.axiosInstance.post("/feature/readAllFeaturesAsTable.php");
    }

    function _getFeatureTypes() {
      return ApiRequest.axiosInstance.post("/feature_type/readAllFeatureTypes.php");
    }

    let loadError = false;
    let loadErrorMessage = [];

    await Promise.all([_getFeaturesWithTypeName(), _getFeatureTypes()])
      .then((results) => {
        let dbFeatures = results[0];
        if (ApiRequest.callSuccess(dbFeatures)) {
          if (ApiRequest.callHasRecords(dbFeatures)) {
            let features = [];
            
            for (let i = 0, len = dbFeatures.data.records.length; i < len; i++) {
              let feature = new Feature();
              feature.setDataFromObject(dbFeatures.data.records[i]);
              features.push(feature);
            }

            features.sort((a, b) => (a.feature_id > b.feature_id ? 1 : -1));

            this.setState({
              features: features,
            });
          }
        } else if (ApiRequest.callNoResults(dbFeatures)) {
          this.setState({
            features: [],
          });
        } else {
          loadError = true;
          if (dbFeatures.data.message !== undefined) {
            loadErrorMessage.push(dbFeatures.data.message);
          }
        }

        let dbFeatureTypes = results[1];
        if (ApiRequest.callSuccess(dbFeatureTypes)) {
          if (ApiRequest.callHasRecords(dbFeatureTypes)) {
            let featureTypes = [];
            
            for (let i = 0, len = dbFeatureTypes.data.records.length; i < len; i++) {
              let featureType = new FeatureType();
              featureType.setDataFromObject(dbFeatureTypes.data.records[i]);
              featureTypes.push(featureType);
            }

            featureTypes.sort((a, b) => (a.feature_type_name > b.feature_type_name ? 1 : -1));

            this.setState({
              featureTypes: featureTypes,
            });
          }
        } else if (ApiRequest.callNoResults(dbFeatureTypes)) {
          this.setState({
            featureTypes: [],
          });
        } else {
          loadError = true;
          if (dbFeatureTypes.data.message !== undefined) {
            loadErrorMessage.push(dbFeatureTypes.data.message);
          }
        }
      })
      .catch((err) => {
        loadError = true;
        if (err.response) {
          if (err.response.data.message !== undefined) {
            loadErrorMessage.push(err.response.data.message);
          }
        }
      })
      .finally(() => {
        console.log("Features 'Read' Request Finished");
        setTimeout(() => {
          this.setState({
            loading: false,
            loadError: loadError,
            loadErrorMessage: loadErrorMessage,
          });
        }, 500);
      });
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }
  /* Search & Sort Functions */

  // Handle Changes in 'Live Search' of Table (Search Box) + Update Table (Hide Features that Don't Match Criteria)
  searchChange = (value) => {
    this.setState({ searchValue: value });

    let backupFeatures = this.state.backupFeatures;

    if (backupFeatures.length === 0) {
      backupFeatures = [...this.state.features];
      this.setState({ backupFeatures: backupFeatures });
    }

    let features = [];

    for (let i = 0, len = backupFeatures.length; i < len; i++) {
      if (backupFeatures[i].feature_name.toLowerCase().includes(value.toLowerCase())) {
        features.push(backupFeatures[i]);
      }
    }

    this.setState({ features: features });
  };

  // Clear 'Live Search' (Search Box) + Update Table (Display all Features)
  clearSearch = () => {
    this.setState({ searchValue: "", features: this.state.backupFeatures });
  };

  // Ascending/Descending Sort Features (onClick 'th')
  sortFeatures = (key) => {
    let features = this.state.features;
    let sort = this.state.sort;

    if (this.state.sort[key] === "asc") {
      sort[key] = "desc";
      this.setState({ sort });
      features.sort((a, b) => (a[key].toString().toLowerCase() > b[key].toString().toLowerCase() ? 1 : -1));
    } else {
      sort[key] = "asc";
      this.setState({ sort });
      features.sort((a, b) => (a[key].toString().toLowerCase() < b[key].toString().toLowerCase() ? 1 : -1));
    }

    this.setState({ features });
  };

  /* Form functions */

  // Validate Form (Called on Submit of Add & Edit) + Create Error Messages if Needed
  _validForm = (e, isEdit = false) => {
    let selectedFeature = this.state.selectedFeature;
    let errors = {};
    let formIsValid = true;

    let formData = new FormData(e.target);

    let feature_name = formData.get("feature_name");
    let feature_type = formData.get("feature_type");
    let feature_model = formData.get("feature_model");
    let feature_thumbnail = formData.get("feature_thumbnail");
    let feature_price = formData.get("feature_price");

    if (feature_name !== "") {
      selectedFeature.feature_name = feature_name;
    } else {
      errors["feature_name"] = "Feature naam is verplicht";
    }

    // No validation required (dropdown)
    selectedFeature.feature_type = feature_type;

    if (feature_model.name !== "") {
      selectedFeature.feature_model_path = feature_model;
    } else if (isEdit) {
      selectedFeature["feature_model"] = null;
    } else {
      errors["feature_model"] = "Model uploaden is verplicht";
    }

    if (feature_thumbnail.name !== "") {
      selectedFeature.feature_thumbnail_path = feature_thumbnail;
    } else if (isEdit) {
      selectedFeature["feature_thumbnail"] = null;
    } else {
      errors["feature_thumbnail"] = "Thumbnail uploaden is verplicht";
    }

    if (feature_price !== "") {
      feature_price = +feature_price;
      if (feature_price > 0) {
        selectedFeature.feature_price = feature_price;
      } else {
        errors["feature_price"] = "Feature prijs moet groter dan 0 zijn";
      }
    } else {
      errors["feature_price"] = "Feature prijs is verplicht";
    }

    if (Object.keys(errors).length > 0) {
      formIsValid = false;
    }

    this.setState({ errors: errors, selectedFeature: selectedFeature });

    return formIsValid;
  };

  /* Popups (Add, Edit, Delete) */

  // Show/Hide 'Add Feature' Pop-up + Clear all 'Changs' from 'fields' and 'errors'
  toggleAddFeature = () => {
    this.setState({
      selectedFeature: new Feature(),
      errors: {},
      addFeature: !this.state.addFeature,
    });
  };

  // Show/Hide 'Edit Feature' Pop-up + Clear 'errors' + set 'fields' (Required for Bypassing Validation when Submitting without Changing Anything)
  // + set 'selectedFeature' (Required for Changing Values without updating the state) ==> If this is not used then when user cancels input, it still displays the changed values in the table
  toggleEditFeature = (feature) => {
    this.setState({
      errors: {},
      editFeature: !this.state.editFeature,
      selectedFeature: feature !== undefined ? Object.assign(Object.create(Object.getPrototypeOf(feature)), feature) : this.state.selectedFeature,
    });
  };

  // Show/Hide 'Delete Feature' Pop-up + Set 'fields' (Used for Displaying 'feature_name' + having the 'id' )
  // NOTE: Errors are not cleared, because we clear them on all others and delete doesn't have any errors linked to it as the only errors that could occur is something with the backend: eg. invalid ID,...
  toggleDeleteFeature = (feature) => {
    this.setState({
      deleteFeature: !this.state.deleteFeature,
      selectedFeature: feature !== undefined ? feature : this.state.selectedFeature,
    });
  };

  /* Update State on Successful HTTP Request */

  // Add feature to 'this.state.features'
  _addStateFeature = (feature) => {
    let features = this.state.features;
    features.push(feature);
    this.setState({ features });
  };

  // Update 'this.state.features' with new values from 'Edit'
  _updateStateFeature = (id) => {
    let features = this.state.features;

    for (let i = 0, len = features.length; i < len; i++) {
      if (features[i].feature_id === id) {
        features[i] = this.state.selectedFeature;
        features[i].feature_type_name = this._getFeatureTypeNameFromID(features[i].feature_type);
        this.setState({ features });

        break;
      }
    }
  };

  // Delete feature from 'this.state.featurs'
  _deleteStateFeature = (id) => {
    let features = this.state.features;

    for (let i = 0; i < features.length; i++) {
      if (features[i].feature_id === id) {
        features.splice(i, 1);

        this.setState({ features });

        break;
      }
    }
  };

  _getFeatureTypeNameFromID = (feature_type_id) => {
    let featureTypes = this.state.featureTypes;

    for (let i = 0, len = featureTypes.length; i < len; i++) {
      if (+feature_type_id === +featureTypes[i].feature_type_id) {
        return featureTypes[i].feature_type_name;
      }
    }
  };

  /* Submit Forms (Add, Edit, Delete) */

  // Handle Submit 'Add Feature' Pop-up
  submitAddFeature = (e) => {
    e.preventDefault();

    if (this._validForm(e)) {
      let formData = new FormData(e.target);

      ApiRequest.axiosInstance({
        method: "post",
        url: "/feature/createFeature.php",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          console.log(res);

          if (ApiRequest.callSuccess(res) && ApiRequest.callHasRecords(res)) {
            let feature = this.state.selectedFeature.toJson("add");

            let data = res.data.records[0];

            if (data.hasOwnProperty("FEATURE_ID")) {
              feature.feature_id = data.FEATURE_ID;
            }

            if (data.hasOwnProperty("FEATURE_MODEL_PATH")) {
              feature.feature_model_path = data.FEATURE_MODEL_PATH;
            }

            if (data.hasOwnProperty("FEATURE_TUMBNAIL_PATH")) {
              feature.feature_thumbnail_path = data.FEATURE_TUMBNAIL_PATH;
            }

            this._addStateFeature(feature);
            this.toggleAddFeature();
          } else {
            let errors = this.state.errors;
            errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
            this.setState({
              errors: errors,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          let errors = this.state.errors;
          errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        })
        .then(() => {
          console.log("Features 'Add' Request Finished");
        });
    }
  };

  // Handle Submit 'Edit Feature' Pop-up
  submitEditFeature = (e) => {
    e.preventDefault();

    if (this._validForm(e, true)) {
      let formData = new FormData(e.target);
      formData.set("feature_id", this.state.selectedFeature.feature_id);

      ApiRequest.axiosInstance({
        method: "post",
        url: "/feature/updateFeature.php",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          console.log(res);

          if (ApiRequest.callSuccess(res) && ApiRequest.callHasRecords(res)) {
            let feature = this.state.selectedFeature.toJson("edit");

            let data = res.data.records[0];

            if (data.hasOwnProperty("FEATURE_ID")) {
              feature.feature_id = data.FEATURE_ID;
            }

            if (data.hasOwnProperty("FEATURE_MODEL_PATH")) {
              feature.feature_model_path = data.FEATURE_MODEL_PATH;
            }

            if (data.hasOwnProperty("FEATURE_TUMBNAIL_PATH")) {
              feature.feature_thumbnail_path = data.FEATURE_TUMBNAIL_PATH;
            }

            this._updateStateFeature(feature.feature_id);
            this.toggleEditFeature();
          } else {
            let errors = this.state.errors;
            errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
            this.setState({
              errors: errors,
            });
          }
        })
        .catch((err) => {
          console.error(err);
          let errors = this.state.errors;
          errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        })
        .then(() => {
          console.log("Feature 'Edit' Request Finished");
        });
    }
  };

  // Handle Submit 'Delete Feature' Pop-up
  submitDeleteFeature = async (e) => {
    e.preventDefault();

    let feature = this.state.selectedFeature;

    let data = feature.toJson("delete");

    await ApiRequest.axiosInstance
      .post("/feature/deleteFeature.php", data)
      .then((res) => {
        if (ApiRequest.callSuccess(res)) {
          this._deleteStateFeature(data.feature_id);
          this.toggleDeleteFeature();
        } else {
          let errors = this.state.errors;
          errors["submitError"] = res.data.message !== undefined ? res.data.message : "Er is iets fout gegaan. Probeer het nog eens";
          this.setState({
            errors: errors,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        let errors = this.state.errors;
        errors["submitError"] = err.response.data.message !== undefined ? err.response.data.message : "Er is iets fout gegaan. Probeer het nog eens";
        this.setState({
          errors: errors,
        });
      })
      .then(() => {
        console.log("Features 'Delete' Request Finished");
      });
  };

  /* Build Table */

  // Fill Table with Data from 'this.state.features'
  populateTable = () => {
    let features = this.state.features;
    let rows = [];

    for (let i = 0, len = features.length; i < len; i++) {
      let children = [];

      children.push(<td key={i + "-name"}>{features[i].feature_name}</td>);
      children.push(<td key={i + "-type"}>{features[i].feature_type_name}</td>);
      children.push(<td key={i + "-price"}>€ {formatPrice(+features[i].feature_price)}</td>);
      children.push(
        <td key={i + "-edit"} className='td-icon' onClick={() => this.toggleEditFeature(features[i])}>
          <FaPencilAlt />
        </td>
      );
      children.push(
        <td key={i + "-delete"} className='td-icon' onClick={() => this.toggleDeleteFeature(features[i])}>
          <FaTrashAlt />
        </td>
      );

      rows.push(
        <tr key={i} id={i}>
          {children}
        </tr>
      );
    }

    return rows;
  };

  render() {
    return (
      <div>
        <SideNavContainer />
        <Features
          state={this.state}
          toggleAddFeature={this.toggleAddFeature}
          toggleEditFeature={this.toggleEditFeature}
          toggleDeleteFeature={this.toggleDeleteFeature}
          submitAddFeature={this.submitAddFeature}
          submitEditFeature={this.submitEditFeature}
          submitDeleteFeature={this.submitDeleteFeature}
          searchChange={this.searchChange}
          clearSearch={this.clearSearch}
          populateTable={this.populateTable}
          sortFeatures={this.sortFeatures}
        />
      </div>
    );
  }
}

export default FeaturesContainer;
