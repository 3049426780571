import React from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import { HelpStepIDTooltip, HelpGifFileTooltip, HelpTextTooltip } from "./../../tooltips/HelpTooltips";

class AddHelpsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal show={this.props.addHelp} onHide={this.props.toggleAddHelp}>
        <Modal.Header closeButton>
          <Modal.Title>Hulpvenster Toevoegen</Modal.Title>
        </Modal.Header>
        <Form method='POST' encType='multipart/form-data' onSubmit={this.props.submitAddHelp}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>
                Hulp Stap ID <HelpStepIDTooltip />
              </Form.Label>
              <Form.Control type='number' step='1' placeholder='Hulp Stap ID' name='help_step_id' />
              <span className={this.props.formErrors["help_step_id"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["help_step_id"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Afbeelding Uploaden <HelpGifFileTooltip />
              </Form.Label>
              <Form.Control type='file' name='help_gif' accept='.png, .jpg, .jpeg, .gif' />
              <span className={this.props.formErrors["help_gif"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["help_gif"]}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Hulp <HelpTextTooltip />
              </Form.Label>
              <Form.Control type='text' placeholder='Prijs' name='help_text' />
              <span className={this.props.formErrors["help_text"] ? "form-error" : "form-error-hidden"}>{this.props.formErrors["help_text"]}</span>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {this.props.formErrors["submitError"] ? <Alert variant='danger'>{this.props.formErrors["submitError"]}</Alert> : null}
            <Button variant='primary' type='submit'>
              Toevoegen
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

export default AddHelpsModal;
