export default class Help {
    constructor(help_step_id = "", help_gif_path = "", help_text = "") {
        this.help_step_id = help_step_id;
        this.help_gif_path = help_gif_path;
        this.help_text = help_text;
    }

    get help_step_id() {
        return this._help_step_id;
    }

    set help_step_id(id) {
        this._help_step_id = id !== undefined ? id : "";
    }

    get help_gif_path() {
        return this._help_gif_path;
    }

    set help_gif_path(path) {
        this._help_gif_path = path !== undefined ? path : "";
    }

    get help_text() {
        return this._help_text;
    }

    set help_text(text) {
        this._help_text = text !== undefined ? text : "";
    }

    setDataFromObject(obj) {
        Object.assign(this, obj);
    }

    toJson(type) {
        let json = {};

        switch (type.toLowerCase()) {
            case "add":
                json = {
                    help_gif_path: this.help_gif_path,
                    help_text: this.help_text,
                };
                break;
            case "edit":
                json = {
                    help_step_id: this.help_step_id,
                    help_gif_path: this.help_gif_path,
                    help_text: this.help_text,
                };
                break;
            case "delete":
                json = {
                    help_step_id: this.help_step_id,
                };
                break;
            default:
                break;
        }

        return json;
    }
}
